import * as React from 'react';
import axios from 'axios';

import PopupLayout from './PopupLayout';

interface Props {
  clientLoginUrl: string;
  forgotPasswordUrl: string;
  logoUrl: string;
  omniauthButtons: JSX.Element;
  onClickSignup: (() => void);
  onClose: (() => void);
  redirectUrl: string;
}

interface State {
  email: string;
  error: string;
  inFlight: boolean;
  password: string;
}

class LoginPopup extends React.Component <Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      inFlight: false,
      password: ''
    };
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const { clientLoginUrl, redirectUrl } = this.props;
    const { email, password } = this.state;
    this.setState({
      email: email.trim(),
      inFlight: true
    }, () => {
      axios
        .post(
          clientLoginUrl,
          {
            commit: 'Log in',
            user: {
              email,
              password
            }
          },
          {
            cancelToken: this.signal.token,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') || ''
            }
          }
        )
        .then(() => {
          window.location.href = redirectUrl;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.setState({
              error: error.response.data.error,
              inFlight: false
            });
          } else {
            this.setState({
              inFlight: false
            });
          }
        });
    });
  }

  render() {
    const { email, error, inFlight, password } = this.state;
    const { forgotPasswordUrl, logoUrl, omniauthButtons, onClose, onClickSignup } = this.props;

    return (
      <PopupLayout loading={inFlight} onClose={onClose} title="Log In" width="23em">
        <div className="LoginPopup">
          <img alt="Logo" className="logo" src={logoUrl} />
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <input
              autoComplete="email"
              autoFocus
              className={error.length > 0 ? 'withError' : ''}
              onChange={(event) => this.setState({ email: event.target.value })}
              placeholder="Email address"
              type="email"
              value={email}
            />
            <input
              className={error.length > 0 ? 'withError' : ''}
              onChange={(event) => this.setState({ password: event.target.value })}
              placeholder="Password"
              type="password"
              value={password}
            />
            {error.length > 0 && <div className="error">{error}</div>}
            <div className="footer">
              <button disabled={inFlight} type="submit">
                Log In
              </button>
              <a href={forgotPasswordUrl}>Forgot your password?</a>
            </div>
          </form>
          <span className="divider">or</span>
          {omniauthButtons}
          <div className="signup">
            <button onClick={onClickSignup} type="button">
              Sign Up using your email address...
            </button>
          </div>
        </div>
      </PopupLayout>
    );
  }
}

export default LoginPopup;
