import * as React from 'react';
import axios from 'axios';

import PopupLayout from './PopupLayout';
import RenderHTML from './RenderHTML';
import headers from '../utils/headersGenerator';

interface Props {
  accountUrl: string;
  authenticityToken: string;
  clientUpdateUrl: string;
  serverUpdateUrl: string;
  termsOfServiceAbstract: string;
  termsOfServiceUrl: string;
  token: string;
}

interface State {
  accepted: boolean;
  checked: boolean;
  closed: boolean;
  inFlight: boolean;
  isClient: boolean;
}

class TermsOfServicePopup extends React.Component <Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      accepted: false,
      checked: false,
      closed: false,
      inFlight: false,
      isClient: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  handleSubmit() {
    const { clientUpdateUrl, token } = this.props;
    this.setState({
      inFlight: true
    }, () => {
      axios
        .put(
          clientUpdateUrl,
          { },
          {
            cancelToken: this.signal.token,
            headers: headers(token)
          }
        )
        .then(() => {
          this.setState({
            accepted: true,
            closed: true,
            inFlight: false
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.debug(error.message);
          } else {
            console.error(error);
          }
          this.setState({
            inFlight: false
          });
        });
    });
  }

  render() {
    const { accepted, closed, isClient } = this.state;
    const { accountUrl } = this.props;

    if (!isClient) {
      return this.renderServerSide();
    }
    if (!accepted && closed) {
      window.location.href = accountUrl;
    } else if (closed) {
      return '';
    }

    return this.renderClientSide();
  }

  renderClientSide() {
    const { checked, inFlight } = this.state;
    const { termsOfServiceAbstract, termsOfServiceUrl } = this.props;

    return (
      <PopupLayout loading={inFlight} onClose={() => this.setState({ closed: true })} title="Terms Of Service">
        <div className="TermsOfServicePopup">
          <div className="content">
            <RenderHTML content={termsOfServiceAbstract} />
          </div>
          <div className="footer">
            <div className="left">
              <a href={termsOfServiceUrl} rel="noreferrer" target="_blank">Terms of service page</a>
              <label>
                <input
                  checked={checked}
                  onChange={() => this.setState({ checked: !checked })}
                  type="checkbox"
                />
                I agree to the terms of service
              </label>
            </div>
            <button
              disabled={!checked}
              onClick={this.handleSubmit}
              type="button"
            >
              Continue
            </button>
          </div>
        </div>
      </PopupLayout>
    );
  }

  renderServerSide() {
    const { authenticityToken, serverUpdateUrl, termsOfServiceAbstract, termsOfServiceUrl } = this.props;
    return (
      <div className="TermsOfServicePopup">
        <div className="notClient">
          <div className="popup">
            <div className="header">
              <h3>Terms Of Service</h3>
            </div>
            <div className="content">
              <RenderHTML content={termsOfServiceAbstract} />
            </div>
            <div className="footer">
              <form action={serverUpdateUrl} method="post">
                <div className="left">
                  <a href={termsOfServiceUrl} rel="noreferrer" target="_blank">Terms of service page</a>
                  <label required>
                    <input name="user[tos_last_accepted]" required type="checkbox" />
                    I agree to the terms of service
                  </label>
                  <input name="authenticity_token" type="hidden" value={authenticityToken} />
                  <input autoComplete="false" name="_method" type="hidden" value="patch" />
                </div>
                <input type="submit" value="Continue" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsOfServicePopup;
