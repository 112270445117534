import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { cloneDeep, isEqual, shuffle } from 'lodash';
import { ToWords } from 'to-words';
import axios from 'axios';

import {
  FloatQuestion,
  FreeformQuestion,
  IntegerQuestion,
  LessonContentBlock,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
  Question,
  QuestionType,
  SortableQuestion
} from './interfaces';
import Button from './Button';
import Checkbox from './Checkbox';
import SortOption from './SortOption';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import getDraggableStyle from '../utils/getDraggableStyle';
import headers from '../utils/headersGenerator';
import images from '../utils/images';
import reorder from '../utils/reorder';

interface Props {
  attemptsRemaining: number | 'unlimited';
  block: LessonContentBlock;
  cohortId: string;
  enrolledUser: boolean;
  expanded?: boolean;
  graded?: boolean;
  lessonId?: string;
  lessonResponsesUrl: string;
  manualGrading?: boolean;
  membershipId?: string;
  readOnly: boolean;
  responses?: Responses;
  token?: string;
}

interface Response {
  content?: string;
  options?: string[];
}

interface Responses {
  [key: string]: Response
}

interface State {
  expanded: boolean;
  isClient: boolean;
  isDirty: boolean;
  questions: Question[];
  readOnly: boolean;
  responses: Responses | {};
}

class LessonQuizContent extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSubmitAnswer = this.handleSubmitAnswer.bind(this);
    this.state = {
      expanded: props.expanded || (props.readOnly && (props.membershipId === undefined)),
      isClient: false,
      isDirty: false,
      questions: [],
      readOnly: props.readOnly,
      responses: props.responses || {}
    };
  }

  changeNumber(questionId: string, type: Question['type'], value: string) {
    const floatRegex = /^\d+\.?\d{0,2}$/;
    const integerRegex = /^\d+$/;
    if (value.length >= 7) {
      return false;
    }

    if (value === '') {
      return this.updateResponse(questionId, '');
    }

    if (type === QuestionType.Integer && integerRegex.test(value)) {
      return this.updateResponse(questionId, value);
    }

    if (type === QuestionType.Float && floatRegex.test(value)) {
      return this.updateResponse(questionId, value);
    }

    return false;
  }

  clickCheckbox(questionId: string, optionId: string) {
    if (this.state.readOnly) {
      return;
    }

    if (this.isMaxReached(questionId) && !this.isOptionIncluded(questionId, optionId)) {
      return;
    }

    this.updateOptionResponses(questionId, optionId);
  }

  componentDidMount() {
    const parsedQuestions = this.parseQuestions();
    const questions = this.props.readOnly ? parsedQuestions : this.shuffleQuestionOptions(parsedQuestions);
    const responses = {};
    questions.forEach((question) => {
      if (question.type === QuestionType.Sort) {
        responses[question.id] = { options: question.sortOptions?.map((option) => option.id) };
      }
    });

    this.setState({
      isClient: true,
      questions,
      responses: this.props.readOnly ? this.state.responses : responses
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  countCorrect(questions: Question[]) {
    return questions.filter((question) => this.isCorrect(question)).length;
  }

  handleDragEnd(questionId: string, result: DropResult, sortOptionIds: string[]) {
    if (!result.destination) {
      return;
    }

    const { responses } = this.state;
    const options = reorder(sortOptionIds, result.source.index, result.destination.index);
    this.setState({
      isDirty: true,
      responses: {
        ...responses,
        [questionId]: {
          ...responses[questionId],
          options
        }
      }
    });
  }

  handleSubmitAnswer() {
    const {
      attemptsRemaining,
      block,
      cohortId,
      lessonId,
      lessonResponsesUrl,
      membershipId,
      token
    } = this.props;
    const responses = JSON.stringify(this.state.responses);
    const updatedAttemptsRemaining = typeof attemptsRemaining === 'number' ? attemptsRemaining - 1 : attemptsRemaining;
    const message = (attemptsRemaining === 1)
      ? 'You can submit your answers to this quiz once only. Are you sure you want to continue?'
      : 'After submitting your answers to this quiz,'
        + ` you will have ${updatedAttemptsRemaining} ${updatedAttemptsRemaining === 1 ? 'attempt' : 'attempts'} remaining.`
        + ' Are you sure you want to continue?';

    if ((attemptsRemaining !== 'unlimited' && !confirm(message)) || responses === '{}' || !token) {
      return;
    }

    axios
      .post(
        lessonResponsesUrl,
        {
          lessonResponse: {
            cohortId,
            lessonId,
            membershipId,
            response: responses,
            score: !this.props.manualGrading ? this.countCorrect(this.state.questions) : 0
          },
          quizId: block.id
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.setState({
          isDirty: false,
          readOnly: true
        }, () => {
          window.location.href = `${response.data.url}&notice=Quiz successfully submitted.`;
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.response.status === 406) {
          this.setState({ isDirty: false }, () => {
            window.location.href = `${error.response.data.url}&alert=You have reached this quiz attempt limit.`;
          });
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  isCorrect(question: Question) {
    const { correct, id, type } = question;
    switch (type) {
      case QuestionType.Float:
      // fall-through
      case QuestionType.Integer:
        return correct !== undefined && Number(this.state.responses[id]?.content) === correct;
      case QuestionType.Freeform:
        return correct !== undefined && this.state.responses[id]?.content === correct;
      case QuestionType.MultipleChoice:
        return !question.multipleChoiceOptions?.map((option) => this.isOptionCorrect(id, option)).includes(false);
      case QuestionType.Sort:
        return isEqual(question.sortOptions?.map((option) => option.id), this.state.responses[id]?.options);
      default:
        return false;
    }
  }

  isMaxReached(questionId: string) {
    const question = this.state.responses[questionId];
    if (!question) {
      return false;
    }

    return question.options && question.options.length >= (this.maxSelectForQuestion(questionId) || 0);
  }

  isOptionCorrect(id: string, option: MultipleChoiceOption) {
    const { correct, freeform } = option;
    const isOptionIncluded = this.isOptionIncluded(id, option.id);
    if (freeform && typeof correct === 'string') {
      return this.state.responses[id]?.content.toLocaleLowerCase() === correct.toLocaleLowerCase();
    }
    return correct ? isOptionIncluded : !isOptionIncluded;
  }

  isOptionIncluded(questionId: string, optionId: string) {
    const question = this.state.responses[questionId];
    if (!question) {
      return false;
    }

    return question.options.includes(optionId);
  }

  maxSelectForQuestion(questionId: string) {
    const question = this.state.questions.find((eachQuestion) => eachQuestion.id === questionId);
    if (question?.type !== QuestionType.MultipleChoice) {
      return NaN;
    }
    return question.maxSelectable;
  }

  parseQuestions(): Question[] {
    return typeof this.props.block.content === 'string' ? JSON.parse(this.props.block.content) : this.props.block.content;
  }

  render() {
    return (
      this.state.isClient ? this.renderClientSide() : this.renderServerSide()
    );
  }

  renderClientSide() {
    const { expanded, questions, responses } = this.state;
    const { block, enrolledUser, graded, readOnly } = this.props;
    return (
      <div className="LessonQuizContent">
        <UnsavedChangesPrompt isDirty={this.state.isDirty} />
        <div className="header">
          <Button
            imgAlt="Expand/Collapse"
            imgSrc={images.arrowHeadRight}
            onClick={async () => this.setState({ expanded: !expanded })}
            rotated={expanded}
            size="small"
          />
          <h3>{block.title}</h3>
        </div>
        <div className={`body ${expanded && 'expanded'}`}>
          {questions && questions.map((question, index) => (
            <div className={`question ${readOnly ? 'readOnly' : 'editable'}`} key={question.id}>
              <div className="header">
                <p className={`${question.required ? 'required' : ''}`}>{`Q: ${index + 1}. ${question.prompt}`}</p>
                {graded && readOnly && this.renderGrade(question)}
              </div>
              {this.renderQuestion(question)}
            </div>
          ))}
          {graded && readOnly && (
            <div className="score">
              <p>Total score: {this.countCorrect(questions)}/
                {questions.filter((question) => (
                  question.correct !== undefined || question.type === QuestionType.MultipleChoice || question.type === QuestionType.Sort
                )).length}
              </p>
            </div>
          )}
          {!readOnly && enrolledUser && (
            <div className="footer">
              <button
                disabled={this.state.readOnly || JSON.stringify(responses) === '{}'}
                onClick={this.handleSubmitAnswer}
                type="button"
              >
                {this.state.readOnly ? 'Submitted' : 'Submit'}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFreeform(question: FreeformQuestion | MultipleChoiceQuestion, option?: MultipleChoiceOption) {
    if (this.state.readOnly) {
      return <p>{this.state.responses[question.id]?.content || option?.label}</p>;
    }

    return (
      <div className="freeform">
        {question.type === QuestionType.Freeform || (option && this.isOptionIncluded(question.id, option.id))
          ? (
            <input
              autoFocus={option?.freeform}
              onChange={(event) => this.updateResponse(question.id, event.target.value)}
              placeholder={option?.label}
              type="text"
            />
          ) : option?.label || ''
        }
      </div>
    );
  }

  renderGrade(question: Question) {
    if (question.correct !== undefined || question.type === QuestionType.MultipleChoice || question.type === QuestionType.Sort) {
      const isCorrect = this.isCorrect(question);
      return (
        <img
          alt={isCorrect ? 'Correct' : 'Incorrect'}
          src={isCorrect ? images.tickBox : images.crossBox}
        />
      );
    }
    return '';
  }

  renderMultipleChoice(question: MultipleChoiceQuestion) {
    const { id, maxSelectable, multipleChoiceOptions } = question;
    const { readOnly } = this.state;
    const optionValue = (value: number) => {
      if (value === 1) {
        return 'Choose one option';
      }
      return `Choose up to ${new ToWords().convert(value).toLowerCase()} options`;
    };

    return (
      <>
        {maxSelectable && <p><small>{optionValue(maxSelectable)}</small></p>}
        {multipleChoiceOptions?.map((option) => {
          // if maxSelectable limit is reached && option not selected => disable
          const disabled = this.isMaxReached(id) && !this.isOptionIncluded(id, option.id);
          return (
            <div
              className={`multipleChoice ${disabled && !readOnly ? 'disabled' : ''}`}
              key={option.id}
              onClick={() => this.clickCheckbox(id, option.id)}
            >
              <Checkbox
                checked={this.isOptionIncluded(id, option.id)}
                disabled={disabled || readOnly}
                onClick={() => this.clickCheckbox(id, option.id)}
              />
              {
                option.freeform
                  ? this.renderFreeform(question, option)
                  : <div>{option.label}</div>
              }
            </div>
          );
        })}
      </>
    );
  }

  renderNumberQuestion(question: FloatQuestion | IntegerQuestion) {
    return (
      <input
        disabled={this.state.readOnly}
        max={question.maxValue}
        min={question.minValue}
        onChange={(event) => this.changeNumber(question.id, question.type, event.target.value)}
        placeholder={question.placeholder}
        type="number"
        value={this.state.responses[question.id]?.content || ''}
      />
    );
  }

  renderQuestion(question: Question) {
    switch (question.type) {
      case QuestionType.Float:
        // fall-through
      case QuestionType.Integer:
        return this.renderNumberQuestion(question);
      case QuestionType.Freeform:
        return this.renderFreeform(question);
      case QuestionType.MultipleChoice:
        return this.renderMultipleChoice(question);
      case QuestionType.Sort:
        return this.renderSortQuestion(question);
      default:
        return '';
    }
  }

  renderServerSide() {
    return (
      <div className="LessonQuizContent">
        <p>Please enable JavaScript and refresh the page to see the quiz.</p>
      </div>
    );
  }

  renderSortQuestion(question: SortableQuestion) {
    const { readOnly, responses } = this.props;
    const questionSortOptions = question.sortOptions;
    const sortByResponse = () => {
      const response: Response = readOnly && responses ? responses[question.id] : this.state.responses[question.id];
      return response?.options?.map((responseOption) => questionSortOptions?.find((option) => option.id === responseOption));
    };
    const sortOptions = sortByResponse();
    if (!sortOptions) {
      return '';
    }
    return (
      <DragDropContext onDragEnd={(result) => this.handleDragEnd(question.id, result, sortOptions.map((option) => option?.id || ''))}>
        <Droppable droppableId="SortQuestion">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {sortOptions.map((option, index) => {
                if (!option) {
                  return '';
                }
                return (
                  <Draggable
                    draggableId={option.id}
                    index={index}
                    isDragDisabled={readOnly}
                    key={option.id}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.draggableProps}
                        ref={providedDraggable.innerRef}
                        style={getDraggableStyle(providedDraggable.draggableProps.style)}
                      >
                        <div {...providedDraggable.dragHandleProps}>
                          <SortOption label={option.label} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  shuffleQuestionOptions(questions: Question[]) {
    return questions.map((question) => {
      switch (question.type) {
        case QuestionType.Sort:
          return {
            ...question,
            sortOptions: shuffle(question.sortOptions)
          };
        case QuestionType.MultipleChoice: {
          if (!question.shuffle) {
            return question;
          }

          const optionsWithoutFreeform = question.multipleChoiceOptions?.filter((option) => option.freeform !== true) || [];
          const freeformOption = question.multipleChoiceOptions?.find((option) => option.freeform === true) || [];

          return {
            ...question,
            multipleChoiceOptions: shuffle(optionsWithoutFreeform).concat(freeformOption)
          };
        }
        default:
          return question;
      }
    });
  }

  updateOptionResponses(questionId: string, newOptionId: string) {
    const newResponse = { [questionId]: { content: '', options: [newOptionId] } };
    const question = this.state.responses[questionId];
    const { responses } = this.state;
    // if question is not included add question with newOptionResponse
    if (!question) {
      this.setState({
        isDirty: true,
        responses: { ...responses, ...newResponse }
      });
      return;
    }

    // if maxSelectable === 1 Remove question response
    if (this.maxSelectForQuestion(questionId) === 1) {
      const newResponses = cloneDeep(responses);
      delete newResponses[questionId];
      this.setState({
        isDirty: true,
        responses: newResponses
      });
      return;
    }

    this.setState({
      isDirty: true,
      responses: {
        ...responses,
        [questionId]: {
          ...responses[questionId],
          options:
            // if newOption is included delete it
            this.isOptionIncluded(questionId, newOptionId)
              ? question.options.filter((option: string) => option !== newOptionId)
              // else push new newOptionResponse
              : question.options.concat(newOptionId)
        }
      }
    });
  }

  updateResponse(questionId: string, content: string) {
    const { responses } = this.state;
    this.setState({
      isDirty: true,
      responses: { ...responses, [questionId]: { ...responses[questionId], content } }
    });
  }
}

export default LessonQuizContent;
