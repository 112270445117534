import * as React from 'react';

import { LessonData } from './interfaces';

interface Props {
  lessonData: LessonData;
  onClosePopup: (() => void);
}

function LessonPreviewPopup(props: Props) {
  return (
    <div className="LessonPreviewPopup">
      <div className="body">
        {props.lessonData.blocks.length > 0
          ? (
            <ul className="list">
              {props.lessonData.blocks.map((block) => (
                <li key={block.id}>
                  {block.title} ({block.type})
                </li>
              ))}
            </ul>
          )
          : (
            <div className="noBlocks">
              <p>There are no blocks for this lesson.</p>
            </div>
          )
        }
      </div>
      <div className="footer">
        <a onClick={props.onClosePopup}>
          Cancel
        </a>
      </div>
    </div>
  );
}

export default LessonPreviewPopup;
