import * as React from 'react';

interface Props {
  errors: [];
  onClickCancel: (() => void);
  onClickRetry: (() => void);
  referralsAlreadySent: string[];
  referralsNotSent: string[];
  referralsSent: string[];
}

const referralList = (referrals: string[], title: string) => (
  <div className="content">
    <h3>{title}</h3>
    <ul>
      {referrals.map((referral, index) => <li key={index}>{referral}</li>)}
    </ul>
  </div>
);

function InvitationsPopupSummary(props: Props) {
  return (
    <div className="InvitationsPopupSummary">
      {props.errors && (
        <div className="errorPanel">
          {referralList(props.errors, 'Some of your invitations could not be sent because of the following errors:')}
        </div>
      )}
      {props.referralsSent.length > 0 && referralList(props.referralsSent, 'Invitations sent:')}
      {props.referralsNotSent.length > 0 && referralList(props.referralsNotSent, 'Invitations that could not be sent:')}
      {props.referralsAlreadySent.length > 0 && referralList(props.referralsAlreadySent, 'Invitations already sent:')}
      <div className="footer">
        {props.referralsNotSent.length > 0
          ? (
            <>
              <a onClick={props.onClickCancel}>
                Cancel
              </a>
              <button
                onClick={props.onClickRetry}
                type="button"
              >
                Try again
              </button>
            </>
          )
          : (
            <button
              onClick={props.onClickCancel}
              type="button"
            >
              Done
            </button>
          )
        }
      </div>
    </div>
  );
}

export default InvitationsPopupSummary;
