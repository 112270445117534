import * as React from 'react';

interface Props {
  isDirty: boolean;
}

interface State {
  originalValue: string | undefined;
}

class UnsavedChangesPrompt extends React.Component<Props, State> {
  componentDidMount() {
    this.disableTurbolinks();
  }

  componentDidUpdate(_prevProps: Props, _prevState: State) {
    if (this.props.isDirty) {
      // Ask user before leaving without saving
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    this.restoreTurbolinks();
    window.onbeforeunload = null;
  }

  disableTurbolinks() {
    const body = $('body');
    if (body) {
      this.setState({
        originalValue: body.attr('data-turbolinks')
      });
      body.attr('data-turbolinks', 'false');
    }
  }

  render() {
    return '';
  }

  restoreTurbolinks() {
    const body = $('body');
    if (this.state.originalValue) {
      body.attr('data-turbolinks', this.state.originalValue);
    } else {
      body.removeAttr('data-turbolinks');
    }
  }
}

export default UnsavedChangesPrompt;
