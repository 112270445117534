import * as React from 'react';
import axios from 'axios';

import { InteractionElement } from './interfaces';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string;
  cohortId?: string;
  groupId?: string;
  indexUrlClient: string;
  indexUrlServer: string;
  interactableId?: string;
  interactions: InteractionElement[];
  isClient: boolean;
  onSubmitInteraction: ((interaction: InteractionElement) => void);
  profileId?: string;
  prompt: string;
  promptMinLength: number;
  token: string;
}

interface State {
  newContent: string;
}

class InteractionSubmitForm extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      newContent: ''
    };
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const {
      cohortId,
      groupId,
      indexUrlClient,
      interactableId,
      onSubmitInteraction,
      profileId,
      token
    } = this.props;
    const { newContent } = this.state;
    this.setState({ newContent: '' });

    axios
      .post(
        indexUrlClient,
        {
          interaction: {
            cohortId,
            content: newContent,
            groupId,
            profileId
          },
          interactableId
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        onSubmitInteraction(response.data.interaction);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  render() {
    const {
      authenticityToken,
      indexUrlClient,
      indexUrlServer,
      interactableId,
      isClient,
      profileId,
      prompt,
      promptMinLength
    } = this.props;
    const { newContent } = this.state;
    return (
      <div className="InteractionSubmitForm">
        <form
          action={isClient ? indexUrlClient : indexUrlServer}
          method="post"
          onSubmit={this.handleSubmit}
        >
          <input name="authenticity_token" type="hidden" value={authenticityToken} />
          <input name="interactable_id" type="hidden" value={interactableId} />
          <input name="interaction[profile_id]" type="hidden" value={profileId} />
          <h4>{prompt}</h4>
          <textarea
            minLength={promptMinLength}
            name="interaction[content]"
            onChange={(event) => this.setState({ newContent: event.target.value })}
            placeholder="Your content..."
            required
            value={newContent}
          />
          <div className="footer">
            <input
              disabled={isClient && newContent.trim().length < promptMinLength}
              type="submit"
              value="Submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

export default InteractionSubmitForm;
