import slugify from 'slugify';

interface Options {
  locale?: string;
  lower?: boolean;
  remove?: RegExp;
  replacement?: string;
  strict?: boolean;
}

export default function uniqueSlug(name: string, existingSlugs: string[], options: Options = { lower: true }) {
  const preferredSlug = slugify(name, options);

  // Check if preferred slug is available
  if (existingSlugs.indexOf(preferredSlug) === -1) {
    return preferredSlug;
  }

  // Check for similar slugs - i.e. those with the same preferred slug, but possibly a suffix
  const similarSlugRegex = new RegExp(`^${preferredSlug}-(\\d+)$`);
  const similarSlugSuffixes: number[] = [];
  existingSlugs.forEach((slug) => {
    const matches = similarSlugRegex.exec(slug);
    if (matches) {
      similarSlugSuffixes.push(parseInt(matches[1], 10));
    }
  });

  // None found? Start with one
  if (similarSlugSuffixes.length === 0) {
    return `${preferredSlug}-1`;
  }

  // Add one to the suffix of the last similar sequence
  similarSlugSuffixes.sort((a, b) => b - a);
  return `${preferredSlug}-${similarSlugSuffixes[0] + 1}`;
}
