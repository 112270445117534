// Support component names relative to this directory:
const componentRequireContext = require.context('../components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

const mountComponents = ReactRailsUJS.mountComponents.bind(ReactRailsUJS);

ReactRailsUJS.handleEvent('turbolinks:before-render', () => {
  ReactRailsUJS.unmountComponents(document.body);
});

ReactRailsUJS.mountComponents = (searchSelector) => {
  mountComponents(searchSelector);
};
