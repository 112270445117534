import * as React from 'react';
import axios from 'axios';

import { Block, BlockQuiz } from './interfaces';
import LessonEditor from './LessonEditor';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import headers from '../utils/headersGenerator';

interface Props {
  initialBlocks: Block[];
  lessonAttachmentUrl: string;
  lessonPreviewUrl: string;
  lessonUrl: string;
  quizzes: BlockQuiz[];
  title: string;
  token: string;
}

interface State {
  isClient: boolean;
  isDirty: boolean;
  savingChanges: boolean;
}

const renderNotice = () => (
  <div className="notice">
    <p>The lesson content editor requires JavaScript to be enabled.</p>
    <p>Please enable JavaScript and refresh the page to continue.</p>
  </div>
);

class LessonEditorContainer extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSaveChanges = this.handleSaveChanges.bind(this);
    this.state = {
      isClient: false,
      isDirty: false,
      savingChanges: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleSaveChanges(currentBlocks: Block[]) {
    if (this.state.savingChanges) {
      return;
    }

    const blocks = currentBlocks.map((block) => ({
      ...block,
      content: JSON.stringify(block.content)
    }));

    this.setState(
      {
        savingChanges: true
      },
      () => {
        // Only start the request once the state has been updated (prevent bounce)
        const { lessonUrl, token } = this.props;
        axios
          .put(
            lessonUrl,
            { blocks },
            {
              cancelToken: this.signal.token,
              headers: headers(token)
            }
          )
          .then((response) => {
            this.setState({
              isDirty: false
            }, () => {
              window.location.href = `${response.data.lessonUrl}?notice=Lesson successfully updated.`;
            });
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.debug(error.message);
            } else {
              console.error(error);
              alert('Something went wrong, please refresh the page and try again.');
              this.setState({
                savingChanges: false
              });
            }
          });
      }
    );
  }

  render() {
    if (!this.state.isClient) {
      return renderNotice();
    }
    return (
      <div className="LessonEditorContainer">
        <LessonEditor
          initialBlocks={this.props.initialBlocks}
          lessonAttachmentUrl={this.props.lessonAttachmentUrl}
          lessonPreviewUrl={this.props.lessonPreviewUrl}
          onDirty={() => this.setState({ isDirty: true })}
          onSaveChanges={this.handleSaveChanges}
          quizzes={this.props.quizzes}
          savingChanges={this.state.savingChanges}
          title={this.props.title}
          token={this.props.token}
        />
        <UnsavedChangesPrompt isDirty={this.state.isDirty} />
      </div>
    );
  }
}

export default LessonEditorContainer;
