import * as React from 'react';
import axios from 'axios';

import Button from './Button';
import headers from '../utils/headersGenerator';
import images from '../utils/images';

interface Props {
  authenticityToken: string;
  canLike: boolean;
  likeId: string | null;
  likeableId: string;
  likeableType: string;
  likesUrl: string;
  membershipId?: string;
  token: string;
}

interface State {
  isClient: boolean;
  likeId: string | null;
}

class LikeButton extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClickButton = this.handleClickButton.bind(this);

    this.state = {
      isClient: false,
      likeId: this.props.likeId
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  create() {
    const { likeableId, likeableType, likesUrl, membershipId, token } = this.props;
    return axios
      .post(
        likesUrl,
        {
          like: {
            likeableId,
            likeableType,
            membershipId
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.setState({
          likeId: response.data.like_id
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  delete() {
    const { likeId } = this.state;
    const { likesUrl, token } = this.props;

    return axios
      .delete(
        `${likesUrl}/${likeId}`,
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({ likeId: null });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  handleClickButton() {
    if (this.state.likeId) {
      return this.delete();
    }
    return this.create();
  }

  render() {
    return (
      <div className="LikeButton">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { likeId } = this.state;
    return (
      <Button
        disabled={!this.props.canLike}
        imgAlt={likeId ? 'unlike' : 'like'}
        imgSrc={likeId ? images.thumbsUpActive : images.thumbsUp}
        onClick={this.handleClickButton}
        size="medium"
      />
    );
  }

  renderServerSide() {
    const { authenticityToken, canLike, likeableId, likeableType, likeId, membershipId } = this.props;
    return (
      <form action={likeId ? `/likes/${likeId}` : '/likes'} method="post">
        {likeId && <input name="_method" type="hidden" value="delete" />}
        {!likeId && (
          <>
            <input name="likeable_id" type="hidden" value={likeableId} />
            <input name="likeable_type" type="hidden" value={likeableType} />
          </>
        )}
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <input name="membership_id" type="hidden" value={membershipId} />
        <label className={canLike ? '' : 'disabled'}>
          <input type="submit" />
          <img alt={likeId ? 'Unlike' : 'Like'} src={likeId ? images.thumbsUpActive : images.thumbsUp} />
        </label>
      </form>
    );
  }
}

export default LikeButton;
