import * as React from 'react';
import { Markup } from 'interweave';

interface Props {
  content: string;
}

interface State {
  isClient: boolean
}

class RenderHTML extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isClient: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  render() {
    const { content } = this.props;
    return (
      this.state.isClient
        ? <Markup content={content} />
        // eslint-disable-next-line react/no-danger
        : <span dangerouslySetInnerHTML={{ __html: content }} />
    );
  }
}

export default RenderHTML;
