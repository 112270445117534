import * as React from 'react';

interface Props {
  size?: number;
}

const getStyle = (props: Props) => {
  const size = props.size || 120;
  const border = Math.floor(size / 7.5);
  return {
    border: `${border}px solid #f3f3f3`,
    borderTop: `${border}px solid #3498db`,
    height: `${size}px`,
    width: `${size}px`
  };
};

function Spinner(props: Props) {
  return <div className="Spinner" style={getStyle(props)} />;
}

export default Spinner;
