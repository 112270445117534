import * as React from 'react';
import axios from 'axios';

import ConversationProfile from './ConversationProfile';
import MessengerSearch from './MessengerSearch';
import { Profile } from './interfaces';
import headers from '../utils/headersGenerator';
import isBlank from '../utils/isBlank';

interface Props {
  conversationUrl: string;
  counterpartId?: string;
  counterparts: Profile[];
  delayTime: number;
  isOnline: boolean;
  onClickConversation: ((counterpart: Profile) => void);
  onGetUnreadMessagesProfileIds?: ((profileIds: string[]) => void);
  onUpdateDelayTime: (() => void);
  onUpdateOnlineStatus: ((isOnline: boolean) => void);
  onUpdateShowOverlay: ((showOverlay: boolean) => void);
  profileId: string;
  token?: string;
  unreadMessagesProfileIds: string[];
}

interface State {
  conversationSearchTerm: string;
  counterparts: Profile[];
  filteredProfileIds: string[];
  intervalId: number;
}

class ConversationList extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    const { counterparts, delayTime } = this.props;
    this.state = {
      conversationSearchTerm: '',
      counterparts,
      filteredProfileIds: [],
      intervalId: window.setInterval(() => this.getConversationList(), delayTime)
    };
  }

  changeConversationInput(conversationSearchTerm: string) {
    this.setState({
      conversationSearchTerm
    }, () => {
      this.updateCounterparts();
    });
  }

  clickConversation(profile: Profile) {
    const {
      isOnline,
      onClickConversation,
      onGetUnreadMessagesProfileIds,
      onUpdateOnlineStatus,
      onUpdateShowOverlay,
      unreadMessagesProfileIds } = this.props;

    if (!isOnline) {
      onUpdateOnlineStatus(navigator.onLine);
      onUpdateShowOverlay(true);
      return;
    }

    const profileIds = unreadMessagesProfileIds.filter((profileId) => profileId !== profile.id);
    if (onGetUnreadMessagesProfileIds) {
      onGetUnreadMessagesProfileIds(profileIds);
    }

    onClickConversation(profile);
  }

  componentDidMount() {
    this.filterProfiles();
  }

  componentDidUpdate(prevProps: Props) {
    const { delayTime } = this.props;
    if (prevProps.delayTime !== delayTime) {
      window.clearInterval(this.state.intervalId);
      this.setState({
        intervalId: window.setInterval(() => this.getConversationList(), delayTime)
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.signal.cancel('Request cancelled.');
  }

  filterByName(profiles: Profile[]) {
    const searchTerm = this.state.conversationSearchTerm.toLowerCase();
    return profiles.filter((profile) => profile.displayName.toLowerCase().includes(searchTerm));
  }

  filterProfiles() {
    const { counterparts } = this.props;
    const { conversationSearchTerm, filteredProfileIds } = this.state;
    const filterById = (allProfiles: Profile[]) => allProfiles.filter((profile) => filteredProfileIds.includes(profile.id));

    if (filteredProfileIds.length === 0) {
      this.setState({
        counterparts: this.filterByName(counterparts)
      });
      return;
    }

    if (isBlank(conversationSearchTerm)) {
      this.setState({
        counterparts: filterById(counterparts)
      });
      return;
    }
    this.setState({
      counterparts: this.filterByName(filterById(counterparts))
    });
  }

  getConversationList() {
    const {
      conversationUrl,
      isOnline,
      onGetUnreadMessagesProfileIds,
      onUpdateDelayTime,
      onUpdateOnlineStatus,
      profileId,
      token,
      unreadMessagesProfileIds } = this.props;

    if (!token || !onGetUnreadMessagesProfileIds) {
      return;
    }

    if (!isOnline) {
      onUpdateOnlineStatus(navigator.onLine);
      onUpdateDelayTime();
      return;
    }

    axios
      .get(
        conversationUrl,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: { profileId }
        }
      )
      .then((response) => {
        const responseProfileIds = response.data.unreadMessagesProfileIds;
        if (onGetUnreadMessagesProfileIds && responseProfileIds !== unreadMessagesProfileIds) {
          onGetUnreadMessagesProfileIds(responseProfileIds);
        }
        onUpdateOnlineStatus(true);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          onUpdateOnlineStatus(false);
          console.error(error);
        }
      });
  }

  noResultFound() {
    const { conversationSearchTerm, counterparts } = this.state;
    return (
      (conversationSearchTerm?.length > 0
        || this.props.counterparts?.length === 0
      ) && counterparts?.length === 0
    );
  }

  render() {
    return (
      <div className="ConversationList">
        <MessengerSearch
          conversationSearchTerm={this.state.conversationSearchTerm}
          onChangeConversationInput={(inputValue) => this.changeConversationInput(inputValue)}
        />
        {this.renderConversationList()}
      </div>
    );
  }

  renderConversationList() {
    const { counterpartId, unreadMessagesProfileIds } = this.props;
    const { counterparts } = this.state;
    return (
      <div className="conversationList">
        {this.noResultFound()
          ? <div className="notFound">No conversations found.</div>
          : counterparts.map((profile) => (
            <ConversationProfile
              counterpartId={counterpartId}
              key={profile.id}
              onClickConversation={() => this.clickConversation(profile)}
              profile={profile}
              unreadMessagesProfileIds={unreadMessagesProfileIds}
            />
          ))
        }
      </div>
    );
  }

  updateCounterparts() {
    const { counterparts } = this.props;
    const updatedCounterparts = isBlank(this.state.conversationSearchTerm) ? counterparts : this.filterByName(counterparts);
    this.setState({
      counterparts: updatedCounterparts
    });
  }
}

export default ConversationList;
