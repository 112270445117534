import * as React from 'react';
import { NotificationElement } from './interfaces';

interface Props {
  notification: NotificationElement;
  onClickHideShow: (() => void);
  onClickMarkAs: (() => void);
}

function NotificationSubMenu(props: Props) {
  const { notification, onClickHideShow, onClickMarkAs } = props;

  return (
    <div className="NotificationSubMenu">
      <div className="item">
        <button onClick={() => onClickMarkAs()} type="button">
          Mark as {notification.read ? 'unread' : 'read'}
        </button>
      </div>
      <div className="item">
        <button onClick={() => onClickHideShow()} type="button">
          {notification.hidden ? 'Unhide' : 'Hide'} notification
        </button>
      </div>
    </div>
  );
}

export default NotificationSubMenu;
