import * as React from 'react';

import Button from './Button';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import images from '../utils/images';

interface Props {
  children: React.ReactNode;
  isBlockExtended: boolean;
  lessonErrorMessages: string[];
  onBlur: (() => void);
  onClickArrowHead: (() => void);
  onEditingTitle: (() => void);
  onRemoveBlock: (() => void);
  onUpdateTitle: ((title: string) => void);
  title: string;
}

function HandleFrame(props: Props) {
  return (
    <div className="HandleFrame">
      <div className="content">
        <div className="blockHeader">
          <Button
            imgAlt="Expand/Collapse"
            imgSrc={images.arrowHeadRight}
            onClick={async () => props.onClickArrowHead()}
            rotated={props.isBlockExtended}
            size="small"
          />
          <TitleEditor
            onBlur={props.onBlur}
            onEditing={() => props.onEditingTitle()}
            onUpdateTitle={props.onUpdateTitle}
            showErrorIcon={props.lessonErrorMessages.length > 0}
            title={props.title}
          />
          <Button
            imgAlt="Remove"
            imgSrc={images.crossBox}
            onClick={async () => props.onRemoveBlock()}
            size="large"
          />
        </div>
        {props.isBlockExtended && (
          <div className="mainBody">
            {props.children}
            {props.lessonErrorMessages.length > 0 && (
              <div className="footer">
                <ValidationErrors messages={props.lessonErrorMessages} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default HandleFrame;
