import * as React from 'react';

import images from '../utils/images';

interface Props {
  messages: string[];
}

function ValidationErrors(props: Props) {
  return (
    <div className="ValidationErrors">
      <img alt="Error sign" src={images.error} />
      {props.messages.join(' ')}
    </div>
  );
}

export default ValidationErrors;
