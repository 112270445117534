import * as React from 'react';
import axios from 'axios';

import { Avatar } from './interfaces';
import PopupLayout from './PopupLayout';
import images from '../utils/images';

interface Props {
  avatars: Avatar[];
  clientSignupUrl: string;
  logoUrl: string;
  omniauthButtons: JSX.Element;
  onClickLogin: (() => void);
  onClose: (() => void);
  redirectUrl: string;
}

interface State {
  avatarSelected: string;
  displayName: string;
  email: string;
  errors: Array<{attribute: string, message: string}>;
  firstName: string;
  inFlight: boolean;
  showForm: boolean;
}

class SignupPopup extends React.Component <Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.state = {
      avatarSelected: '',
      displayName: '',
      email: '',
      errors: [],
      firstName: '',
      inFlight: false,
      showForm: false
    };
  }

  createField(name: 'displayName' | 'email' | 'firstName') {
    return (
      <>
        <input
          className={this.findError(name) ? 'withError' : ''}
          // @ts-expect-error
          onBlur={() => this.setState({ [name]: this.state[name].trim() })}
          // @ts-expect-error
          onChange={(event) => this.setState({ [name]: event.target.value })}
          required
          type="text"
          value={this.state[name]}
        />
        {this.findError(name) && <div className="details">{this.findError(name)}</div>}
      </>
    );
  }

  findError(attribute: string) {
    const { errors } = this.state;
    if (errors.length === 0) {
      return undefined;
    }

    const filteredErrors = errors.filter((error) => error.attribute === attribute);
    return filteredErrors.map((error) => error.message).join();
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const { clientSignupUrl, redirectUrl } = this.props;
    const { avatarSelected, displayName, email, firstName } = this.state;
    this.setState({
      displayName: displayName.trim(),
      email: email.trim(),
      firstName: firstName.trim(),
      inFlight: true
    }, () => {
      axios
        .post(
          clientSignupUrl,
          {
            avatar: avatarSelected,
            signup: {
              displayName,
              email,
              firstName
            }
          },
          {
            cancelToken: this.signal.token,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') || ''
            }
          }
        )
        .then((response) => {
          if (response.data.errors) {
            this.setState({
              errors: response.data.errors,
              inFlight: false
            });
          } else {
            window.location.href = `${redirectUrl}?notice=${response.data.notice}`;
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.debug(error.message);
          } else {
            console.error(error);
          }
          this.setState({
            inFlight: false
          });
        });
    });
  }

  render() {
    const { avatarSelected, inFlight, showForm } = this.state;
    const { avatars, logoUrl, omniauthButtons, onClickLogin, onClose } = this.props;

    return (
      <PopupLayout loading={inFlight} onClose={onClose} title="Sign Up" width="23em">
        <div className="SignupPopup">
          {showForm && (
            <div className="goBackContainer">
              <button className="goBack" onClick={() => this.setState({ showForm: false })} type="button">
                &#x3c;
              </button>
            </div>
          )}
          {!showForm && (
            <>
              <img alt="Site Logo" className="logo" src={logoUrl} />
              <div className="login">
                Already a member? <button onClick={onClickLogin} type="button">Log in</button>
              </div>
              <button className="emailButton" onClick={() => { this.setState({ showForm: true }); }} type="button">
                <img alt="Sing up with email" src={images.envelope} />
                <div className="title">Sign up with email</div>
              </button>
              {omniauthButtons}
            </>
          )}
          {showForm && (
            <>
              <form className="emailForm" onSubmit={(event) => this.handleSubmit(event)}>
                <label required>Display name</label>
                <div className="description">Name displayed in groups</div>
                {this.createField('displayName')}
                <label required>First name</label>
                <div className="description">Will not be visible to other users</div>
                {this.createField('firstName')}
                <label required>Email</label>
                <div className="description">Will not be visible to other users</div>
                {this.createField('email')}
                <label>Select an Avatar</label>
                <div className="avatars">
                  {avatars.map((avatar, index) => (
                    <button
                      className={(avatarSelected === '' && index === 0) || avatarSelected === avatar.path ? 'selected' : ''}
                      key={avatar.path}
                      onClick={() => this.setState({ avatarSelected: avatar.path })}
                      type="button"
                    >
                      <img alt="Avatar-{index}" src={avatar.url} />
                    </button>
                  ))}
                </div>
                <div className="footer">
                  <button disabled={inFlight} type="submit">
                    Join
                  </button>
                </div>
              </form>
              <div className="login">
                Already a member? <button onClick={onClickLogin} type="button">Log in</button>
              </div>
            </>
          )}
        </div>
      </PopupLayout>
    );
  }
}

export default SignupPopup;
