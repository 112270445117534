import * as React from 'react';

import Dropzone, { FileRejection } from 'react-dropzone';

interface Props {
  inFlight: boolean;
  onCancel: () => void;
  onCreateImage: ((blob: Blob) => void);
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
}

const colors = [
  { name: 'Silver', code: '#C0C0C0' },
  { name: 'Gray', code: '#808080' },
  { name: 'Black', code: '#000000' },
  { name: 'Red', code: '#FF0000' },
  { name: 'Maroon', code: '#800000' },
  { name: 'Yellow', code: '#FFFF00' },
  { name: 'Olive', code: '#808000' },
  { name: 'Lime', code: '#00FF00' },
  { name: 'Green', code: '#008000' },
  { name: 'Aqua', code: '#00FFFF' },
  { name: 'Teal', code: '#008080' },
  { name: 'Blue', code: '#0000FF' },
  { name: 'Navy', code: '#000080' },
  { name: 'Fuchsia', code: '#FF00FF' },
  { name: 'Purple', code: '#800080' }
];

const getBackgroundColorStyle = (code: string): React.CSSProperties => ({
  backgroundColor: code
});

function CoverUploader(props: Props) {
  const createColoredCover = (code: string) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const height = 600;
    const image = new Image();
    const width = 900;
    canvas.width = width;
    canvas.height = height;
    if (!ctx) {
      return;
    }

    ctx.fillStyle = code;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      0,
      0,
      width,
      height,
      0,
      0,
      width,
      height
    );
    canvas.toBlob((blob) => blob && props.onCreateImage(blob));
  };

  return (
    <div className="CoverUploader">
      <div className="body">
        <label>Choose a solid color or upload a photo</label>
        <div className="colors">
          {colors.map((color) => (
            <button
              className="color"
              key={color.code}
              onClick={() => createColoredCover(color.code)}
              style={getBackgroundColorStyle(color.code)}
              title={color.name}
              type="button"
            >
              {}
            </button>
          ))}
        </div>
      </div>
      <div className="footer">
        <a onClick={props.onCancel} type="button">
          Cancel
        </a>
        <Dropzone
          accept="image/gif, image/heic, image/jpg, image/jpeg, image/png"
          maxSize={5242880}
          minSize={0}
          multiple={false}
          onDrop={props.onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="button" {...getRootProps()}>
              <button disabled={props.inFlight} type="button">
                <input {...getInputProps()} />
                Upload
              </button>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

export default CoverUploader;
