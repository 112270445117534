import * as React from 'react';
import { FileError, FileRejection } from 'react-dropzone';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Button from './Button';
import CropperPopup from './CropperPopup';
import PopupLayout from './PopupLayout';
import headers from '../utils/headersGenerator';

interface Props {
  groupId: string;
  logoSrc: string;
  logoUploaderUrl: string;
  token: string;
}

interface State {
  cancelUpload: null | (() => void);
  dropId: string;
  image: string;
  imageType: string;
  isClient: boolean;
  loading: boolean;
  newImage: string;
  showCropper: boolean;
}

class LogoEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    const { logoSrc } = this.props;
    this.state = {
      cancelUpload: null,
      dropId: '',
      image: logoSrc,
      imageType: '',
      isClient: false,
      loading: false,
      newImage: logoSrc,
      showCropper: false
    };
  }

  alertCorruptFile() {
    alert('Error loading the image. File type is not recognised or data is corrupted.');
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  async convertBlob(blob: Blob) {
    const isHeic = blob.type === 'image/heic';
    if (isHeic) {
      const conversor = require('../utils/heicConversor'); // eslint-disable-line global-require
      return conversor.default(blob);
    }
    const image = new Image();
    image.src = URL.createObjectURL(blob);
    return image.decode()
      .then(() => blob)
      .catch(() => { throw new Error('Could not decode image'); });
  }

  handleClose() {
    if (this.state.cancelUpload) {
      this.state.cancelUpload();
    }
    this.setState({
      dropId: '',
      imageType: '',
      loading: false,
      newImage: this.state.image,
      showCropper: false
    });
  }

  handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    const dropId = uuidv4();
    this.setState({ dropId });
    acceptedFiles.forEach((file) => {
      this.setState({
        imageType: file.type,
        loading: true
      });
      const reader = new FileReader();
      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const blob = new Blob([binaryStr || ''], { type: file.type });
        this.convertBlob(blob)
          .then((convertedBlob) => {
            // Discard result if popup was closed, so far a promise can not be cancelled.
            if (this.state.dropId !== dropId) {
              return;
            }
            this.setState({
              loading: false,
              newImage: URL.createObjectURL(convertedBlob)
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
              showCropper: false
            }, () => this.alertCorruptFile());
          });
      };
      reader.readAsArrayBuffer(file);
    });

    if (fileRejections.length > 0) {
      alert(fileRejections[0].errors.map((error: FileError) => {
        if (error.code === 'file-too-large') {
          const maxSizeMB = (5242880 / 1048576).toFixed(2);
          return `File must be smaller than ${maxSizeMB} MB`;
        }
        return error.message;
      })[0]);
    }
  }

  render() {
    return this.state.isClient ? this.renderClientSide() : this.renderServerSide();
  }

  renderClientSide() {
    const { image, showCropper } = this.state;
    return (
      <div className="LogoEditor">
        <button
          className="edit"
          onClick={() => this.setState({ showCropper: true })}
          type="button"
        >
          Edit
        </button>
        <div className="logoImage">
          <Button
            imgAlt="Logo"
            imgSrc={image}
            onClick={async () => this.setState({ showCropper: true })}
          />
        </div>
        {showCropper && this.renderCropperModal()}
      </div>
    );
  }

  renderCropperModal() {
    const { imageType, loading, newImage } = this.state;
    return (
      <PopupLayout
        loading={loading}
        onClose={this.handleClose}
        title="Edit Logo"
      >
        <CropperPopup
          aspect={1}
          image={newImage}
          imageType={imageType}
          onCancel={this.handleClose}
          onDrop={this.handleDrop}
          onUploadFile={(blob) => this.uploadFile(blob)}
          showUpload
        />
      </PopupLayout>
    );
  }

  renderServerSide() {
    return <>JavaScript must be enabled to edit logo image.</>;
  }

  uploadFile(blob: Blob) {
    const cancelToken = new axios.CancelToken((canceler) => {
      this.setState({
        cancelUpload: canceler,
        loading: true
      });
    });
    const { logoUploaderUrl, groupId, token } = this.props;
    const extension = blob.type.split('/')[1];
    const timestamp = new Date().getTime();
    const file = new File([blob], `logo-${timestamp}.${extension}`);
    const formData = new FormData();
    formData.append('Logo', file);
    formData.append('groupId', groupId);
    axios
      .post(
        logoUploaderUrl,
        formData,
        {
          cancelToken,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.setState({
          image: URL.createObjectURL(blob),
          loading: false,
          showCropper: false
        });
        const img = new Image();
        img.onload = () => this.setState({ image: img.src });
        img.src = response.data.newLogoSource;
      })
      .catch((error) => {
        this.setState({
          loading: false,
          showCropper: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }
}

export default LogoEditor;
