import * as React from 'react';

import { EmailAddress } from './interfaces';
import MultiSelectorElement from './MultiSelectorElement';

interface Props {
  emailAddresses: EmailAddress[];
  inputValue: string;
  message: string;
  onBlur: (() => void);
  onCancel: (() => void);
  onChange: ((values: EmailAddress[]) => void);
  onInputChange: ((value: string) => void);
  onKeyDown: ((event: React.KeyboardEvent) => void);
  onMessageChange: ((value: string) => void);
  onSubmit: (() => void);
  sending: boolean;
}

function InvitationsPopupBody(props: Props) {
  return (
    <div className="InvitationsPopupBody">
      <div className="content">
        <label>To</label>
        <div className="input">
          <MultiSelectorElement
            emailAddresses={props.emailAddresses}
            inputValue={props.inputValue}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            onKeyDown={props.onKeyDown}
            sending={props.sending}
          />
        </div>
        <label>Message</label>
        <textarea
          disabled={props.sending}
          onChange={(event) => props.onMessageChange(event.target.value)}
          placeholder="Optional"
          spellCheck
          value={props.message}
        />
      </div>
      <div className="footer">
        <a onClick={props.onCancel}>
          Cancel
        </a>
        <button
          disabled={props.sending || props.emailAddresses.length === 0}
          onClick={props.onSubmit}
          type="submit"
        >
          {props.sending ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
}

export default InvitationsPopupBody;
