import * as React from 'react';
import { FileError, FileRejection } from 'react-dropzone';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Button from './Button';
import CropperPopup from './CropperPopup';
import CustomAvatarUploader from './CustomAvatarUploader';
import { DefaultAvatarInterface } from './interfaces';
import DefaultAvatarList from './DefaultAvatarList';
import PopupLayout from './PopupLayout';
import headers from '../utils/headersGenerator';

interface Props {
  avatarSrc: string;
  avatarUploaderUrl: string;
  customAvatarAllowed: boolean;
  defaultAvatars: [{path: string, url: string}];
  extraClassName?: string;
  hasAvatar: boolean;
  maxSize?: number;
  profileId?: string;
  token: string;
}

interface State {
  cancelUpload: null | (() => void);
  dropId: string;
  hasAvatar: boolean;
  image: string;
  imageType: string;
  inputName: string;
  inputValue: string;
  isClient: boolean;
  loading: boolean;
  newImage: string;
  showCropper: boolean;
  showPopup: boolean;
}
class AvatarEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    const { avatarSrc, hasAvatar } = this.props;
    this.state = {
      cancelUpload: null,
      dropId: '',
      hasAvatar,
      image: avatarSrc,
      imageType: '',
      inputName: '',
      inputValue: '',
      isClient: false,
      loading: false,
      newImage: avatarSrc,
      showCropper: false,
      showPopup: false
    };
  }

  alertCorruptFile() {
    alert('Error loading the image. File type is not recognised or data is corrupted.');
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  async convertBlob(blob: Blob) {
    const isHeic = blob.type === 'image/heic';
    if (isHeic) {
      const conversor = require('../utils/heicConversor'); // eslint-disable-line global-require
      return conversor.default(blob);
    }
    const image = new Image();
    image.src = URL.createObjectURL(blob);
    return image.decode()
      .then(() => blob)
      .catch(() => { throw new Error('Could not decode image'); });
  }

  async handleClickImage() {
    this.setState({ showPopup: true });
  }

  handleClose() {
    if (this.state.cancelUpload) {
      this.state.cancelUpload();
    }
    this.setState({
      dropId: '',
      imageType: '',
      loading: false,
      newImage: this.state.image,
      showCropper: false,
      showPopup: false
    });
  }

  handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    const dropId = uuidv4();
    this.setState({ dropId });
    acceptedFiles.forEach((file) => {
      this.setState({
        imageType: file.type,
        loading: true
      });
      const reader = new FileReader();
      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const blob = new Blob([binaryStr || ''], { type: file.type });
        this.convertBlob(blob)
          .then((convertedBlob) => {
            // Discard result if popup was closed, so far a promise can not be cancelled.
            if (this.state.dropId !== dropId) {
              return;
            }
            this.setState({
              loading: false,
              showCropper: true,
              showPopup: false,
              newImage: URL.createObjectURL(convertedBlob)
            });
          })
          .catch(() => {
            this.setState({
              loading: false,
              showCropper: false
            }, () => this.alertCorruptFile());
          });
      };
      reader.readAsArrayBuffer(file);
    });

    if (fileRejections.length > 0) {
      alert(fileRejections[0].errors.map((error: FileError) => {
        if (error.code === 'file-too-large') {
          const maxSizeMB = ((this.props.maxSize || 5242880) / 1048576).toFixed(2);
          return `File must be smaller than ${maxSizeMB} MB`;
        }
        return error.message;
      })[0]);
    }
  }

  handleRemove() {
    const { avatarUploaderUrl, profileId, token } = this.props;
    this.setState({ loading: true }, () => {
      axios
        .delete(
          avatarUploaderUrl,
          {
            cancelToken: this.signal.token,
            headers: headers(token),
            params: {
              profileId
            }
          }
        )
        .then((response) => {
          const img = new Image();
          img.onload = () => this.setState({
            hasAvatar: false,
            image: img.src,
            loading: false,
            newImage: img.src,
            showPopup: false
          });
          img.src = response.data.newAvatarSource;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.debug(error.message);
          } else {
            console.error(error);
            alert('Something went wrong, please refresh the page and try again.');
            this.setState({
              loading: false,
              showPopup: false
            });
          }
        });
    });
  }

  render() {
    return (
      <div className={`AvatarEditor ${this.props.extraClassName}`}>
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { image, inputName, inputValue, showCropper, showPopup } = this.state;
    return (
      <>
        {!this.props.profileId && (
          <input form="new_profile" name={inputName} type="hidden" value={inputValue} />
        )}
        <button className="edit" onClick={() => this.setState({ showPopup: true })} type="button">Edit</button>
        <div className="avatarImage">
          <Button
            imgAlt="Avatar"
            imgSrc={image}
            onClick={this.handleClickImage}
          />
        </div>
        {showCropper && this.renderCropperModal()}
        {showPopup && this.renderSelectorModal()}
      </>
    );
  }

  renderCropperModal() {
    const { imageType, loading, newImage } = this.state;
    return (
      <PopupLayout
        loading={loading}
        onClose={this.handleClose}
        title="Edit Photo"
      >
        <CropperPopup
          aspect={1}
          image={newImage}
          imageType={imageType}
          onCancel={this.handleClose}
          onUploadFile={(blob) => this.uploadFile(blob)}
        />
      </PopupLayout>
    );
  }

  renderSelectorModal() {
    const { customAvatarAllowed, defaultAvatars, maxSize } = this.props;
    const { hasAvatar, loading } = this.state;
    return (
      <PopupLayout
        loading={loading}
        onClose={this.handleClose}
        title="Edit Avatar"
      >
        <DefaultAvatarList
          customAvatarAllowed={customAvatarAllowed}
          defaultAvatars={defaultAvatars}
          hasAvatar={hasAvatar}
          onCancel={this.handleClose}
          onRemove={this.handleRemove}
          onSelect={(defaultAvatar: DefaultAvatarInterface) => this.selectDefault(defaultAvatar)}
        />
        {customAvatarAllowed && (
          <CustomAvatarUploader
            hasAvatar={hasAvatar}
            inFlight={loading}
            maxSize={maxSize}
            onCancel={this.handleClose}
            onDrop={this.handleDrop}
            onRemove={this.handleRemove}
          />
        )}
      </PopupLayout>
    );
  }

  renderServerSide() {
    const { avatarSrc, profileId } = this.props;
    if (profileId) {
      return (
        <>
          <a className="button" href={`${profileId}/avatar/edit`} type="button">Edit</a>
          <a href={`${profileId}/avatar/edit`} type="button">
            <img alt="Avatar" src={avatarSrc} width="190px" />
          </a>
        </>
      );
    }
    return (
      <>
        <img alt="Avatar" src={avatarSrc} width="190px" />
        <br />
        <small>Enable JavaScript to edit</small>
      </>
    );
  }

  selectDefault(defaultAvatar: DefaultAvatarInterface) {
    this.setState({ loading: true });
    const { avatarUploaderUrl, profileId, token } = this.props;
    if (!profileId) {
      this.setState({
        hasAvatar: true,
        image: defaultAvatar.url,
        inputName: '[default_avatar]',
        inputValue: defaultAvatar.path,
        loading: false,
        showPopup: false
      });
      return;
    }
    const formData = new FormData();
    formData.append('defaultAvatar', defaultAvatar.path);
    formData.append('profileId', profileId);

    axios
      .patch(
        avatarUploaderUrl,
        formData,
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        const img = new Image();
        img.onload = () => this.setState({
          hasAvatar: true,
          image: img.src,
          loading: false,
          showPopup: false
        });
        img.src = response.data.newAvatarSource;
      })
      .catch((error) => {
        this.setState({
          loading: false,
          showCropper: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  uploadFile(blob: Blob) {
    const cancelToken = new axios.CancelToken((canceler) => {
      this.setState({
        cancelUpload: canceler,
        loading: true
      });
    });
    const { avatarUploaderUrl, profileId, token } = this.props;
    const extension = blob.type.split('/')[1];
    const timestamp = new Date().getTime();
    const file = new File([blob], `avatar-${timestamp}.${extension}`);
    const formData = new FormData();
    formData.append('Avatar', file);
    if (profileId) {
      formData.append('profileId', profileId);
    }

    axios({
      cancelToken,
      data: formData,
      headers: headers(token),
      method: profileId ? 'PATCH' : 'POST',
      url: avatarUploaderUrl
    })
      .then((response) => {
        this.setState({
          hasAvatar: true,
          image: URL.createObjectURL(blob),
          inputName: '[avatar_name]',
          inputValue: file.name,
          loading: false,
          showCropper: false
        });
        const img = new Image();
        img.onload = () => this.setState({ image: img.src });
        if (response.data.newAvatarSource) {
          img.src = response.data.newAvatarSource;
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          showCropper: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }
}

export default AvatarEditor;
