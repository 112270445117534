import * as React from 'react';

interface Props {
  label: string;
  onCancel: () => void;
  onSubmit: (reason: string) => void;
  options?: string[];
  prompt?: string;
}

interface State {
  inFlight: boolean;
  reason: string;
  selectedOption?: string;
}

class ReasonPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      inFlight: false,
      reason: '',
      selectedOption: ''
    };
  }

  render() {
    const { label, options, prompt } = this.props;
    const { inFlight, reason, selectedOption } = this.state;
    return (
      <div className="ReasonPopup">
        {prompt && <div className="prompt">{prompt}</div>}
        <div className="body">
          <label>{label}</label>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.submit();
            }}
          >
            {options && (
              <select
                defaultValue={options[0]}
                onChange={(event) => this.selectOption(event.target.value)}
              >
                {options.map((option, index) => <option key={index} value={index === 0 ? '' : option}>{option}</option>)}
              </select>
            )}
            {(!options || selectedOption === 'Other') && (
              <input
                autoFocus
                onChange={(event) => this.setState({ reason: event.target.value })}
                placeholder="Please include any relevant information...(Optional)"
                type="textarea"
                value={reason}
              />
            )}
          </form>
        </div>
        <div className="footer">
          <a
            onClick={(event) => {
              event.preventDefault();
              this.props.onCancel();
            }}
          >
            Cancel
          </a>
          <button
            disabled={inFlight || (options && !selectedOption)}
            onClick={() => this.submit()}
            type="submit"
          >
            {inFlight ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    );
  }

  selectOption(reason: string) {
    this.setState({
      selectedOption: reason,
      reason: reason === 'Other' ? '' : reason
    });
  }

  submit() {
    const { reason, selectedOption } = this.state;
    const otherReason = reason.length === 0 ? 'Other' : `Other - ${reason}`;
    const reasonValue = selectedOption === 'Other' ? otherReason : reason;
    this.setState({ inFlight: true }, () => this.props.onSubmit(reasonValue));
  }
}

export default ReasonPopup;
