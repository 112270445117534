import * as React from 'react';
import { TrixEditor } from 'react-trix';

interface Props {
  content: string;
  onUpdateText: ((blockData: string) => void);
}

const buttonTemplate = `
  <button
    type="button"
    class="trix-button trix-button--icon trix-button--icon-heading-1"
    data-trix-action="x-heading"
    title="Heading"
    tabindex="-1"
  >
    Heading
  </button>
`;

const dialogHeadingTemplate = `
  <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
    <div class="trix-dialog__link-fields">
      <div class="trix-button-group">
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1">H1</button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading2">H2</button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3">H3</button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4">H4</button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5">H5</button>
        <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6">H6</button>
      </div>
    </div>
  </div>
`;

function LessonRichEditor(props: Props) {
  const handleChange = (html: string, _text: string) => {
    props.onUpdateText(html);
  };

  const handleEditorReady = () => {
    // Remove broken attachFiles button
    $('[data-trix-button-group="file-tools"]').remove();
    // Remove default heading button
    $('[data-trix-attribute="heading1"]').remove();
    // Add custom main heading button
    $('[data-trix-button-group="block-tools"]').prepend(buttonTemplate);
    // Add headings buttons
    $('[data-trix-dialogs]').append(dialogHeadingTemplate);
  };

  return (
    <div className="LessonRichEditor">
      <TrixEditor
        className="trixEditor"
        mergeTags={[]}
        onChange={handleChange}
        onEditorReady={handleEditorReady}
        value={props.content}
      />
    </div>
  );
}

export default LessonRichEditor;
