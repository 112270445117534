import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import Popup from 'reactjs-popup';
import axios from 'axios';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Block,
  BlockAttachment,
  BlockLink,
  BlockQuiz,
  BlockRich,
  BlockType,
  BlockVideo,
  ErrorMessages,
  Link
} from './interfaces';
import BlockList from './BlockList';
import HandleFrame from './HandleFrame';
import LessonAttachmentEditor from './LessonAttachmentEditor';
import LessonLinkEditor from './LessonLinkEditor';
import LessonPreview from './LessonPreview';
import LessonRichEditor from './LessonRichEditor';
import LessonVideoEditor from './LessonVideoEditor';
import LinkPopup from './LinkPopup';
import PopupLayout from './PopupLayout';
import QuestionnaireEditor from './QuestionnaireEditor';
import QuizListPopup from './QuizListPopup';
import headers from '../utils/headersGenerator';
import reorder from '../utils/reorder';

interface Props {
  initialBlocks: Block[];
  lessonAttachmentUrl: string;
  lessonPreviewUrl: string;
  onDirty: (() => void);
  onSaveChanges: ((blocks: Block[]) => void);
  quizzes: BlockQuiz[];
  savingChanges: boolean;
  title: string;
  token: string;
}

interface State {
  blockIdsWithChildErrors: string[];
  currentBlocks: Block[];
  editingBlockId: string;
  editingLink: {
    blockId: string;
    linkId: string;
  }
  editingTitleId: string;
  errorMessages: string;
  extendedBlocksId: string[];
  isDragDisabled: boolean;
  lessonErrors: ErrorMessages[];
  linkBlockId: string;
  previewContent: string;
  showBlocksPopup: boolean;
  showLinkPopup: boolean;
  showQuizPopup: boolean;
}

class LessonEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    const firstBlockId = this.props.initialBlocks.length > 0 ? this.props.initialBlocks[0].id : '';
    this.handleAddLink = this.handleAddLink.bind(this);
    this.handleAddLinkToBlock = this.handleAddLinkToBlock.bind(this);
    this.handleAddQuizBlocks = this.handleAddQuizBlocks.bind(this);
    this.handleCancelLinkPopup = this.handleCancelLinkPopup.bind(this);
    this.handleClickPreview = this.handleClickPreview.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleEditLink = this.handleEditLink.bind(this);
    this.handleEnterEditor = this.handleEnterEditor.bind(this);
    this.handleLeaveEditor = this.handleLeaveEditor.bind(this);
    this.handleUpdateLink = this.handleUpdateLink.bind(this);

    this.state = {
      blockIdsWithChildErrors: [],
      currentBlocks: this.props.initialBlocks,
      // if initialBlocks is empty or the first block is type video, don't initialize in editor mode
      editingBlockId: firstBlockId === '' || this.props.initialBlocks[0].type === BlockType.Video ? '' : firstBlockId,
      editingLink: {
        blockId: '',
        linkId: ''
      },
      editingTitleId: '',
      errorMessages: '',
      extendedBlocksId: [firstBlockId],
      isDragDisabled: false,
      lessonErrors: [],
      linkBlockId: '',
      previewContent: '',
      showBlocksPopup: false,
      showLinkPopup: false,
      showQuizPopup: false
    };
  }

  addNewAttachmentBlock() {
    const newBlock: BlockAttachment = {
      content: [],
      description: '',
      id: uuidv4(),
      title: 'Add title',
      type: BlockType.Attachment
    };

    this.prependBlock(newBlock);
  }

  addNewRichBlock() {
    const newBlock: BlockRich = {
      content: '<div><!--block-->Add Content<em><br></em><br></div>',
      id: uuidv4(),
      title: 'Add title',
      type: BlockType.Rich
    };

    this.prependBlock(newBlock);
  }

  addNewVideoBlock() {
    const newBlock: BlockVideo = {
      content: { videoSrc: '' },
      description: '',
      id: uuidv4(),
      title: 'Add title',
      type: BlockType.Video
    };

    this.prependBlock(newBlock);
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (!isEqual(this.state.currentBlocks, prevState.currentBlocks)) {
      this.props.onDirty();
      this.setState({
        lessonErrors: this.getLessonErrors()
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  getCurrentLink() {
    const editingLinkBlock = this.state.currentBlocks.find((currentBlock) => currentBlock.id === this.state.editingLink.blockId);

    if (editingLinkBlock && editingLinkBlock.type === BlockType.Link) {
      return editingLinkBlock.content.find((link) => link.id === this.state.editingLink.linkId);
    }

    return {
      id: '',
      title: '',
      url: ''
    };
  }

  getErrorMessages(errors: ErrorMessages[], index: number) {
    return errors.find((_element, elementIndex) => index === elementIndex)?.messages || [];
  }

  getLessonErrors(): ErrorMessages[] {
    return this.state.currentBlocks.map((block, index) => {
      const messages: string[] = [];

      if (block.title.trim().length < 3) {
        messages.push('Title must be at least 3 characters.');
      }

      if (block.title.trim().length > 100) {
        messages.push('Title must be less than 100 characters.');
      }

      if (this.state.blockIdsWithChildErrors.includes(block.id)) {
        messages.push('This component has some errors. Please fix these before saving.');
      }

      const emptyLinkBlock = this.state.currentBlocks.find(
        (currentBlock) => currentBlock.type === BlockType.Link && currentBlock.content.length === 0 && currentBlock.id === block.id
      );
      if (emptyLinkBlock) {
        messages.push('You must add at least one link.');
      }

      if (block.type === BlockType.Attachment && block.content.length === 0) {
        messages.push('You must add at least one file.');
      }

      if (block.type === BlockType.Rich && block.content === '') {
        messages.push('You must have some content in rich text editor.');
      }

      if (block.type === BlockType.Video && block.content.videoSrc === '') {
        messages.push('You must add an embeded video code.');
      }

      if (block.type === BlockType.Quiz && block.content.length === 0) {
        messages.push('You must add at least one question.');
      }

      if (block.type === BlockType.Attachment) {
        const fileNames = block.content.map((file) => file.filename);
        const checkDup = new Set(fileNames).size < fileNames.length;
        if (checkDup) {
          messages.push('File name in use.');
        }
      }

      return {
        index,
        messages
      };
    });
  }

  getLinkErrorMessage(link: Link) {
    const regrex = /^(http|http(s)?:\/\/)?([\w-]+\.)+[\w-]+[.com|.in|.net|.org]+(\[\?%&=]*)?/g;

    if (!RegExp(regrex).test(link.url)) {
      return 'URL is not valid.';
    }

    return '';
  }

  handleAddLink(blockId: string) {
    this.setState({
      linkBlockId: blockId,
      showLinkPopup: true
    });
  }

  handleAddLinkToBlock(link: Link) {
    const { currentBlocks, extendedBlocksId, linkBlockId } = this.state;
    const errorMessage = this.getLinkErrorMessage(link);
    if (errorMessage !== '') {
      this.setState({
        errorMessages: errorMessage
      });
      return;
    }

    const newLink = {
      id: uuidv4(),
      title: link.title,
      url: link.url
    };

    const newBlock: BlockLink = {
      content: [newLink],
      id: uuidv4(),
      title: 'Add title',
      type: BlockType.Link
    };

    // Update existing link block
    const updatedBlocks = currentBlocks.map((currentBlock) => {
      if (currentBlock.id === linkBlockId && currentBlock.type === BlockType.Link) {
        return {
          ...currentBlock,
          content: [
            ...currentBlock.content,
            newLink
          ]
        };
      }
      return currentBlock;
    });

    const blocks = linkBlockId !== '' ? updatedBlocks : [newBlock, ...currentBlocks];
    this.setState({
      currentBlocks: blocks,
      editingBlockId: newBlock.id,
      errorMessages: '',
      extendedBlocksId: [...extendedBlocksId, newBlock.id],
      linkBlockId: '',
      showLinkPopup: false
    });
  }

  handleAddQuizBlocks(quizzes: BlockQuiz[]) {
    if (quizzes.length === 0) {
      return;
    }

    const newBlocksId = quizzes.map((quiz) => quiz.id);
    this.setState({
      currentBlocks: [...quizzes, ...this.state.currentBlocks],
      extendedBlocksId: [...newBlocksId, ...this.state.extendedBlocksId],
      showQuizPopup: false
    });
  }

  handleCancelLinkPopup() {
    this.setState({
      editingLink: { blockId: '', linkId: '' },
      errorMessages: '',
      showLinkPopup: false
    });
  }

  handleClickPreview() {
    const blocks = this.state.currentBlocks.map((block) => ({ ...block, content: JSON.stringify(block.content) }));
    axios
      .post(
        this.props.lessonPreviewUrl,
        { blocks },
        {
          cancelToken: this.signal.token,
          headers: headers(this.props.token)
        }
      )
      .then((response) => {
        this.setState({ previewContent: response.data.content });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    this.setState({
      currentBlocks: reorder(
        this.state.currentBlocks,
        result.source.index,
        result.destination.index
      )
    });
  }

  handleEditLink(blockId: string, linkId: string) {
    this.setState({
      editingLink: { blockId, linkId },
      showLinkPopup: true
    });
  }

  handleEnterEditor() {
    this.setState({
      isDragDisabled: true
    });
  }

  handleLeaveEditor() {
    this.setState({
      isDragDisabled: false
    });
  }

  handleUpdateLink(link: Link) {
    const { currentBlocks, editingLink, extendedBlocksId } = this.state;
    const linkBlock = currentBlocks.find((currentBlock) => currentBlock.id === editingLink.blockId);
    if (linkBlock?.type !== BlockType.Link) {
      return;
    }

    const errorMessage = this.getLinkErrorMessage(link);
    if (errorMessage !== '') {
      this.setState({
        errorMessages: errorMessage
      });
      return;
    }

    const updatedLinks = linkBlock.content.map((contentLink: Link) => (contentLink.id === link.id ? link : contentLink));
    const updatedCurrentBlocks = currentBlocks.map((currentBlock) => (
      currentBlock.id === editingLink.blockId && currentBlock.type === BlockType.Link
        ? { ...currentBlock, content: updatedLinks }
        : currentBlock));

    this.setState({
      currentBlocks: updatedCurrentBlocks,
      editingBlockId: editingLink.blockId,
      editingLink: { blockId: '', linkId: '' },
      errorMessages: '',
      extendedBlocksId: [...extendedBlocksId, editingLink.blockId],
      showLinkPopup: false
    });
  }

  prependBlock(block: Block) {
    this.setState({
      currentBlocks: [block, ...this.state.currentBlocks],
      editingBlockId: block.id,
      extendedBlocksId: [...this.state.extendedBlocksId, block.id]
    });
  }

  removeBlock(blockId: string) {
    if (window.confirm('Do you really want to delete this block?')) {
      this.setState((previousState) => ({
        currentBlocks: previousState.currentBlocks.filter((block) => block.id !== blockId)
      }));
    }
  }

  render() {
    const { previewContent, showLinkPopup, showQuizPopup } = this.state;
    if (previewContent !== '') {
      return <LessonPreview content={previewContent} onClose={() => this.setState({ previewContent: '' })} />;
    }
    return (
      <div className="LessonEditor">
        {this.renderHeader()}
        {showLinkPopup && this.renderLinkPopup()}
        {showQuizPopup && this.renderQuizListPopup()}
        {this.renderEditor()}
      </div>
    );
  }

  renderAddBlockButton() {
    return (
      <Popup
        arrowStyle={{ color: '#e0e0e0' }}
        onOpen={() => this.setState({ showBlocksPopup: true })}
        open={this.state.showBlocksPopup}
        position="right top"
        trigger={<button type="button">Add block</button>}
      >
        <BlockList
          onAddBlock={(type) => {
            this.setState({ showBlocksPopup: false });
            switch (type) {
              case BlockType.Attachment:
                return this.addNewAttachmentBlock();
              case BlockType.Link:
                return this.setState({ showLinkPopup: true });
              case BlockType.Quiz:
                return this.setState({ showQuizPopup: true });
              case BlockType.Rich:
                return this.addNewRichBlock();
              case BlockType.Video:
                return this.addNewVideoBlock();
              default:
                return console.error(`Unrecognised type: ${type}`);
            }
          }}
        />
      </Popup>
    );
  }

  renderAttachmentBlock(block: BlockAttachment, index: number) {
    return (
      <LessonAttachmentEditor
        attachedFiles={block.content}
        description={block.description}
        lessonAttachmentUrl={this.props.lessonAttachmentUrl}
        onFileRejected={(messages) => this.updateLessonError(index, messages)}
        onUpdateContent={(content) => this.updateBlock({ ...block, content })}
        onUpdateDescription={(description) => this.updateDescription(block.id, description)}
        token={this.props.token}
      />
    );
  }

  renderBlocks(lessonErrors: ErrorMessages[]) {
    const { currentBlocks, editingTitleId, extendedBlocksId, isDragDisabled } = this.state;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId="LessonEditor">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {currentBlocks.map((block, index) => {
                const { id, title, type } = block;
                return (
                  <Draggable
                    disableInteractiveElementBlocking={isDragDisabled || id !== editingTitleId}
                    draggableId={id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={id}
                  >
                    {(providedDraggable) => (
                      <div
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                      >
                        <HandleFrame
                          isBlockExtended={extendedBlocksId.includes(id)}
                          lessonErrorMessages={this.getErrorMessages(lessonErrors, index)}
                          onBlur={() => this.setState({ editingTitleId: '' })}
                          onClickArrowHead={() => this.toggleExtendedBlock(id)}
                          onEditingTitle={() => this.setState({ editingTitleId: id })}
                          onRemoveBlock={() => this.removeBlock(id)}
                          onUpdateTitle={(newTitle) => this.updateTitle(id, newTitle)}
                          title={title}
                        >
                          <div
                            onMouseEnter={this.handleEnterEditor}
                            onMouseLeave={this.handleLeaveEditor}
                          >
                            {type === BlockType.Attachment && this.renderAttachmentBlock(block, index)}
                            {type === BlockType.Link && this.renderLinkBlock(block)}
                            {type === BlockType.Quiz && this.renderQuizBlock(block)}
                            {type === BlockType.Rich && this.renderRichBlock(block)}
                            {type === BlockType.Video && this.renderVideoBlock(block)}
                          </div>
                        </HandleFrame>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  renderEditor() {
    const { initialBlocks, savingChanges } = this.props;
    const { blockIdsWithChildErrors, currentBlocks } = this.state;
    const allLessonErrors = this.state.lessonErrors;
    const lessonHasErrors = allLessonErrors.some((error) => error.messages.length > 0);
    const saveDisabled = isEqual(initialBlocks, currentBlocks) || blockIdsWithChildErrors.length > 0 || lessonHasErrors || savingChanges;
    return (
      <>
        {
          this.state.currentBlocks.length < 1
            ? <p>There is no block for this lesson.</p>
            : this.renderBlocks(allLessonErrors)
        }
        <div className="footer">
          <button onClick={this.handleClickPreview} type="button">
            Preview
          </button>
          <button
            disabled={saveDisabled}
            onClick={() => this.props.onSaveChanges(currentBlocks)}
            type="button"
          >
            {savingChanges ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </>
    );
  }

  renderHeader() {
    return (
      <div className="header">
        <div className="leftSide">
          {this.renderAddBlockButton()}
        </div>
        <div className="rightSide">
          <button
            disabled={this.state.extendedBlocksId.length === 0}
            onClick={() => this.setState({ extendedBlocksId: [] })}
            type="button"
          >
            Minimise All
          </button>
          <button
            disabled={this.state.currentBlocks.length === this.state.extendedBlocksId.length}
            onClick={() => this.setState({ extendedBlocksId: this.state.currentBlocks.map((block) => block.id) })}
            type="button"
          >
            Expand All
          </button>
        </div>

      </div>
    );
  }

  renderLinkBlock(block: BlockLink) {
    return (
      <LessonLinkEditor
        currentBlockId={block.id}
        currentLinks={block.content}
        description={block.description}
        onClickAddLink={this.handleAddLink}
        onClickEdit={this.handleEditLink}
        onReorderLinks={(sourceIndex, destinationIndex) => this.reorderLinks(block, sourceIndex, destinationIndex)}
        onUpdateDescription={(description) => this.updateDescription(block.id, description)}
        updateCurrentBlockLinks={(content) => this.updateBlock({ ...block, content })}
      />
    );
  }

  renderLinkPopup() {
    const { editingLink, errorMessages } = this.state;
    return (
      <PopupLayout
        errorMessages={errorMessages}
        onClose={this.handleCancelLinkPopup}
        title={editingLink.blockId ? 'Update Link' : 'Add Link'}
      >
        <LinkPopup
          currentLink={this.getCurrentLink()}
          onCancel={this.handleCancelLinkPopup}
          onSubmit={editingLink.blockId ? this.handleUpdateLink : this.handleAddLinkToBlock}
        />
      </PopupLayout>
    );
  }

  renderQuizBlock(block: BlockQuiz) {
    return (
      <QuestionnaireEditor
        onDirty={this.props.onDirty}
        onUpdateQuestionnaire={(quiz) => this.updateBlock(quiz)}
        questionnaire={block}
        questionnaireType="quiz"
      />
    );
  }

  renderQuizListPopup() {
    const currentBlockIds = this.state.currentBlocks.map((block) => block.id);
    return (
      <PopupLayout
        onClose={() => this.setState({ showQuizPopup: false })}
        title="Select Quiz"
      >
        <QuizListPopup
          currentBlockIds={currentBlockIds}
          onAddQuizzes={this.handleAddQuizBlocks}
          onCancel={() => this.setState({ showQuizPopup: false })}
          quizzes={this.props.quizzes.filter((quiz) => !currentBlockIds.includes(quiz.id))}
        />
      </PopupLayout>
    );
  }

  renderRichBlock(block: BlockRich) {
    return (
      <div onMouseMove={this.handleEnterEditor}>
        <LessonRichEditor
          content={block.content}
          onUpdateText={(content) => this.updateBlock({ ...block, content })}
        />
      </div>
    );
  }

  renderVideoBlock(block: BlockVideo) {
    return (
      <LessonVideoEditor
        description={block.description}
        onSubmitVideo={(content) => {
          this.updateBlock({
            ...block,
            content
          });
          this.updateEditingId('');
        }}
        onUpdateDescription={(description) => this.updateDescription(block.id, description)}
        title={block.title}
        videoSrc={block.content.videoSrc}
      />
    );
  }

  reorderLinks(block: Block, sourceIndex: number, destinationIndex: number) {
    const currentBlocks = this.state.currentBlocks.map((currentBlock) => {
      if (block.id !== currentBlock.id || currentBlock.type !== BlockType.Link) {
        return currentBlock;
      }

      return {
        ...currentBlock,
        content: reorder(currentBlock.content, sourceIndex, destinationIndex)
      };
    });

    this.setState({
      currentBlocks
    });
  }

  toggleExtendedBlock(newId: string) {
    const updatedIds = (prevExtendedBlockIds: string[]) => {
      if (prevExtendedBlockIds.includes(newId)) {
        return prevExtendedBlockIds.filter((id: string) => id !== newId);
      }
      return [...prevExtendedBlockIds, newId];
    };
    this.setState((previousState) => ({
      extendedBlocksId: updatedIds(previousState.extendedBlocksId)
    }));
    if (this.state.editingBlockId === newId) {
      this.setState({
        editingBlockId: ''
      });
    }
  }

  updateBlock(block: Block) {
    const { currentBlocks } = this.state;
    this.setState({
      blockIdsWithChildErrors: block.type === BlockType.Quiz
        ? this.state.blockIdsWithChildErrors.filter((errorBlockId) => errorBlockId !== block.id)
        : this.state.blockIdsWithChildErrors,
      currentBlocks: currentBlocks.map((currentBlock) => currentBlock.id === block.id ? block : currentBlock),
      showLinkPopup: false
    });
  }

  updateDescription(blockId: string, description: string) {
    this.setState({
      currentBlocks: this.state.currentBlocks.map((block) => (
        block.id === blockId
          ? { ...block, description }
          : block
      ))
    });
  }

  updateEditingId(blockId: string) {
    this.setState((previousState) => ({
      editingBlockId: blockId === previousState.editingBlockId ? '' : blockId
    }));
  }

  updateLessonError(index: number, messages: string[]) {
    const updateLessonErrors = this.state.lessonErrors.map((lessonError) => {
      if (lessonError.index === index) {
        return {
          index,
          messages: [...lessonError.messages, ...messages]
        };
      }
      return lessonError;
    });

    this.setState({
      lessonErrors: updateLessonErrors
    });
  }

  updateTitle(id: string, title: string) {
    this.setState((previousState) => ({
      currentBlocks: previousState.currentBlocks.map((block) => (
        block.id === id
          ? { ...block, title }
          : block
      ))
    }));
  }
}

export default LessonEditor;
