import * as React from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import Button from './Button';
import LessonBlockDescription from './LessonBlockDescription';
import { UploadedFile } from './interfaces';
import formatBytes from '../utils/formatBytes';
import headers from '../utils/headersGenerator';
import images from '../utils/images';

interface FileError {
  code: string,
  message: string
}

interface Props {
  attachedFiles: UploadedFile[];
  description?: string;
  lessonAttachmentUrl: string;
  onFileRejected: ((messages: string[]) => void);
  onUpdateContent: ((files: UploadedFile[]) => void);
  onUpdateDescription: ((description: string) => void);
  token: string;
}

interface State {
  currentFiles: UploadedFile[];
}

class LessonAttachmentEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = {
      currentFiles: this.props.attachedFiles
    };
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleDrop(acceptedFiles: File[]) {
    acceptedFiles.forEach((file) => this.uploadFile(file));
  }

  removeFile(id: string) {
    if (!confirm('Are you sure you want to delete this file?')) {
      return;
    }
    const file = this.state.currentFiles.find((attachment) => attachment.id === id);
    if (file && file.cancelTokenSource) {
      file.cancelTokenSource.cancel('Upload cancelled by user.');
    }

    this.setState({
      currentFiles: this.state.currentFiles.filter((attachment) => attachment.id !== id)
    }, () => {
      this.props.onUpdateContent(this.state.currentFiles);
    });
  }

  render() {
    // 5242880 b = 5 MB
    const maxSize = 5242880;
    return (
      <div className="LessonAttachmentEditor">
        <LessonBlockDescription onUpdate={this.props.onUpdateDescription} value={this.props.description} />
        <Dropzone
          maxSize={maxSize}
          minSize={0}
          onDrop={this.handleDrop}
          onDropRejected={(event) => this.updateErrorMessages(event[0].errors, maxSize)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div className={`uploadContainer ${isDragActive ? 'valid' : ''}`} {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive ? 'Click here or drop files to upload' : 'Drop it!'}
            </div>
          )}
        </Dropzone>
        <div className="list">
          {this.state.currentFiles.map((file) => (
            <div className="attachment" key={file.id}>
              <div className="left">
                <div className="name">{file.filename}</div>
                <div className="size" title={formatBytes(file.size, 3)}>({formatBytes(file.size, 0)})</div>
              </div>
              <div className="right">
                {file.loaded && <div className="progress"><div className="bar" style={{ width: `${file.loaded}%` }} /></div>}
                <Button
                  imgAlt="Remove"
                  imgSrc={images.crossBox}
                  onClick={async () => this.removeFile(file.id)}
                  size="medium"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  updateErrorMessages(errors: FileError[], maxSize: number) {
    const messages = errors.map((error) => {
      if (error.code === 'file-too-large') {
        return `File must not exceed ${formatBytes(maxSize)}.`;
      }
      return error.message;
    });

    this.props.onFileRejected(messages);
  }

  uploadFile(file: File) {
    const cancelTokenSource = axios.CancelToken.source();
    const formData = new FormData();
    const provisoryId = uuidv4();
    formData.append('lesson_attachment[adjunct]', file);
    const loaded = (progress: ProgressEvent) => (Math.round((progress.loaded / progress.total) * 100));
    const { lessonAttachmentUrl, onUpdateContent, token } = this.props;
    axios
      .post(
        lessonAttachmentUrl,
        formData,
        {
          cancelToken: cancelTokenSource.token,
          headers: headers(token),
          onUploadProgress: (progress) => {
            if (this.state.currentFiles.map((attachment) => attachment.id).includes(provisoryId)) {
              this.setState({
                currentFiles: this.state.currentFiles.map((attachment) => (
                  attachment.id === provisoryId
                    ? { ...attachment, loaded: loaded(progress) }
                    : attachment
                ))
              });
            } else {
              this.setState({
                currentFiles: [
                  { cancelTokenSource, filename: file.name, id: provisoryId, loaded: loaded(progress), size: file.size },
                  ...this.state.currentFiles
                ]
              });
            }
          }
        }
      )
      .then((response) => {
        const { filename, id, size } = response.data;
        this.setState(
          {
            currentFiles: this.state.currentFiles.map((attachment) => (
              attachment.id === provisoryId
                ? { filename, id, size }
                : attachment
            ))
          },
          () => onUpdateContent(this.state.currentFiles)
        );
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }
}

export default LessonAttachmentEditor;
