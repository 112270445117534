import * as React from 'react';
import axios from 'axios';

import Interaction from './Interaction';
import { InteractionElement } from './interfaces';
import InteractionSubmitForm from './InteractionSubmitForm';
import headers from '../utils/headersGenerator';

interface Props {
  allowedToCreate: boolean;
  authenticityToken: string;
  cohortId: string;
  defaultAvatarUrl: string;
  flaggedId?: string;
  flagsUrl: string;
  groupId: string;
  indexUrlClient: string;
  indexUrlServer: string;
  interactableId: string;
  interactions: InteractionElement[];
  joinUrl: string;
  likesUrl: string;
  membershipId?: string;
  profileId?: string;
  prompt: string;
  promptMinLength: number;
  queryParams: { [key: string]: string };
  reportOptions: string[];
  token: string;
  totalInteractionsLength: number;
  type: string;
  unauthorizedTitle: string;
}

interface State {
  interactions: InteractionElement[];
  isClient: boolean;
  moreInteractions: boolean;
}

class ActivityList extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSeeMore = this.handleSeeMore.bind(this);

    const { interactions, totalInteractionsLength } = props;

    this.state = {
      interactions,
      isClient: false,
      moreInteractions: interactions.length !== totalInteractionsLength
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  deleteInteraction(id: string) {
    const { interactions } = this.state;
    const interaction = interactions.find((stateInteraction) => stateInteraction.id === id);
    if (!interaction) {
      return;
    }
    axios
      .delete(
        interaction.deleteUrlClient,
        {
          cancelToken: this.signal.token,
          headers: headers(this.props.token)
        }
      )
      .then(() => {
        this.setState({
          interactions: interactions.filter((stateInteraction) => stateInteraction.id !== id)
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  handleSeeMore(event: React.MouseEvent) {
    event.preventDefault();
    const { groupId, indexUrlClient, interactableId, token } = this.props;
    const stateInteractions = this.state.interactions;
    axios
      .get(
        indexUrlClient,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: {
            groupId,
            interactableId,
            previousPageInteractionCount: stateInteractions.length
          }
        }
      )
      .then((response) => {
        const { interactions, interactionsLength } = response.data;
        if (!interactions || (interactions && interactions.length === 0)) {
          return;
        }
        this.setState({
          interactions: [...stateInteractions, ...interactions],
          moreInteractions: interactions.length + stateInteractions.length !== interactionsLength
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  render() {
    const {
      allowedToCreate,
      authenticityToken,
      cohortId,
      defaultAvatarUrl,
      flaggedId,
      flagsUrl,
      groupId,
      indexUrlClient,
      indexUrlServer,
      interactableId,
      joinUrl,
      likesUrl,
      membershipId,
      profileId,
      prompt,
      promptMinLength,
      reportOptions,
      token,
      type,
      unauthorizedTitle
    } = this.props;
    const { interactions, isClient, moreInteractions } = this.state;
    return (
      <div className="ActivityList">
        {interactions.length === 0 && profileId
          ? (
            <div className="paddedBody">
              <h4>There is no recent activity. Be the first!</h4>
            </div>
          )
          : (
            interactions.map((interaction) => (
              <Interaction
                authenticityToken={authenticityToken}
                clientSideDeleteUrl={interaction.deleteUrlClient}
                defaultAvatarUrl={defaultAvatarUrl}
                flaggedId={flaggedId}
                flagsUrl={flagsUrl}
                friendlyName="activity"
                interaction={interaction}
                key={interaction.id}
                likesUrl={likesUrl}
                membershipId={membershipId}
                onClickDeleteButton={() => this.deleteInteraction(interaction.id)}
                redirectUrl={indexUrlServer}
                reportOptions={reportOptions}
                serverSideDeleteUrl={interaction.deleteUrlServer}
                token={token}
                type={type}
              />
            )))}
        {moreInteractions && this.renderSeeMore()}
        {allowedToCreate ? (
          <InteractionSubmitForm
            authenticityToken={authenticityToken}
            cohortId={cohortId}
            groupId={groupId}
            indexUrlClient={indexUrlClient}
            indexUrlServer={indexUrlServer}
            interactableId={interactableId}
            interactions={interactions}
            isClient={isClient}
            onSubmitInteraction={(interaction) => this.updateInteractions(interaction)}
            profileId={profileId}
            prompt={prompt}
            promptMinLength={promptMinLength}
            token={token}
          />
        ) : (
          <div className="notAuthorized">
            {this.renderUnauthorizedTitle(joinUrl, unauthorizedTitle)}
          </div>
        )}
      </div>
    );
  }

  renderSeeMore() {
    const { interactions, isClient } = this.state;
    const { flaggedId, queryParams } = this.props;
    const keys = Object.keys(queryParams);
    const params = () => {
      if (keys.length > 0) {
        const allParams = keys.map((key) => (`${key}=${queryParams[key]}`)).join('&');
        if (flaggedId && flaggedId.length > 0) {
          return allParams.concat(`#${flaggedId}`);
        }
        return allParams;
      }
      return '';
    };
    const href = isClient ? '' : `?previous_page_interaction_count=${interactions.length}${'&'.concat(params())}`;
    return (
      <div className="seeMore">
        <a
          href={href}
          onClick={this.handleSeeMore}
        >
          See more...
        </a>
      </div>
    );
  }

  renderUnauthorizedTitle(joinUrl: string, unauthorizedTitle: string | undefined) {
    if (unauthorizedTitle) {
      return <p>{unauthorizedTitle}</p>;
    }
    return <p><a href={joinUrl}>Join the group</a> to share your own updates</p>;
  }

  updateInteractions(interaction: InteractionElement) {
    this.setState({
      interactions: [interaction, ...this.state.interactions]
    });
  }
}

export default ActivityList;
