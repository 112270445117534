import * as React from 'react';

interface Props {
  messages: string | string[];
}

function ErrorMessage(props: Props) {
  const { messages } = props;
  if (typeof messages === 'string') {
    return (
      <div className="ErrorMessage">
        <p>{messages}</p>
      </div>
    );
  }
  return (
    <div className="ErrorMessage">
      {messages.map((content, index) => <p key={index}>{content}</p>)}
    </div>
  );
}

export default ErrorMessage;
