import * as React from 'react';
import axios from 'axios';

import PopupLayout from './PopupLayout';
import SurveyContent from './SurveyContent';
import { TriggeredSurvey } from './interfaces';

interface Props {
  defaultShow: boolean;
  token: string;
  triggeredSurvey: TriggeredSurvey;
}

interface State {
  isClient: boolean;
  showPopup: boolean;
}

class SurveyPopup extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.state = {
      isClient: false,
      showPopup: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true,
      showPopup: this.props.defaultShow
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleClosePopup() {
    if (confirm('Are you sure you want to close this survey?')) {
      this.setState({ showPopup: false });
    }
  }

  render() {
    const { isClient, showPopup } = this.state;
    return (
      <div className="SurveyPopup">
        {!this.props.defaultShow && (
          isClient
            ? (
              <button
                className="button"
                onClick={() => this.setState({ showPopup: true })}
                type="button"
              >
                Complete
              </button>
            )
            : 'Enable JavaScript to respond this survey.'
        )}
        {showPopup && this.renderPopup()}
      </div>
    );
  }

  renderPopup() {
    const { triggeredSurvey } = this.props;
    return (
      <PopupLayout
        onClose={this.handleClosePopup}
        title={triggeredSurvey.block.title}
        width="600px"
      >
        <SurveyContent
          triggeredSurvey={triggeredSurvey}
        />
      </PopupLayout>
    );
  }
}

export default SurveyPopup;
