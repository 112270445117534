import * as React from 'react';

import { LessonData } from './interfaces';
import PopupLayout from './PopupLayout';

interface Props {
  availableLessons: LessonData[];
  onCancel: (() => void);
  onConfirm: ((lessonData: LessonData[]) => void);
}

interface State {
  selectedLessonIds: string[];
}

class LessonsPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      selectedLessonIds: []
    };
  }

  handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    const options = e.target.selectedOptions;
    const selectedLessonIds: string[] = [];
    for (let i = 0; i < options.length; i += 1) {
      selectedLessonIds.push(options[i].value);
    }

    this.setState({
      selectedLessonIds
    });
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const selectedLessons = this.props.availableLessons.filter(
      (lesson) => lesson.id && this.state.selectedLessonIds.includes(lesson.id)
    );

    this.props.onConfirm(selectedLessons);
  }

  noLessonsSelected() {
    return this.state.selectedLessonIds.length === 0;
  }

  render() {
    const { availableLessons, onCancel } = this.props;
    return (
      <PopupLayout
        onClose={onCancel}
        title="Select Lessons"
      >
        <div className="LessonsPopup">
          <div className="body">
            {availableLessons.length > 0
              ? (
                <>
                  <p>Select the lessons to add:</p>
                  <form>
                    <select
                      multiple
                      onChange={this.handleChange}
                      size={availableLessons.length > 10 ? 10 : availableLessons.length}
                    >
                      {availableLessons.map((lesson) => (
                        <option key={lesson.id} value={lesson.id}>{lesson.name}</option>
                      ))}
                    </select>
                  </form>
                </>
              )
              : <p>All lessons have been added to this sequence.</p>
            }
          </div>
          <div className="footer">
            <a onClick={this.props.onCancel}>
              Cancel
            </a>
            {availableLessons.length > 0 && (
              <button
                disabled={this.noLessonsSelected()}
                onClick={this.handleSubmit}
                type="submit"
              >
                Add lessons
              </button>
            )}
          </div>
        </div>
      </PopupLayout>
    );
  }
}

export default LessonsPopup;
