import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import axios, { AxiosRequestHeaders } from 'axios';

import { ErrorMessages, Lesson, LessonData, LessonState, Sequence } from './interfaces';
import SequenceFrame from './SequenceFrame';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import getDraggableStyle from '../utils/getDraggableStyle';
import nonce from '../utils/nonce';
import reorder from '../utils/reorder';
import uniqueSlug from '../utils/uniqueSlug';

interface SequenceState {
  deletedLessonStates: LessonState[];
  extended: boolean;
  lessonStates: LessonState[];
  sequenceId: string;
  sequenceName: string;
}

interface Props {
  contentCourseUrl: string;
  courseId: string;
  coursesUrl: string;
  initialSequences: Sequence[];
  lessons: Lesson[];
  token: string;
}

interface State {
  deletedSequenceIds: string[];
  editingTitleId: number | '';
  isClient: boolean;
  isDirty: boolean;
  isDragDisabled: boolean;
  lessonHasErrors: boolean;
  // Nonce is used to ensure that DOM is updated correctly when sequences are removed
  nonce: string;
  sequenceStates: SequenceState[];
}

class SequenceEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    this.handleAddSequence = this.handleAddSequence.bind(this);
    this.handleCollapseSequences = this.handleCollapseSequences.bind(this);
    this.handleDragDisable = this.handleDragDisable.bind(this);
    this.handleDragEnable = this.handleDragEnable.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleExpandSequences = this.handleExpandSequences.bind(this);
    this.handleSaveChanges = this.handleSaveChanges.bind(this);

    // build internal representation of sequence and lesson state
    const sequenceStates = this.props.initialSequences.map((sequence, index) => ({
      deletedLessonStates: [],
      extended: index === 0,
      lessonStates: sequence.lessons.map((lesson) => ({
        extended: false,
        lessonData: {
          ...lesson
        }
      })),
      sequenceId: sequence.id,
      sequenceName: sequence.name
    }));

    this.state = {
      deletedSequenceIds: [],
      editingTitleId: '',
      isClient: false,
      isDragDisabled: false,
      isDirty: false,
      lessonHasErrors: false,
      nonce: '',
      sequenceStates
    };
  }

  addLessons(sequenceIndex: number, lessonsToAdd: Lesson[]) {
    const sequenceState = this.state.sequenceStates[sequenceIndex];
    const existingSlugs = sequenceState.lessonStates.map((lessonState) => lessonState.lessonData.lessonSettings.slug);
    const newLessons = lessonsToAdd.map((lessonToAdd) => ({
      extended: true,
      lessonData: {
        blocks: lessonToAdd.blocks,
        lessonSettings: {
          commentsEnabled: lessonToAdd.lessonSettings.commentsEnabled,
          daysToRelease: lessonToAdd.lessonSettings.daysToRelease,
          points: lessonToAdd.lessonSettings.points,
          slug: uniqueSlug(lessonToAdd.lessonSettings.slug || lessonToAdd.name, existingSlugs)
        },
        name: lessonToAdd.name,
        templateId: lessonToAdd.id
      }
    }));

    const sequenceStates = this.state.sequenceStates.map(
      (innerSequenceState, sequenceStateIndex) => {
        if (sequenceStateIndex !== sequenceIndex) {
          return innerSequenceState;
        }

        return {
          ...innerSequenceState,
          lessonStates: [...innerSequenceState.lessonStates, ...newLessons]
        };
      }
    );

    this.setState({
      isDirty: true,
      sequenceStates
    });
  }

  checkErrors(sequenceIndex: number) {
    return this.getLessonErrors(sequenceIndex).some((lesson) => lesson.messages.length > 0);
  }

  componentDidMount() {
    this.setState({
      isClient: true,
      nonce: nonce()
    });
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (this.state.sequenceStates !== prevState.sequenceStates) {
      const sequenceWithLessonErrors = this.state.sequenceStates.map((_sequenceState, index) => this.checkErrors(index));
      this.setState({
        lessonHasErrors: sequenceWithLessonErrors.includes(true)
      });
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  disableCollapseAllButton() {
    return !this.state.sequenceStates.some((sequenceState) => sequenceState.extended);
  }

  disableExpandAllButton() {
    return !this.state.sequenceStates.some((sequenceState) => !sequenceState.extended);
  }

  getErrorMessages(errors: ErrorMessages[], index: number) {
    return errors.find((_element, elementIndex) => index === elementIndex)?.messages || [];
  }

  getLessonErrors(sequenceIndex: number): ErrorMessages[] {
    const { lessonStates } = this.state.sequenceStates[sequenceIndex];
    return lessonStates.map((lessonState, index) => {
      const { lessonData } = lessonState;
      const messages: string[] = [];

      // check for duplicate slugs
      const lessonsWithExistingSlug = lessonStates.some((otherLesson, otherIndex) => (
        otherLesson.lessonData.lessonSettings.slug === lessonData.lessonSettings.slug && otherIndex !== index
      ));
      if (lessonsWithExistingSlug) {
        messages.push('This lesson does not have a unique slug. Please type a new slug.');
      }

      // check slug values
      const slugRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$/;
      if (lessonData.lessonSettings.slug.trim().length < 3) {
        messages.push('Slug must be at least 3 characters long.');
      } else if (!slugRegex.test(lessonData.lessonSettings.slug)) {
        messages.push('Slug must be alphanumeric characters with hyphens only. '
          + 'Slug can not have spaces, start or finish with a hyphen.');
      }

      if (lessonData.name.trim().length < 3) {
        messages.push('Name must be at least 3 characters long.');
      }

      const { daysToRelease, points } = lessonData.lessonSettings;
      if (daysToRelease === '' || daysToRelease < 0) {
        messages.push('Release after must be a valid positive number.');
      }

      if (points === '' || points < 0) {
        messages.push('Points must be a valid positive number.');
      }

      return {
        index,
        messages
      };
    });
  }

  getSequenceErrors(): ErrorMessages[] {
    const { sequenceStates } = this.state;
    const sequenceIdsWithSlugs = sequenceStates.flatMap((sequenceState) => sequenceState.lessonStates.flatMap(
      (lessonState) => ({ id: sequenceState.sequenceId, slug: lessonState.lessonData.lessonSettings.slug })
    ));

    return sequenceStates.map((sequenceState, index) => {
      const messages = [];
      const sequenceLessonSlugs = sequenceState.lessonStates.flatMap((lessonState) => lessonState.lessonData.lessonSettings.slug);
      const lessonSlugExists = sequenceIdsWithSlugs
        .filter((sequenceIdWithSlug) => sequenceIdWithSlug.id !== sequenceState.sequenceId)
        .some((sequenceSlug) => sequenceLessonSlugs.includes(sequenceSlug.slug));

      if (lessonSlugExists) {
        messages.push('Lesson slug is already being used in another sequence.');
      }

      if (sequenceState.sequenceName.trim().length < 3) {
        messages.push('Name must be at least 3 characters long.');
      }

      if (sequenceState.sequenceName.trim().length > 100) {
        messages.push('Name must be less than 100 characters.');
      }

      return {
        index,
        messages
      };
    });
  }

  handleAddSequence() {
    const newSequenceState: SequenceState = {
      deletedLessonStates: [],
      extended: true,
      lessonStates: [],
      sequenceId: '',
      sequenceName: 'New sequence'
    };

    this.setState({
      isDirty: true,
      sequenceStates: [...this.state.sequenceStates, newSequenceState]
    });
  }

  handleCollapseSequences() {
    this.setState({
      sequenceStates: this.state.sequenceStates.map((sequenceState) => ({
        ...sequenceState,
        extended: false
      }))
    });
  }

  handleDragDisable() {
    this.setState({
      isDragDisabled: true
    });
  }

  handleDragEnable() {
    this.setState({
      isDragDisabled: false
    });
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    this.setState({
      sequenceStates: reorder(
        this.state.sequenceStates,
        result.source.index,
        result.destination.index
      ),
      isDirty: true,
      nonce: nonce(this.state.nonce)
    });
  }

  handleExpandSequences() {
    this.setState({
      sequenceStates: this.state.sequenceStates.map((sequenceState) => ({
        ...sequenceState,
        extended: true
      }))
    });
  }

  handleSaveChanges() {
    this.setState({ isDirty: false });
    const { contentCourseUrl, courseId, coursesUrl } = this.props;

    // prepare destruction sentinel for deleted sequences
    const deletedSequences = this.state.deletedSequenceIds.map((id) => ({
      id,
      _destroy: true
    }));

    let lessonDeletion = false;

    const updatedSequences = this.state.sequenceStates.map(
      (sequenceState, index) => {
        const deletedLessonDetails = sequenceState.deletedLessonStates.map((lessonState) => (
          {
            id: lessonState.lessonData.id,
            slug: lessonState.lessonData.lessonSettings.slug
          }
        ));

        // prepare destruction sentinel for deleted lessons
        const deletedLessons = deletedLessonDetails.map((deletedLessonState) => {
          if (!sequenceState.lessonStates.some((lessonState) => lessonState.lessonData.lessonSettings.slug === deletedLessonState.slug)) {
            return {
              id: deletedLessonState.id,
              _destroy: true
            };
          }
          return undefined;
        }).filter((lesson) => lesson !== undefined);

        if (deletedLessons.length > 0) {
          lessonDeletion = true;
        }

        // prepare update attributes for new and existing lessons
        const updatedLessons = sequenceState.lessonStates.map((lessonState, lessonStateIndex) => {
          const { blocks, lessonSettings, name, templateId } = lessonState.lessonData;
          const getId = () => {
            const deletedLesson = deletedLessonDetails.find((lesson) => lesson.slug === lessonSettings.slug);
            return deletedLesson?.id || lessonState.lessonData.id;
          };
          return {
            blocks,
            commentsEnabled: lessonSettings.commentsEnabled,
            courseId,
            daysToRelease: lessonSettings.daysToRelease,
            id: getId(),
            name,
            points: lessonSettings.points,
            precedence: lessonStateIndex,
            slug: lessonSettings.slug,
            templateId
          };
        });

        return {
          courseId,
          id: sequenceState.sequenceId,
          lessonsAttributes: [...deletedLessons, ...updatedLessons],
          name: sequenceState.sequenceName,
          precedence: index
        };
      }
    );

    const warning = 'You are about to delete sequences/lessons. All related content (lesson comments, lesson responses) will be lost.';

    if ((deletedSequences.length > 0 || lessonDeletion) && !confirm(warning)) {
      this.setState({ isDirty: true });
    } else {
      // put it all together
      const course = { sequencesAttributes: [...deletedSequences, ...updatedSequences] };

      const headers: AxiosRequestHeaders = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') || ''
      };

      axios
        .put(
          coursesUrl,
          { course },
          {
            cancelToken: this.signal.token,
            headers
          }
        )
        .then(() => {
          window.location.href = `${contentCourseUrl}?notice=Sequence successfully updated.`;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.debug(error.message);
          } else {
            console.error(error);
          }
          window.location.href = `${contentCourseUrl}?alert=Sequence could not be updated.`;
        });
    }
  }

  removeLesson(sequenceIndex: number, lessonIndex: number) {
    const sequenceStates = this.state.sequenceStates.map(
      (sequenceState, currentSequenceIndex) => {
        if (sequenceIndex === currentSequenceIndex) {
          // Add lesson to list of deleted IDs if it has one
          const lesson = sequenceState.lessonStates[lessonIndex];
          const deletedLessonStates = lesson.lessonData.id
            ? [...sequenceState.deletedLessonStates, { extended: false, lessonData: lesson.lessonData }]
            : sequenceState.deletedLessonStates;

          // Remove from lesson list
          const lessonStates = sequenceState.lessonStates.filter(
            (_lessonState, currentLessonIndex) => currentLessonIndex !== lessonIndex
          );

          return {
            ...sequenceState,
            deletedLessonStates,
            lessonStates
          };
        }
        return sequenceState;
      }
    );

    this.setState({
      isDirty: true,
      lessonHasErrors: this.checkErrors(sequenceIndex),
      sequenceStates
    });
  }

  removeSequence(index: number, sequenceId: string | undefined) {
    const updatedSequenceStates = [...this.state.sequenceStates];

    if (window.confirm('Do you really want to delete this sequence?')) {
      if (index > -1) {
        updatedSequenceStates.splice(index, 1);
      }

      const deletedSequenceIds = sequenceId
        ? [...this.state.deletedSequenceIds, sequenceId]
        : this.state.deletedSequenceIds;

      this.setState({
        deletedSequenceIds,
        isDirty: true,
        lessonHasErrors: this.checkErrors(index),
        nonce: nonce(this.state.nonce),
        sequenceStates: updatedSequenceStates
      });
    }
  }

  render() {
    return this.state.isClient ? this.renderClientSide() : this.renderServerSide();
  }

  renderClientSide() {
    const allSequenceErrors = this.getSequenceErrors();
    const sequenceHasErrors = allSequenceErrors.some((error) => error.messages.length > 0);
    return (
      <div className="SequenceEditor">
        {this.renderHeader()}
        {this.renderSequences(allSequenceErrors)}
        {this.renderFooter(sequenceHasErrors)}
      </div>
    );
  }

  renderFooter(sequenceHasErrors: boolean) {
    return (
      <div className="footer">
        <button
          disabled={sequenceHasErrors || this.state.lessonHasErrors || !this.state.isDirty}
          onClick={this.handleSaveChanges}
          type="button"
        >
          Save Changes
        </button>
      </div>
    );
  }

  renderHeader() {
    return (
      <div className="header">
        <div className="leftSide">
          <button onClick={this.handleAddSequence} type="button">Add sequence</button>
        </div>
        <div className="rightSide">
          <button
            disabled={this.disableCollapseAllButton()}
            onClick={this.handleCollapseSequences}
            type="button"
          >
            Minimise All
          </button>
          <button
            disabled={this.disableExpandAllButton()}
            onClick={this.handleExpandSequences}
            type="button"
          >
            Expand All
          </button>
        </div>
      </div>
    );
  }

  renderSequence(index: number, sequenceState: SequenceState, sequenceErrors: ErrorMessages[]) {
    return (
      <SequenceFrame
        allLessons={this.props.lessons}
        isBlockExtended={this.state.sequenceStates[index].extended}
        lessonErrors={this.getLessonErrors(index)}
        onClickAddLesson={(lessons) => this.addLessons(index, lessons)}
        onClickArrowHead={() => this.toggleSequence(index)}
        onEditingTitle={() => this.setState({ editingTitleId: index })}
        onMouseEnterInFrame={this.handleDragDisable}
        onMouseExitOutFrame={this.handleDragEnable}
        onRemoveLesson={(lessonIndex) => this.removeLesson(index, lessonIndex)}
        onRemoveSequence={() => this.removeSequence(index, sequenceState.sequenceId)}
        onReorderLessons={(sourceIndex, destinationIndex) => this.reorderLessons(index, sourceIndex, destinationIndex)}
        onToggleLesson={(lessonIndex) => this.toggleLesson(index, lessonIndex)}
        onUpdateLesson={(lessonIndex, lessonData) => this.updateLesson(index, lessonIndex, lessonData)}
        onUpdateName={(name) => this.updateName(index, name)}
        sequenceErrorMessages={this.getErrorMessages(sequenceErrors, index)}
        sequenceIndex={index}
        sequenceLessonStates={sequenceState.lessonStates}
        sequenceName={sequenceState.sequenceName}
      />
    );
  }

  renderSequences(sequenceErrors: ErrorMessages[]) {
    const { editingTitleId, isDragDisabled, isDirty, sequenceStates } = this.state;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <UnsavedChangesPrompt isDirty={isDirty} />
        <Droppable droppableId="sequences" key={this.state.nonce}>
          {(providedDroppable) => (
            <div
              className="droppable"
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              {sequenceStates.map((sequenceState, sequenceStateIndex) => (
                <Draggable
                  disableInteractiveElementBlocking={sequenceStateIndex !== editingTitleId}
                  draggableId={`sequence-${sequenceStateIndex}`}
                  index={sequenceStateIndex}
                  isDragDisabled={isDragDisabled}
                  key={sequenceStateIndex}
                >
                  {(providedDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      style={getDraggableStyle(providedDraggable.draggableProps.style)}
                    >
                      {this.renderSequence(sequenceStateIndex, sequenceState, sequenceErrors)}
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  renderServerSide() {
    return (
      <div className="SequenceEditor">
        <p>Please enable JavaScript and refresh the page to edit the content.</p>
      </div>
    );
  }

  reorderLessons(sequenceIndex: number, sourceIndex: number, destinationIndex: number) {
    const sequenceStates = this.state.sequenceStates.map((sequenceState, sequenceStateIndex) => {
      if (sequenceIndex !== sequenceStateIndex) {
        return sequenceState;
      }

      const updatedSequenceState = { ...sequenceState };
      updatedSequenceState.lessonStates = reorder(
        updatedSequenceState.lessonStates,
        sourceIndex,
        destinationIndex
      );

      return updatedSequenceState;
    });

    this.setState({
      isDirty: true,
      nonce: nonce(),
      sequenceStates
    });
  }

  toggleLesson(sequenceIndex: number, lessonIndex: number) {
    const sequenceStates = this.state.sequenceStates.map((sequenceState, sequenceStateIndex) => {
      if (sequenceIndex !== sequenceStateIndex) {
        return sequenceState;
      }

      const updatedSequenceState = { ...sequenceState };
      updatedSequenceState.lessonStates = [...updatedSequenceState.lessonStates];
      updatedSequenceState.lessonStates[lessonIndex] = {
        ...updatedSequenceState.lessonStates[lessonIndex],
        extended: !updatedSequenceState.lessonStates[lessonIndex].extended
      };

      return updatedSequenceState;
    });

    this.setState({ sequenceStates });
  }

  toggleSequence(index: number) {
    const sequenceStates = [...this.state.sequenceStates];
    const oldValue = sequenceStates[index];
    const extended = !oldValue.extended;
    sequenceStates[index] = {
      ...oldValue,
      extended
    };

    this.setState({
      sequenceStates
    });
  }

  updateLesson(sequenceIndex: number, lessonIndex: number, lessonData: LessonData) {
    const sequenceStates = this.state.sequenceStates.map((sequenceState, sequenceStateIndex) => {
      if (sequenceIndex !== sequenceStateIndex) {
        return sequenceState;
      }

      const updatedSequenceState = { ...sequenceState };
      updatedSequenceState.lessonStates = [...updatedSequenceState.lessonStates];
      updatedSequenceState.lessonStates[lessonIndex] = {
        ...updatedSequenceState.lessonStates[lessonIndex],
        lessonData
      };

      return updatedSequenceState;
    });

    this.setState({
      isDirty: true,
      lessonHasErrors: this.checkErrors(sequenceIndex),
      sequenceStates
    });
  }

  updateName(sequenceIndex: number, name: string) {
    const sequenceStates = this.state.sequenceStates.map((sequenceState, sequenceStateIndex) => {
      if (sequenceIndex !== sequenceStateIndex) {
        return sequenceState;
      }

      return {
        ...sequenceState,
        sequenceId: sequenceState.sequenceId,
        sequenceName: name
      };
    });

    this.setState({
      isDirty: true,
      sequenceStates
    });
  }
}

export default SequenceEditor;
