import * as React from 'react';

import { ErrorMessages, LessonData } from './interfaces';
import Button from './Button';
import LessonsPopup from './LessonsPopup';
import SequenceLessons from './SequenceLessons';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import images from '../utils/images';

interface LessonState {
  extended: boolean;
  lessonData: LessonData;
}

interface Props {
  allLessons: LessonData[];
  isBlockExtended: boolean;
  lessonErrors: ErrorMessages[];
  onClickAddLesson: ((lesson: LessonData[]) => void);
  onClickArrowHead: (() => void);
  onEditingTitle: (() => void);
  onMouseEnterInFrame: (() => void);
  onMouseExitOutFrame: (() => void);
  onRemoveLesson: ((index: number) => void);
  onRemoveSequence: (() => void);
  onReorderLessons: ((sourceIndex: number, destinationIndex: number) => void);
  onToggleLesson: ((index: number) => void);
  onUpdateLesson: ((index: number, lessonData: LessonData) => void);
  onUpdateName: ((title: string) => void);
  sequenceErrorMessages: string[];
  sequenceIndex: number;
  sequenceLessonStates: LessonState[];
  sequenceName: string;
}

interface State {
  showLessonsPopup: boolean;
}

class SequenceFrame extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleConfirmAddLessons = this.handleConfirmAddLessons.bind(this);
    this.state = {
      showLessonsPopup: false
    };
  }

  getAvailableLessons() {
    const existingLessons = this.props.sequenceLessonStates.map((state) => state.lessonData);

    // find all lessons which have IDs that do not match templateIds of existing lessons
    return this.props.allLessons.filter((lesson) => !existingLessons.some(
      (existingLesson) => existingLesson.templateId === lesson.id
    ));
  }

  handleConfirmAddLessons(lessons: LessonData[]) {
    this.setState(
      {
        showLessonsPopup: false
      },
      () => {
        this.props.onClickAddLesson(lessons);
      }
    );
  }

  render() {
    const {
      isBlockExtended,
      lessonErrors,
      onClickArrowHead,
      onMouseEnterInFrame,
      onMouseExitOutFrame,
      onRemoveLesson,
      onReorderLessons,
      onToggleLesson,
      onUpdateLesson,
      onUpdateName,
      sequenceName,
      sequenceLessonStates,
      sequenceErrorMessages,
      sequenceIndex
    } = this.props;

    return (
      <div className="SequenceFrame">
        <div className="content">
          <div className="blockHeader">
            <Button
              imgAlt="Expand/Collapse"
              imgSrc={images.arrowHeadRight}
              onClick={async () => onClickArrowHead()}
              rotated={isBlockExtended}
              size="small"
            />
            <TitleEditor
              onEditing={() => this.props.onEditingTitle()}
              onUpdateTitle={onUpdateName}
              showErrorIcon={sequenceErrorMessages.length > 0}
              title={sequenceName}
            />
            <div className="action">
              <button onClick={() => this.setState({ showLessonsPopup: true })} type="button">
                Add Lessons
              </button>
              {this.state.showLessonsPopup && (
                <LessonsPopup
                  availableLessons={this.getAvailableLessons()}
                  onCancel={() => this.setState({ showLessonsPopup: false })}
                  onConfirm={this.handleConfirmAddLessons}
                />
              )}
              <Button
                imgAlt="Remove sequence"
                imgSrc={images.crossBox}
                onClick={async () => this.props.onRemoveSequence()}
                size="large"
              />
            </div>
          </div>
          {isBlockExtended && (
            <>
              <div
                onMouseEnter={onMouseEnterInFrame}
                onMouseLeave={onMouseExitOutFrame}
              >
                <SequenceLessons
                  lessonErrors={lessonErrors}
                  lessonStates={sequenceLessonStates}
                  onRemoveLesson={onRemoveLesson}
                  onReorderLessons={onReorderLessons}
                  onToggleLesson={onToggleLesson}
                  onUpdateLesson={onUpdateLesson}
                  sequenceIndex={sequenceIndex}
                />
              </div>
              {sequenceErrorMessages.length > 0 && (
                <div className="footer">
                  <ValidationErrors messages={sequenceErrorMessages} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SequenceFrame;
