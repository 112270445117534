import * as React from 'react';

import { LessonData, LessonSettings } from './interfaces';
import Button from './Button';
import LessonPreviewPopup from './LessonPreviewPopup';
import PopupLayout from './PopupLayout';
import SequenceLessonSettings from './SequenceLessonSettings';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import images from '../utils/images';

interface Props {
  isBlockExtended: boolean;
  lessonData: LessonData;
  lessonErrorMessages: string[];
  onEditing: (() => void);
  onMouseEnterSettings: (() => void);
  onMouseLeaveSettings: (() => void);
  onRemoveLesson: (() => void);
  onSaveLessonName: ((value: string) => void);
  onSaveLessonSettings: ((lessonSettings: LessonSettings) => void);
  onToggleLesson: (() => void);
}

interface State {
  showPopup: boolean;
}

class SequenceLesson extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPopup: false
    };
  }

  handleClickRemoveLesson() {
    if (confirm('Are you sure you want to remove this lesson?')) {
      this.props.onRemoveLesson();
    }
  }

  render() {
    const {
      isBlockExtended,
      lessonData,
      lessonErrorMessages,
      onEditing,
      onMouseEnterSettings,
      onMouseLeaveSettings,
      onSaveLessonName,
      onSaveLessonSettings,
      onToggleLesson
    } = this.props;
    return (
      <div className="SequenceLesson">
        <div className="content">
          <div className="blockHeader">
            <Button
              imgAlt="Expand/Collapse"
              imgSrc={images.arrowHeadRight}
              onClick={async () => onToggleLesson()}
              rotated={isBlockExtended}
              size="small"
            />
            <TitleEditor
              onEditing={onEditing}
              onUpdateTitle={onSaveLessonName}
              showErrorIcon={lessonErrorMessages.length > 0}
              title={lessonData.name}
            />
            <div className="action">
              <button onClick={() => this.setState({ showPopup: true })} type="button">
                Preview
              </button>
              {this.state.showPopup && (
                <PopupLayout
                  onClose={() => this.setState({ showPopup: false })}
                  title={`${lessonData.name} Preview`}
                >
                  <LessonPreviewPopup
                    lessonData={lessonData}
                    onClosePopup={() => this.setState({ showPopup: false })}
                  />
                </PopupLayout>
              )}
              <Button
                imgAlt="Remove Lesson"
                imgSrc={images.crossBox}
                onClick={async () => this.handleClickRemoveLesson()}
                size="large"
              />
            </div>
          </div>
          {isBlockExtended && (
            <div className="settings">
              <SequenceLessonSettings
                lessonSettings={lessonData.lessonSettings}
                onClick={onEditing}
                onMouseEnterSettings={onMouseEnterSettings}
                onMouseLeaveSettings={onMouseLeaveSettings}
                onSaveLessonSettings={onSaveLessonSettings}
              />
            </div>
          )}
          {isBlockExtended && lessonErrorMessages.length > 0 && (
            <div className="footer">
              <ValidationErrors messages={lessonErrorMessages} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SequenceLesson;
