import * as React from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { isEqual, sortBy } from 'lodash';
import axios from 'axios';

import { Member } from './interfaces';
import MemberList from './MemberList';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string,
  createModeratorUrl: string;
  members: Member[];
  moderators: Member[];
  showGroupUrl: string;
  token: string;
  updateGroupUrl: string;
}

interface State {
  draggingId: string;
  inFlight: boolean;
  isClient: boolean;
  members: Member[];
  membersSelected: string[];
  moderators: Member[];
  moderatorsSelected: string[];
}

class ModeratorManager extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleSave = this.handleSave.bind(this);
    const { members, moderators } = this.props;

    this.state = {
      draggingId: '',
      inFlight: false,
      isClient: false,
      members,
      membersSelected: [],
      moderators,
      moderatorsSelected: []
    };
  }

  addModerators() {
    const { members, membersSelected, moderators } = this.state;
    const newModerators = members.filter((member) => membersSelected.includes(member.id));
    this.setState({
      draggingId: '',
      members: sortBy(members.filter((member) => !membersSelected.includes(member.id)), (m) => m.displayName.toLowerCase()),
      membersSelected: [],
      moderators: sortBy([...moderators, ...newModerators], (m) => m.displayName.toLowerCase()),
      moderatorsSelected: []
    });
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleDragEnd(dropResult: DropResult) {
    const { destination, source } = dropResult;

    if (!destination || source.droppableId === destination.droppableId) {
      this.setState({ draggingId: '' });
      return;
    }

    if (destination.droppableId === 'moderators') {
      this.addModerators();
    } else {
      this.removeModerators();
    }
  }

  handleDragStart(start: DragStart) {
    const id = start.draggableId;
    const list = start.source.droppableId;

    this.setState({
      draggingId: id
    });

    if (list === 'moderators') {
      const included = this.state.moderatorsSelected.some((moderatorId) => moderatorId === id);
      if (!included) {
        this.setState({ moderatorsSelected: [id] });
      }
    } else {
      const included = this.state.membersSelected.some((memberId) => memberId === id);
      if (!included) {
        this.setState({ membersSelected: [id] });
      }
    }
  }

  handleSave() {
    const { moderators, showGroupUrl, token, updateGroupUrl } = this.props;
    const newModerators = this.state.moderators.filter((moderator) => !moderators.includes(moderator));
    const removedModerators = moderators.filter((moderator) => this.state.members.includes(moderator));

    if (newModerators.length === 0 && removedModerators.length === 0) {
      return;
    }

    this.setState({ inFlight: true });

    axios
      .put(
        updateGroupUrl,
        {
          add: newModerators.map((member) => member.id),
          remove: removedModerators.map((moderator) => moderator.id)
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        window.location.href = `${showGroupUrl}?notice=Moderators successfully updated.`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.response.status === 403) {
          alert(error.response.statusText);
          window.location.href = '/';
        } else if (error.response.status === 422) {
          window.location.href = `${showGroupUrl}?alert=Some moderators could not be updated.`;
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
        this.setState({ inFlight: false });
      });
  }

  removeModerators() {
    const { members, moderators, moderatorsSelected } = this.state;
    const newMembers = moderators.filter((member) => moderatorsSelected.includes(member.id));
    this.setState({
      draggingId: '',
      members: sortBy([...members, ...newMembers], (m) => m.displayName.toLowerCase()),
      membersSelected: [],
      moderators: sortBy(moderators.filter((member) => !moderatorsSelected.includes(member.id)), (m) => m.displayName.toLowerCase()),
      moderatorsSelected: []
    });
  }

  render() {
    return (
      <div className="ModeratorManager">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { draggingId, inFlight, members, membersSelected, moderators, moderatorsSelected } = this.state;
    return (
      <>
        {members.length === 0 && moderators.length === 0 && <h4>There are no members available.</h4>}
        <p>You can drag and drop members from the member list to make them moderators.</p>
        <p>Multiple items can be selected at once.</p>
        <div className="lists">
          <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
            <MemberList
              draggingId={draggingId}
              droppableId="members"
              key="members"
              label="Members"
              members={members}
              onSelect={(selection) => this.setState({ membersSelected: selection })}
              selected={membersSelected}
            />
            <div className="buttons">
              <button disabled={membersSelected.length === 0} onClick={() => this.addModerators()} type="button">
                &#10217;
              </button>
              <button disabled={moderatorsSelected.length === 0} onClick={() => this.removeModerators()} type="button">
                &#10216;
              </button>
            </div>
            <MemberList
              draggingId={draggingId}
              droppableId="moderators"
              key="moderators"
              label="Moderators"
              members={moderators}
              onSelect={(selection) => this.setState({ moderatorsSelected: selection })}
              selected={moderatorsSelected}
            />
          </DragDropContext>
        </div>
        <button
          className="saveButton"
          disabled={inFlight || (isEqual(members, this.props.members) && isEqual(moderators, this.props.moderators))}
          onClick={this.handleSave}
          type="button"
        >
          Save
        </button>
      </>
    );
  }

  renderServerSide() {
    const { authenticityToken, createModeratorUrl, members } = this.props;
    return (
      members.length === 0
        ? <h3>There are no members available.</h3>
        : (
          <form action={createModeratorUrl} className="serverSide" method="post">
            <div className="content">
              <input name="authenticity_token" type="hidden" value={authenticityToken} />
              <label>Select the members to create moderators</label>
              <select multiple name="moderators[membership_ids][]">
                {members.map((member) => <option key={member.id} value={member.id}>{member.displayName}</option>)}
              </select>
            </div>
            <div className="footer">
              <button type="submit">Create Moderators</button>
            </div>
          </form>
        )
    );
  }
}

export default ModeratorManager;
