import * as React from 'react';

import ConversationProfile from './ConversationProfile';
import MessengerSearch from './MessengerSearch';
import { Profile } from './interfaces';
import Spinner from './Spinner';

interface Props {
  conversationUrl: string;
  counterparts: Profile[];
  onCancel: () => void;
  onClickConversation: ((counterpart: Profile) => void);
  profileId: string;
}

interface State {
  conversationSearchTerm: string;
  counterparts: Profile[];
  searching: boolean;
}

class NewConversationList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      counterparts: this.props.counterparts,
      conversationSearchTerm: '',
      searching: false
    };
  }

  changeConversationInput(conversationSearchTerm: string) {
    const { counterparts } = this.props;
    if (counterparts.length === 0) {
      return;
    }

    this.setState({
      searching: true
    });

    const filterByName = () => counterparts.filter((profile) => {
      const displayName = profile.displayName.toLowerCase();
      const searchTerm = conversationSearchTerm.toLowerCase();
      return displayName.includes(searchTerm);
    });

    this.setState({
      conversationSearchTerm,
      counterparts: filterByName(),
      searching: false
    });
  }

  noResultFound() {
    const { conversationSearchTerm, counterparts } = this.state;
    return conversationSearchTerm?.length > 0 && counterparts.length === 0;
  }

  render() {
    const { conversationSearchTerm, searching } = this.state;
    return (
      <div className="NewConversationList">
        <div className="body">
          <MessengerSearch
            conversationSearchTerm={conversationSearchTerm}
            onChangeConversationInput={(inputValue) => this.changeConversationInput(inputValue)}
          />
          {searching
            ? <div className="spinnerContainer"><Spinner size={30} /></div>
            : this.renderConversationList()
          }
        </div>
        <div className="footer">
          <a onClick={this.props.onCancel}>Cancel</a>
        </div>
      </div>
    );
  }

  renderConversationList() {
    return (
      <div className="list">
        {this.noResultFound()
          ? <div className="notFound">No profiles found.</div>
          : this.state.counterparts.map((profile) => (
            <ConversationProfile
              key={profile.id}
              newConversation
              onClickConversation={() => this.props.onClickConversation(profile)}
              profile={profile}
            />
          ))
        }
      </div>
    );
  }
}

export default NewConversationList;
