import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import Cropper from 'react-easy-crop';

interface Area {
  height: number,
  width: number,
  x: number,
  y: number
}

interface Props {
  aspect: number;
  image: string;
  imageType: string;
  onCancel: () => void;
  onDrop?: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  onUploadFile: (blob: Blob) => void;
  showUpload?: boolean;
}

interface State {
  crop: { x: number, y: number };
  inFlight: boolean;
  pixelCrop: Area;
  zoom: number;
}

class CropperPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleCropComplete = this.handleCropComplete.bind(this);

    this.state = {
      crop: { x: 0, y: 0 },
      inFlight: false,
      pixelCrop: { height: 0, width: 0, x: 0, y: 0 },
      zoom: 1
    };
  }

  handleClickSave() {
    this.setState({ inFlight: true });
    const { height, width, x, y } = this.state.pixelCrop;
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        0,
        0,
        width,
        height
      );
      canvas.toBlob((blob) => blob && this.props.onUploadFile(blob), this.props.imageType);
    };
    image.src = this.props.image;
  }

  handleCropComplete = (_croppedArea: {x: number, y: number}, croppedAreaPixels: Area) => {
    const { height, width, x, y } = croppedAreaPixels;
    this.setState({
      pixelCrop: {
        height,
        width,
        x,
        y
      }
    });
  };

  render() {
    const { crop, inFlight, zoom } = this.state;
    const { aspect, image, onCancel, onDrop, showUpload } = this.props;
    return (
      <div className="CropperPopup">
        <div className="body">
          <Cropper
            aspect={aspect}
            crop={crop}
            image={image}
            onCropChange={(newCrop) => this.setState({ crop: newCrop })}
            onCropComplete={this.handleCropComplete}
            onZoomChange={(newZoom) => this.setState({ zoom: newZoom })}
            style={{ containerStyle: { borderRadius: '3px' } }}
            zoom={zoom}
          />
        </div>
        <div className="footer">
          <div className="zoomControl">
            Zoom
            <input
              max="3"
              min="0.7"
              onChange={(event) => this.setState({ zoom: Number(event.target.value) })}
              step="0.1"
              type="range"
              value={zoom}
            />
          </div>
          <div className="buttons">
            <a onClick={onCancel}>
              Cancel
            </a>
            {showUpload && (
              <Dropzone
                accept="image/gif, image/heic, image/jpeg, image/png"
                maxSize={5242880}
                minSize={0}
                multiple={false}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="button" {...getRootProps()}>
                    <button type="button">
                      <input {...getInputProps()} />
                      Upload own
                    </button>
                  </div>
                )}
              </Dropzone>
            )}
            <button
              disabled={inFlight}
              onClick={this.handleClickSave}
              type="button"
            >
              Save photo
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CropperPopup;
