import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { ErrorMessages, LessonData, LessonSettings, LessonState } from './interfaces';
import SequenceLesson from './SequenceLesson';

import getDraggableStyle from '../utils/getDraggableStyle';

interface Props {
  lessonErrors: ErrorMessages[];
  lessonStates: LessonState[];
  onRemoveLesson: ((index: number) => void);
  onReorderLessons: ((sourceIndex: number, destinationIndex: number) => void);
  onToggleLesson: ((index: number) => void);
  onUpdateLesson: ((index: number, lessonData: LessonData) => void);
  sequenceIndex: number;
}

interface State {
  editingTitleId: string;
  isDragDisabled: boolean;
}

const getDroppableStyle = () => ({
  background: 'lightgrey',
  padding: '10px 10px 2px 10px'
});

class SequenceLessons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleMouseEnterSettings = this.handleMouseEnterSettings.bind(this);
    this.handleMouseLeaveSettings = this.handleMouseLeaveSettings.bind(this);
    this.state = {
      editingTitleId: '',
      isDragDisabled: false
    };
  }

  getErrorMessages(errors: ErrorMessages[], index: number) {
    return errors[index].messages || [];
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    this.props.onReorderLessons(result.source.index, result.destination.index);
  }

  handleMouseEnterSettings() {
    this.setState({
      isDragDisabled: true
    });
  }

  handleMouseLeaveSettings() {
    this.setState({
      editingTitleId: '',
      isDragDisabled: false
    });
  }

  render() {
    const {
      lessonErrors,
      lessonStates,
      onRemoveLesson,
      onToggleLesson,
      sequenceIndex
    } = this.props;
    const { editingTitleId, isDragDisabled } = this.state;
    return (
      <div className="SequenceLessons">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable
            droppableId={`sequence-${sequenceIndex}-lessons`}
            type="droppableItem"
          >
            {(providedDroppable) => (
              <div
                className="lessons"
                ref={providedDroppable.innerRef}
                style={getDroppableStyle()}
              >
                {lessonStates.length < 1
                  ? (
                    <div className="noLessons">
                      There is no lesson for this sequence.
                    </div>
                  )
                  : lessonStates.map((lesson, index) => {
                    const { extended, lessonData } = lesson;
                    return (
                      <Draggable
                        disableInteractiveElementBlocking={lessonData.id !== editingTitleId}
                        draggableId={`sequence-${sequenceIndex}-lesson-${index}`}
                        index={index}
                        isDragDisabled={isDragDisabled}
                        key={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getDraggableStyle(provided.draggableProps.style)}
                          >
                            <SequenceLesson
                              isBlockExtended={extended}
                              lessonData={lessonData}
                              lessonErrorMessages={this.getErrorMessages(lessonErrors, index)}
                              onEditing={() => this.setState({ editingTitleId: lessonData.id || '' })}
                              onMouseEnterSettings={this.handleMouseEnterSettings}
                              onMouseLeaveSettings={this.handleMouseLeaveSettings}
                              onRemoveLesson={() => onRemoveLesson(index)}
                              onSaveLessonName={(value) => this.updateLessonName(index, value)}
                              onSaveLessonSettings={(lessonSettings) => this.updateLessonSettings(index, lessonSettings)}
                              onToggleLesson={() => onToggleLesson(index)}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                }
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  updateLessonName(index: number, name: string) {
    this.props.onUpdateLesson(index, {
      ...this.props.lessonStates[index].lessonData,
      name
    });
  }

  updateLessonSettings(index: number, lessonSettings: LessonSettings) {
    this.props.onUpdateLesson(index, {
      ...this.props.lessonStates[index].lessonData,
      lessonSettings
    });
  }
}

export default SequenceLessons;
