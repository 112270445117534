import * as React from 'react';
import axios from 'axios';

import DeleteButton from './DeleteButton';
import LikeButton from './LikeButton';
import { StatusUpdate } from './interfaces';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string;
  deleteClientSideUrl?: string;
  deleteServerSideUrl?: string;
  friendlyName: string;
  likeId: string | null;
  likesUrl?: string;
  membershipId?: string;
  status: StatusUpdate;
  token: string;
}

function StatusUpdateMember(props: Props) {
  const {
    authenticityToken,
    deleteClientSideUrl,
    deleteServerSideUrl,
    likeId,
    likesUrl,
    membershipId,
    status,
    token
  } = props;
  const signal = axios.CancelToken.source();

  React.useEffect(() => () => {
    signal.cancel('Request cancelled.');
  });

  const deleteStatus = () => {
    if (!deleteClientSideUrl) {
      return;
    }

    axios
      .delete(
        deleteClientSideUrl,
        {
          cancelToken: signal.token,
          headers: headers(token),
          params: { id: status.id }
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log(error.message);
        } else {
          console.error(error);
          alert(error.message);
        }
      });
  };

  return (
    status && (
      <div className="StatusUpdateMember">
        {status.content}
        <div className="buttons">
          {likesUrl && (
            <LikeButton
              authenticityToken={authenticityToken}
              canLike
              likeId={likeId}
              likeableId={status.id}
              likeableType="StatusUpdate"
              likesUrl={likesUrl}
              membershipId={membershipId}
              token={token}
            />
          )}
          {deleteServerSideUrl && (
            <DeleteButton
              appearance="flat"
              authenticityToken={authenticityToken}
              friendlyName="status update"
              onClickDeleteButton={() => deleteStatus()}
              serverSideUrl={deleteServerSideUrl}
            />
          )}
        </div>
      </div>
    )
  );
}

export default StatusUpdateMember;
