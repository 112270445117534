import * as React from 'react';
import axios from 'axios';

import { BlockQuiz } from './interfaces';
import QuestionnaireEditor from './QuestionnaireEditor';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import headers from '../utils/headersGenerator';

interface Props {
  questionnaire: BlockQuiz;
  questionnaireType: 'quiz' | 'survey';
  showUrl: string;
  token: string;
  updateUrl: string;
}

interface State {
  isClient: boolean;
  isDirty: boolean;
}

class QuestionnaireEditorContainer extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.state = {
      isClient: false,
      isDirty: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  render() {
    return this.state.isClient ? this.renderClientSide() : this.renderServerSide();
  }

  renderClientSide() {
    return (
      <div className="QuestionnaireEditorContainer">
        <QuestionnaireEditor
          onDirty={() => this.setState({ isDirty: true })}
          onUpdateQuestionnaire={(questionnaire) => this.saveChanges(questionnaire)}
          onlyQuestions
          questionnaire={this.props.questionnaire}
          questionnaireType={this.props.questionnaireType}
        />
        <UnsavedChangesPrompt isDirty={this.state.isDirty} />
      </div>
    );
  }

  renderServerSide() {
    return (
      <div className="QuestionnaireEditorContainer">
        <p>Please enable JavaScript and refresh the page to edit questions.</p>
      </div>
    );
  }

  saveChanges(questionnaire: BlockQuiz) {
    const { questionnaireType, showUrl, token, updateUrl } = this.props;
    axios
      .put(
        updateUrl,
        { [questionnaireType]: { 'content': questionnaire.content } },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.setState({
          isDirty: false
        }, () => { window.location.href = `${showUrl}?notice=${response.data.message}`; });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
        }
        window.location.href = `${showUrl}?alert=${error.response.data.message}`;
      });
  }
}

export default QuestionnaireEditorContainer;
