import * as React from 'react';

import images from '../utils/images';

interface Props {
  label: string;
}

function SortOption(props: Props) {
  return (
    <div className="SortOption">
      <img alt="Drag Handler" src={images.drag} />
      {props.label}
    </div>
  );
}

export default SortOption;
