import { CancelTokenSource } from 'axios';

export enum BlockType {
  Attachment = 'attachment',
  Link = 'link',
  Quiz = 'quiz',
  Rich = 'rich',
  Survey = 'survey',
  Video = 'video'
}

export enum QuestionType {
  Float = 'float',
  Freeform = 'freeform',
  Integer = 'integer',
  MultipleChoice = 'multiple-choice',
  Sort = 'sort'
}

export interface Avatar {
  path: string,
  url: string
}

export interface BaseBlock {
  id: string;
  title: string;
}

export interface BaseOption {
  id: string;
  label: string;
}

export interface BaseQuestion {
  correct?: number | string;
  dependantOf?: string;
  id: string;
  marked?: string;
  prompt: string;
  required?: boolean;
}

export interface BlockAttachment extends BaseBlock {
  content: UploadedFile[];
  description?: string;
  type: BlockType.Attachment;
}

export interface BlockLink extends BaseBlock {
  content: Link[];
  description?: string;
  type: BlockType.Link;
}

// attemptLimit, daysToReleaseGrade, manualGrading and description are not present in Surveys
export interface BlockQuiz extends BaseBlock {
  attemptLimit?: number;
  content: Question[];
  daysToReleaseGrade?: number;
  description?: string;
  id: string,
  manualGrading?: boolean;
  title: string;
  type: BlockType.Quiz;
}

export interface BlockRich extends BaseBlock {
  content: string;
  type: BlockType.Rich;
}

export interface BlockVideo extends BaseBlock {
  content: { videoSrc: string };
  description?: string;
  type: BlockType.Video;
}

type Block = BlockAttachment | BlockLink | BlockQuiz | BlockRich | BlockVideo;

export interface DefaultAvatarInterface {
  path: string,
  url: string
}

export interface EmailAddress {
  label: string;
  valid: boolean;
  value: string;
}

export interface ErrorMessages {
  index: number;
  messages: string[];
}

export interface FloatQuestion extends BaseQuestion {
  maxValue?: number;
  minValue?: number;
  placeholder?: string;
  type: QuestionType.Float;
}

export interface FreeformQuestion extends BaseQuestion {
  placeholder?: string;
  type: QuestionType.Freeform;
}

export interface IntegerQuestion extends BaseQuestion {
  maxValue?: number;
  minValue?: number;
  placeholder?: string;
  type: QuestionType.Integer;
}

export interface InteractionElement {
  anyFlag: boolean;
  avatarUrl: string;
  canDestroy: boolean;
  canFlag: boolean;
  canLike: boolean;
  content: string;
  createdAt: string;
  createdTime: string;
  deleteUrlClient: string;
  deleteUrlServer: string;
  deleted: boolean;
  displayName: string;
  flagId?: string;
  flagged: boolean;
  id: string;
  likeCount: number;
  likeId: string;
  memberUrl: string;
}

export interface LessonSettings {
  commentsEnabled: boolean;
  daysToRelease: number | string;
  points: number | string;
  slug: string;
}

// Lesson data received from Ruby
export interface Lesson {
  blocks: Block[];
  id?: string;
  lessonSettings: LessonSettings;
  name: string;
  templateId?: string;
}

// Lesson data that is used for editor
export interface LessonData {
  blocks: Block[];
  id?: string;
  lessonSettings: LessonSettings;
  name: string;
  templateId?: string;
}

export interface LessonState {
  extended: boolean;
  lessonData: LessonData;
}

export interface LessonContentBlock extends BaseBlock {
  content: Question[];
  type: BlockType.Quiz;
}

export interface Link {
  id: string;
  title: string;
  url: string;
}

export interface Member {
  displayName: string;
  id: string;
}

export interface MembershipQuestion {
  id: string;
  placeholder?: string;
  prompt: string;
  required?: boolean;
}

export interface MultipleChoiceOption extends BaseOption {
  correct?: boolean | string;
  freeform?: boolean;
}

export interface MultipleChoiceQuestion extends BaseQuestion {
  freeform?: boolean;
  maxSelectable?: number;
  multipleChoiceOptions?: MultipleChoiceOption[];
  required?: boolean;
  shuffle?: boolean;
  type: QuestionType.MultipleChoice;
}

export interface NotificationElement {
  avatarUrl: string;
  createdAt: string;
  displayName: string;
  hidden: boolean;
  id: string;
  memberUrl: string;
  read: boolean;
  seen: boolean;
  title: string;
  url: string;
}

export interface Profile {
  avatar: string;
  displayName: string;
  id: string;
}

type Option = MultipleChoiceOption | SortableOption;

type Question = FloatQuestion | IntegerQuestion | FreeformQuestion | MultipleChoiceQuestion | SortableQuestion;

export interface QuestionError {
  index: number;
  messages: string[];
}

export interface Rule {
  content: string;
  id: string;
  title: string;
}

// Sequence data received from Ruby
export interface Sequence {
  id: string;
  lessons: Lesson[];
  name: string;
}

// Sequence data that is for editor
export interface SequenceData {
  id?: string;
  name: string;
}

export interface SortableQuestion extends BaseQuestion {
  sortOptions?: SortableOption[];
  type: QuestionType.Sort;
}

type SortableOption = BaseOption;

export interface StatusUpdate {
  content: string;
  id: string;
  status: string;
}

export interface TriggeredSurvey {
  block: SurveyContentBlock;
  profileId?: string;
  surveyResponsesUrl: string;
  token: string;
  triggeredSurveyId: string;
  triggeredSurveyUrl: string;
  userId: string;
}

export interface SurveyContentBlock extends BaseBlock{
  content: Question[];
  type: BlockType.Survey;
}

export interface UploadedFile {
  cancelTokenSource?: CancelTokenSource;
  filename: string;
  id: string;
  loaded?: number;
  size: number;
}

export type { Block, Option, Question, SortableOption };
