import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Rule } from './interfaces';
import RuleEditor from './RuleEditor';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import getDraggableStyle from '../utils/getDraggableStyle';
import headers from '../utils/headersGenerator';
import reorder from '../utils/reorder';

interface Props {
  groupId: string;
  groupShowUrl: string;
  rules: Rule[];
  token: string;
  updateUrl: string;
}

interface State {
  editingRuleId: string;
  expandedRules: string[];
  isClient: boolean;
  isDirty: boolean;
  rules: Rule[];
}

class CodeOfConductEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleUpdateRule = this.handleUpdateRule.bind(this);
    this.state = {
      editingRuleId: '',
      expandedRules: [],
      isClient: false,
      isDirty: false,
      rules: this.props.rules
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleClickAdd() {
    const rules = [...this.state.rules, { content: 'Add content here', id: uuidv4(), title: 'Add title' }];
    this.setState({
      isDirty: !isEqual(rules, this.props.rules),
      rules
    });
  }

  handleClickSave() {
    const { groupId, groupShowUrl, token, updateUrl } = this.props;
    axios
      .patch(
        updateUrl,
        {
          codeOfConduct: this.state.rules,
          groupId
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          isDirty: false
        }, () => {
          window.location.href = `${groupShowUrl}?notice=Code of conduct successfully submitted.`;
        });
      })
      .catch((error) => {
        this.setState({
          isDirty: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          window.location.href = `${groupShowUrl}?alert=Code of conduct could not be submitted.`;
        }
      });
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    const rules = reorder(this.state.rules, result.source.index, result.destination.index);
    this.setState({
      isDirty: !isEqual(rules, this.props.rules),
      rules
    });
  }

  handleUpdateRule(rule: Rule) {
    const rules = this.state.rules.map((oldRule) => oldRule.id === rule.id ? rule : oldRule);
    this.setState({
      isDirty: !isEqual(rules, this.props.rules),
      rules
    });
  }

  isDisabled() {
    const tooShort = this.state.rules.some((rule) => (
      rule.content.trim().length < 2 || rule.title.trim().length < 2
    ));

    return !this.state.isDirty || tooShort;
  }

  onClickArrowHead(id: string) {
    const { expandedRules } = this.state;
    this.setState({
      expandedRules: expandedRules.includes(id)
        ? expandedRules.filter((expanded) => expanded !== id)
        : [...expandedRules, id]
    });
  }

  removeRule(id: string) {
    const rules = this.state.rules.filter((rule) => rule.id !== id);
    this.setState({
      expandedRules: this.state.expandedRules.filter((expanded) => expanded !== id),
      isDirty: !isEqual(rules, this.props.rules),
      rules
    });
  }

  render() {
    const { expandedRules, isClient, isDirty, rules } = this.state;
    if (!isClient) {
      return <>Please, enable JavaScript to edit the code of conduct.</>;
    }
    return (
      <div className="CodeOfConductEditor">
        <div className="buttons">
          <div className="left">
            <button onClick={this.handleClickAdd} type="button">
              Add Rule
            </button>
          </div>
          <div className="right">
            <button
              disabled={expandedRules.length === 0}
              onClick={() => this.setState({ expandedRules: [] })}
              type="button"
            >
              Minimise All
            </button>
            <button
              disabled={expandedRules.length === rules.length}
              onClick={() => this.setState({ expandedRules: rules.map((rule) => rule.id) })}
              type="button"
            >
              Expand All
            </button>
          </div>
        </div>
        <div className="content">
          {rules.length === 0
            ? 'There are no rules for this group.'
            : this.renderRules()
          }
        </div>
        <div className="footer">
          <button
            disabled={this.isDisabled()}
            onClick={() => this.handleClickSave()}
            type="button"
          >
            Save
          </button>
          <UnsavedChangesPrompt isDirty={isDirty} />
        </div>
      </div>
    );
  }

  renderRules() {
    const { editingRuleId, expandedRules, rules } = this.state;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId="CodeOfConductEditor">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {rules.map((rule, index) => {
                const { id } = rule;
                return (
                  <Draggable
                    disableInteractiveElementBlocking={id !== editingRuleId}
                    draggableId={id}
                    index={index}
                    key={id}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        ref={providedDraggable.innerRef}
                        style={getDraggableStyle(providedDraggable.draggableProps.style, rules.length === index + 1)}
                      >
                        <RuleEditor
                          expanded={expandedRules.includes(id)}
                          index={index}
                          onClickArrowHead={() => this.onClickArrowHead(id)}
                          onEditingTitle={() => this.setState({ editingRuleId: id })}
                          onMouseLeave={() => this.setState({ editingRuleId: '' })}
                          onRemove={() => this.removeRule(id)}
                          onUpdate={this.handleUpdateRule}
                          rule={rule}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default CodeOfConductEditor;
