import * as React from 'react';
import DeleteButton from './DeleteButton';
import FlagButton from './FlagButton';
import { InteractionElement } from './interfaces';

interface Props {
  authenticityToken: string;
  clientSideDeleteUrl: string;
  flagsUrl: string;
  friendlyName: string;
  interaction: InteractionElement;
  onClick: ((flagId: string) => void);
  onClickDeleteButton: ((id: string) => void);
  onToggleShowMenu: ((showMenu: boolean) => void);
  reportOptions: string[];
  serverSideDeleteUrl: string;
  token: string;
  type: string;
}

function InteractionSubMenu(props: Props) {
  return (
    <div className="InteractionSubMenu">
      {props.interaction.canFlag && (
        <div className="item">
          <FlagButton
            appearance="flat"
            authenticityToken={props.authenticityToken}
            flagId={props.interaction.flagId}
            flaggableId={props.interaction.id}
            flaggableType={props.type}
            flagsUrl={props.flagsUrl}
            onClick={props.onClick}
            onToggleShowMenu={props.onToggleShowMenu}
            reportOptions={props.reportOptions}
            title={props.interaction.flagId ? 'Discard this report' : `Report this ${props.friendlyName.toLowerCase()}`}
            token={props.token}
          />
        </div>
      )}
      {props.interaction.canDestroy && (
        <div className="item">
          <DeleteButton
            appearance="flat"
            authenticityToken={props.authenticityToken}
            friendlyName={props.friendlyName}
            onClickDeleteButton={() => props.onClickDeleteButton(props.interaction.id)}
            onToggleShowMenu={props.onToggleShowMenu}
            serverSideUrl={props.serverSideDeleteUrl}
            title={`Delete this ${props.friendlyName.toLowerCase()}`}
          />
        </div>
      )}
    </div>
  );
}

export default InteractionSubMenu;
