import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { cloneDeep, shuffle } from 'lodash';
import { ToWords } from 'to-words';
import axios from 'axios';

import {
  FloatQuestion,
  FreeformQuestion,
  IntegerQuestion,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
  Question,
  QuestionType,
  SortableQuestion,
  TriggeredSurvey
} from './interfaces';
import Checkbox from './Checkbox';
import SortOption from './SortOption';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import getDraggableStyle from '../utils/getDraggableStyle';
import headers from '../utils/headersGenerator';
import reorder from '../utils/reorder';

interface Props {
  triggeredSurvey: TriggeredSurvey;
}

interface Response {
  content?: string;
  options?: string[];
}

interface Responses {
  [key: string]: Response
}

interface State {
  currentPage: number;
  firstPage: number;
  isClient: boolean;
  isDirty: boolean;
  maxPage: number;
  questions: Question[];
  responses: Responses | {};
}

class SurveyContent extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClickRemind = this.handleClickRemind.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      currentPage: 1,
      firstPage: 1,
      isClient: false,
      isDirty: false,
      maxPage: this.getMaximumPageNumber(),
      questions: [],
      responses: {}
    };
  }

  changeNumber(questionId: string, type: Question['type'], value: string) {
    const floatRegex = /^\d+\.?\d{0,2}$/;
    const integerRegex = /^\d+$/;
    if (value.length >= 7) {
      return false;
    }

    if (value === '') {
      return this.updateResponse(questionId, '');
    }

    if (type === QuestionType.Integer && integerRegex.test(value)) {
      return this.updateResponse(questionId, value);
    }

    if (type === QuestionType.Float && floatRegex.test(value)) {
      return this.updateResponse(questionId, value);
    }

    return false;
  }

  checkDependency(question: Question) {
    if (question.dependantOf) {
      const optionId = question.dependantOf;
      const { responses } = this.state;
      return Object.values(responses).filter((response) => response.options?.includes(optionId)).length > 0;
    }
    return true;
  }

  checkRequired(questions: Question[]) {
    const { responses } = this.state;
    const requiredIds = questions
      .filter((question) => question.required)
      .map((que) => que.id);
    const responsesIds = Object.keys(responses);
    return requiredIds.find((req) => !responsesIds.includes(req)) === undefined;
  }

  clickCheckbox(questionId: string, optionId: string) {
    if (this.isMaxReached(questionId) && !this.isOptionIncluded(questionId, optionId)) {
      return;
    }

    this.updateOptionResponses(questionId, optionId);
  }

  componentDidMount() {
    const parsedQuestions = this.parseQuestions();
    const questions = this.shuffleQuestionOptions(parsedQuestions);
    const responses = {};
    questions.forEach((question) => {
      if (question.type === QuestionType.Sort) {
        responses[question.id] = { options: question.sortOptions?.map((option) => option.id) };
      }
    });

    this.setState({
      isClient: true,
      questions,
      responses
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  getMaximumPageNumber() {
    const questions = this.props.triggeredSurvey.block.content;
    return Math.ceil(questions.length / 5);
  }

  getQuestionsPerPage() {
    const { currentPage, questions } = this.state;
    return questions.slice((currentPage - 1) * 5, currentPage * 5);
  }

  handleClickRemind() {
    const { triggeredSurvey } = this.props;

    axios
      .put(
        triggeredSurvey.triggeredSurveyUrl,
        {
          triggeredSurvey: {
            remindLater: true
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(triggeredSurvey.token)
        }
      )
      .then(() => {
        window.location.href = '/?notice=Survey ignored';
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error.response.data.errors);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  handleDragEnd(questionId: string, result: DropResult, sortOptionIds: string[]) {
    if (!result.destination) {
      return;
    }

    const { responses } = this.state;
    const options = reorder(sortOptionIds, result.source.index, result.destination.index);
    this.setState({
      isDirty: true,
      responses: {
        ...responses,
        [questionId]: {
          ...responses[questionId],
          options
        }
      }
    });
  }

  handleSubmit() {
    const {
      block,
      profileId,
      surveyResponsesUrl,
      token,
      triggeredSurveyId,
      userId
    } = this.props.triggeredSurvey;

    axios
      .post(
        surveyResponsesUrl,
        {
          surveyResponse: {
            content: JSON.stringify(this.state.responses),
            profileId,
            surveyId: block.id,
            triggeredSurveyId,
            userId
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          isDirty: false
        }, () => {
          alert('Survey successfully submitted.');
          window.location.reload();
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.response.status === 406) {
          this.setState({ isDirty: false }, () => {
            alert('Could not submit the survey.');
          });
        } else {
          console.error(error);
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  isMaxReached(questionId: string) {
    const question = this.state.responses[questionId];
    if (!question) {
      return false;
    }

    return question.options && question.options.length >= (this.maxSelectForQuestion(questionId) || 0);
  }

  isOptionIncluded(questionId: string, optionId: string) {
    const question = this.state.responses[questionId];
    if (!question) {
      return false;
    }

    return question.options.includes(optionId);
  }

  maxSelectForQuestion(questionId: string) {
    const question = this.state.questions.find((eachQuestion) => eachQuestion.id === questionId);
    if (question?.type !== QuestionType.MultipleChoice) {
      return NaN;
    }
    return question.maxSelectable;
  }

  parseQuestions(): Question[] {
    const { content } = this.props.triggeredSurvey.block;
    return typeof content === 'string' ? JSON.parse(content) : content;
  }

  render() {
    return (
      this.state.isClient ? this.renderClientSide() : this.renderServerSide()
    );
  }

  renderClientSide() {
    const { currentPage, firstPage, maxPage, responses } = this.state;
    const questions = this.getQuestionsPerPage();
    return (
      <div className="SurveyContent">
        <UnsavedChangesPrompt isDirty={this.state.isDirty} />
        <div className="header">
          Page {currentPage} of {maxPage}
        </div>
        <div className="body expanded">
          {questions && questions.map((question) => (
            this.checkDependency(question) && (
              <div className="question editable" key={question.id}>
                <div className="header">
                  <h3 className={`${question.required ? 'required' : ''}`}>{question.prompt}</h3>
                </div>
                {this.renderQuestion(question)}
              </div>
            )
          ))}
        </div>
        <div className="footer">
          <button
            className="remind"
            onClick={this.handleClickRemind}
            type="button"
          >
            Remind me later
          </button>
          {firstPage !== currentPage && (
            <button
              className="prevButton"
              onClick={() => this.setState({ currentPage: currentPage - 1 })}
              type="button"
            >
              Previous
            </button>
          )}
          {currentPage !== maxPage
            ? (
              <button
                disabled={!this.checkRequired(questions)}
                onClick={() => this.setState({ currentPage: currentPage + 1 })}
                type="button"
              >
                Continue
              </button>
            )
            : (
              <button
                disabled={JSON.stringify(responses) === '{}' || !this.checkRequired(questions)}
                onClick={this.handleSubmit}
                type="submit"
              >
                Submit
              </button>
            )}
        </div>
      </div>
    );
  }

  renderFreeform(question: FreeformQuestion | MultipleChoiceQuestion, option?: MultipleChoiceOption) {
    const responseValue = this.state.responses[question.id]?.content;
    return (
      <div className="freeform">
        {question.type === QuestionType.Freeform || (option && this.isOptionIncluded(question.id, option.id))
          ? (
            <input
              autoFocus={option?.freeform}
              onChange={(event) => this.updateResponse(question.id, event.target.value)}
              placeholder={option?.label}
              required={question.required}
              type="text"
              value={responseValue}
            />
          ) : option?.label || ''
        }
      </div>
    );
  }

  renderMultipleChoice(question: MultipleChoiceQuestion) {
    const { id, maxSelectable, multipleChoiceOptions } = question;
    const optionValue = (value: number) => {
      if (value === 1) {
        return 'Choose one option';
      }
      return `Choose up to ${new ToWords().convert(value).toLowerCase()} options`;
    };

    return (
      <>
        {maxSelectable && <p><small>{optionValue(maxSelectable)}</small></p>}
        {multipleChoiceOptions?.map((option) => {
          // if maxSelectable limit is reached && option not selected => disable
          const disabled = this.isMaxReached(id) && !this.isOptionIncluded(id, option.id);
          return (
            <div
              className={`multipleChoice ${disabled ? 'disabled' : ''}`}
              key={option.id}
              onClick={() => this.clickCheckbox(id, option.id)}
            >
              <Checkbox
                checked={this.isOptionIncluded(id, option.id)}
                disabled={disabled}
                onClick={() => this.clickCheckbox(id, option.id)}
              />
              {
                option.freeform
                  ? this.renderFreeform(question, option)
                  : <div>{option.label}</div>
              }
            </div>
          );
        })}
      </>
    );
  }

  renderNumberQuestion(question: FloatQuestion | IntegerQuestion) {
    return (
      <input
        max={question.maxValue}
        min={question.minValue}
        onChange={(event) => this.changeNumber(question.id, question.type, event.target.value)}
        placeholder={question.placeholder}
        required={question.required}
        type="number"
        value={this.state.responses[question.id]?.content || ''}
      />
    );
  }

  renderQuestion(question: Question) {
    switch (question.type) {
      case QuestionType.Float:
        // fall-through
      case QuestionType.Integer:
        return this.renderNumberQuestion(question);
      case QuestionType.Freeform:
        return this.renderFreeform(question);
      case QuestionType.MultipleChoice:
        return this.renderMultipleChoice(question);
      case QuestionType.Sort:
        return this.renderSortQuestion(question);
      default:
        return '';
    }
  }

  renderServerSide() {
    return (
      <div className="SurveyContent">
        <p>Please enable JavaScript and refresh the page to see the survey.</p>
      </div>
    );
  }

  renderSortQuestion(question: SortableQuestion) {
    const questionSortOptions = question.sortOptions;
    const sortByResponse = () => {
      const response: Response = this.state.responses[question.id];
      return response?.options?.map((responseOption) => questionSortOptions?.find((option) => option.id === responseOption));
    };
    const sortOptions = sortByResponse();
    if (!sortOptions) {
      return '';
    }
    return (
      <div className="sort">
        <DragDropContext onDragEnd={(result) => this.handleDragEnd(question.id, result, sortOptions.map((option) => option?.id || ''))}>
          <Droppable droppableId="SortQuestion">
            {(providedDroppable) => (
              <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {sortOptions.map((option, index) => {
                  if (!option) {
                    return '';
                  }
                  return (
                    <Draggable
                      draggableId={option.id}
                      index={index}
                      key={option.id}
                    >
                      {(providedDraggable) => (
                        <div
                          {...providedDraggable.draggableProps}
                          ref={providedDraggable.innerRef}
                          style={getDraggableStyle(providedDraggable.draggableProps.style)}
                        >
                          <div {...providedDraggable.dragHandleProps}>
                            <SortOption label={option.label} />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }

  shuffleQuestionOptions(questions: Question[]) {
    return questions.map((question) => {
      switch (question.type) {
        case QuestionType.Sort:
          return {
            ...question,
            sortOptions: shuffle(question.sortOptions)
          };
        case QuestionType.MultipleChoice: {
          if (!question.shuffle) {
            return question;
          }

          const optionsWithoutFreeform = question.multipleChoiceOptions?.filter((option) => option.freeform !== true) || [];
          const freeformOption = question.multipleChoiceOptions?.find((option) => option.freeform === true) || [];

          return {
            ...question,
            multipleChoiceOptions: shuffle(optionsWithoutFreeform).concat(freeformOption)
          };
        }
        default:
          return question;
      }
    });
  }

  updateOptionResponses(questionId: string, newOptionId: string) {
    const newResponse = { [questionId]: { content: '', options: [newOptionId] } };
    const question = this.state.responses[questionId];
    const { responses } = this.state;
    // if question is not included add question with newOptionResponse
    if (!question) {
      this.setState({
        isDirty: true,
        responses: { ...responses, ...newResponse }
      });
      return;
    }

    const isOptionIncluded = this.isOptionIncluded(questionId, newOptionId);
    // if maxSelectable === 1 or if new option is the only one and included, remove question response
    if (isOptionIncluded && question.options.length === 1) {
      const newResponses = cloneDeep(responses);
      delete newResponses[questionId];
      this.setState({
        isDirty: true,
        responses: { ...newResponses }
      });
      return;
    }

    this.setState({
      isDirty: true,
      responses: {
        ...responses,
        [questionId]: {
          ...responses[questionId],
          options:
            // if newOption is included delete it
            isOptionIncluded
              ? question.options.filter((option: string) => option !== newOptionId)
              // else push new newOptionResponse
              : question.options.concat(newOptionId)
        }
      }
    });
  }

  updateResponse(questionId: string, content: string) {
    const { responses } = this.state;
    if (content === '') {
      const newResponses = cloneDeep(responses);
      delete newResponses[questionId];
      this.setState({
        isDirty: true,
        responses: { ...newResponses }
      });
    } else {
      this.setState({
        isDirty: true,
        responses: { ...responses, [questionId]: { ...responses[questionId], content } }
      });
    }
  }
}

export default SurveyContent;
