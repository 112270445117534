import heic2any from 'heic2any';

const heicConversor = (blob: Blob) => {
  const convertedBlob = heic2any({
    blob,
    quality: 1,
    toType: 'image/jpeg'
  });
  return convertedBlob;
};

export default heicConversor;
