import * as React from 'react';
import images from 'utils/images';

import Button from './Button';
import { Link } from './interfaces';

interface Props {
  currentBlockId: string;
  link: Link;
  onClickEdit: ((blockId: string, linkId: string) => void);
  onRemoveLink: ((linkId: string) => void);
}

function LinkElement(props: Props) {
  return (
    <div className="LinkElement">
      <div className="left">
        <a
          href={props.link.url.startsWith('http') ? props.link.url : `//${props.link.url}`}
          rel="noreferrer"
          target="_blank"
          type="button"
        >
          <p>
            {props.link.title ? props.link.title : props.link.url}
          </p>

        </a>
      </div>
      <div className="right">
        <div className="editButton">
          <Button
            imgAlt="Edit"
            imgSrc={images.edit}
            onClick={async () => props.onClickEdit(props.currentBlockId, props.link.id)}
            size="small"
          />
        </div>
        <Button
          imgAlt="Remove"
          imgSrc={images.crossBox}
          onClick={async () => props.onRemoveLink(props.link.id)}
          size="medium"
        />
      </div>
    </div>
  );
}

export default LinkElement;
