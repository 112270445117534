import * as React from 'react';
import isBlank from 'utils/isBlank';

import PopupLayout from './PopupLayout';
import { StatusUpdate } from './interfaces';
import StatusUpdatePopupBody from './StatusUpdatePopupBody';

interface Props {
  active: boolean;
  authenticityToken: string;
  clearStatusServerSideUrl: string;
  clientSideUrl: string;
  membershipId: string;
  serverSideUrl: string;
  statusUpdates: StatusUpdate[];
  token: string;
}

interface State {
  content: string;
  errorMessages: string[];
  showPopup: boolean;
  statusUpdates: StatusUpdate[];
}

class StatusUpdateActivity extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleUpdateStatusUpdates = this.handleUpdateStatusUpdates.bind(this);
    const { statusUpdates } = this.props;
    const content = statusUpdates.find((status) => status.status === 'active')?.content || '';
    this.state = {
      content,
      errorMessages: [],
      showPopup: false,
      statusUpdates
    };
  }

  handleClosePopup() {
    this.setState({
      errorMessages: [],
      showPopup: false
    });
  }

  handleUpdateStatusUpdates(statusUpdates: StatusUpdate[]) {
    const content = statusUpdates.find((status) => status.status === 'active')?.content || '';
    this.setState({
      content,
      statusUpdates
    });
  }

  render() {
    const { content, showPopup } = this.state;
    const { active, serverSideUrl } = this.props;
    return (
      <div className="StatusUpdateActivity">
        {!isBlank(content) && <div className="content">{content}</div>}
        {active && (
          <div className="actionButtons">
            <a
              className="button"
              href={serverSideUrl}
              onClick={(event) => {
                event.preventDefault();
                this.setState({ showPopup: true });
              }}
            >
              Set Status
            </a>
            {this.renderClearButton()}
          </div>
        )}
        {showPopup && this.renderPopup()}
      </div>
    );
  }

  renderClearButton() {
    const { authenticityToken, clearStatusServerSideUrl } = this.props;
    return (
      <form action={clearStatusServerSideUrl} method="post">
        <input name="_method" type="hidden" value="delete" />
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <input disabled={isBlank(this.state.content)} type="submit" value="Clear" />
      </form>
    );
  }

  renderPopup() {
    const { clientSideUrl, membershipId, token } = this.props;
    const { content, errorMessages, statusUpdates } = this.state;
    return (
      <PopupLayout
        errorMessages={errorMessages}
        onClose={this.handleClosePopup}
        title="Edit Status"
      >
        <StatusUpdatePopupBody
          clientSideUrl={clientSideUrl}
          content={content}
          membershipId={membershipId}
          onClose={this.handleClosePopup}
          onError={(messages) => this.setState({ errorMessages: messages })}
          onUpdateStatusList={this.handleUpdateStatusUpdates}
          statusUpdates={statusUpdates}
          token={token}
        />
      </PopupLayout>
    );
  }
}

export default StatusUpdateActivity;
