import * as React from 'react';

import Avatar from './Avatar';
import { Profile } from './interfaces';

interface Props {
  counterpartId?: string;
  newConversation?: boolean;
  onClickConversation: (() => void);
  profile: Profile;
  unreadMessagesProfileIds?: string[];
}

const classNames = (props: Props) => (
  `${props.counterpartId === props.profile.id ? 'active' : 'inactive'}
   ${props.unreadMessagesProfileIds && props.unreadMessagesProfileIds.includes(props.profile.id) ? 'unread' : ''}`
);

function ConversationProfile(props: Props) {
  return (
    <div className="ConversationProfile">
      <a
        className={props.newConversation ? '' : classNames(props)}
        href={`?counterpart=${props.profile.id}`}
        onClick={(event) => {
          event.preventDefault();
          props.onClickConversation();
        }}
      >
        <Avatar
          rounded
          size="small"
          url={props.profile.avatar}
        />
        <span className="displayName">{props.profile.displayName}</span>
      </a>
    </div>
  );
}

export default ConversationProfile;
