import * as React from 'react';

import { Avatar } from './interfaces';
import LoginPopup from './LoginPopup';
import OmniauthButtons from './OmniauthButtons';
import SignupPopup from './SignupPopup';

interface Props {
  authenticityToken: string;
  avatars: Avatar[];
  clientLoginUrl: string;
  clientSignupUrl: string;
  currentPath: string;
  facebookUrl: string;
  forgotPasswordUrl: string;
  googleUrl: string;
  logoUrl: string;
  rootUrl: string;
  serverLoginUrl: string;
  serverRegistrationUrl: string;
}

interface State {
  showLogin: boolean;
  showSignup: boolean;
}

const activePath = (currentPath: string, linkPath: string) => (
  currentPath.startsWith(linkPath) ? 'active' : ''
);

class UnauthenticatedLinks extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleClickLogin = this.handleClickLogin.bind(this);
    this.handleClickSignup = this.handleClickSignup.bind(this);
    this.state = {
      showLogin: false,
      showSignup: false
    };
  }

  clickLogin(event: React.MouseEvent) {
    event.preventDefault();
    this.setState({
      showLogin: true
    });
  }

  clickSignup(event: React.MouseEvent) {
    event.preventDefault();
    this.setState({
      showSignup: true
    });
  }

  componentDidMount() {
    // Open SignupPopup when clicking group cards
    const signupButtons = $(`a[href$="${this.props.serverRegistrationUrl}"]`);
    signupButtons.on('click', (event) => {
      event.preventDefault();
      this.setState({ showSignup: true });
    });
  }

  handleClickLogin() {
    this.setState({
      showSignup: false,
      showLogin: true
    });
  }

  handleClickSignup() {
    this.setState({
      showSignup: true,
      showLogin: false
    });
  }

  omniauthButtons() {
    const { authenticityToken, facebookUrl, googleUrl } = this.props;
    return (
      <OmniauthButtons authenticityToken={authenticityToken} facebookUrl={facebookUrl} googleUrl={googleUrl} />
    );
  }

  render() {
    const {
      avatars,
      clientLoginUrl,
      clientSignupUrl,
      currentPath,
      forgotPasswordUrl,
      logoUrl,
      rootUrl,
      serverLoginUrl,
      serverRegistrationUrl
    } = this.props;

    return (
      <div className="UnauthenticatedLinks">
        <span><a className={activePath(currentPath, 'what-we-do')} href="">What we do</a></span>
        <span><a className={activePath(currentPath, 'about-us')} href="">About us</a></span>
        <span>
          <a
            className={activePath(currentPath, serverRegistrationUrl)}
            href={serverRegistrationUrl}
            onClick={(event) => this.clickSignup(event)}
          >
            Sign Up
          </a>
        </span>
        <span>
          <a
            className={activePath(currentPath, serverLoginUrl)}
            href={serverLoginUrl}
            onClick={(event) => this.clickLogin(event)}
          >
            Log In
          </a>
        </span>
        {this.state.showLogin && (
          <LoginPopup
            clientLoginUrl={clientLoginUrl}
            forgotPasswordUrl={forgotPasswordUrl}
            logoUrl={logoUrl}
            omniauthButtons={this.omniauthButtons()}
            onClickSignup={this.handleClickSignup}
            onClose={() => this.setState({ showLogin: false })}
            redirectUrl={rootUrl}
          />
        )}
        {this.state.showSignup && (
          <SignupPopup
            avatars={avatars}
            clientSignupUrl={clientSignupUrl}
            logoUrl={logoUrl}
            omniauthButtons={this.omniauthButtons()}
            onClickLogin={this.handleClickLogin}
            onClose={() => this.setState({ showSignup: false })}
            redirectUrl={rootUrl}
          />
        )}
      </div>
    );
  }
}

export default UnauthenticatedLinks;
