import * as React from 'react';
import axios from 'axios';

import Interaction from './Interaction';
import { InteractionElement } from './interfaces';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string;
  defaultAvatarUrl: string;
  flaggedId?: string;
  flagsUrl: string;
  indexUrlServer: string;
  interactions: InteractionElement[];
  likesUrl: string;
  membershipId?: string;
  reportOptions: string[];
  token: string;
  type: string;
}

interface State {
  interactions: InteractionElement[];
}

class ReplyList extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    this.state = {
      interactions: this.props.interactions
    };
  }

  deleteInteraction(id: string) {
    const interaction = this.state.interactions.find((stateInteraction) => stateInteraction.id === id);
    if (!interaction) {
      return;
    }
    axios
      .delete(
        interaction.deleteUrlClient,
        {
          cancelToken: this.signal.token,
          headers: headers(this.props.token)
        }
      )
      .then(() => {
        this.setState({
          interactions: this.state.interactions.map((stateInteraction) => (
            stateInteraction.id === id
              ? { ...stateInteraction, deleted: true }
              : stateInteraction
          ))
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  render() {
    const {
      authenticityToken,
      defaultAvatarUrl,
      flaggedId,
      flagsUrl,
      indexUrlServer,
      likesUrl,
      membershipId,
      reportOptions,
      token,
      type
    } = this.props;
    const { interactions } = this.state;
    return (
      <div className="ReplyList">
        {interactions.length === 0 && membershipId
          ? (
            <div className="paddedBody">
              <h4>There is no recent reply. Be the first!</h4>
            </div>
          )
          : (
            interactions.map((interaction) => (
              <Interaction
                authenticityToken={authenticityToken}
                clientSideDeleteUrl={interaction.deleteUrlClient}
                defaultAvatarUrl={defaultAvatarUrl}
                flaggedId={flaggedId}
                flagsUrl={flagsUrl}
                friendlyName="reply"
                interaction={interaction}
                key={interaction.id}
                likesUrl={likesUrl}
                membershipId={membershipId}
                onClickDeleteButton={() => this.deleteInteraction(interaction.id)}
                redirectUrl={indexUrlServer}
                reportOptions={reportOptions}
                serverSideDeleteUrl={interaction.deleteUrlServer}
                token={token}
                type={type}
              />
            )))}
      </div>
    );
  }
}

export default ReplyList;
