import * as React from 'react';

import Button from './Button';
import images from '../utils/images';

interface Props {
  label: string;
  onChangeLabel: ((value: string) => void);
  onRemoveOption: (() => void);
  onSelectValue: ((value: string) => void);
  optionsCount: number;
}

function SortOptionEditor(props: Props) {
  return (
    <div className="SortOptionEditor">
      <img alt="Drag Handler" src={images.drag} />
      <div className="content">
        <div className="prompt">
          <label>
            Option:
            <input
              onChange={(event) => props.onChangeLabel(event.target.value)}
              placeholder="Choose a label for this option..."
              type="text"
              value={props.label}
            />
          </label>
          <div className="button">
            <Button
              disabled={props.optionsCount <= 2}
              imgAlt="Remove option"
              imgSrc={images.minus}
              onClick={async () => props.onRemoveOption()}
              size="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SortOptionEditor;
