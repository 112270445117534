import * as React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { Member } from './interfaces';

interface Props {
  draggingId: string;
  droppableId: 'enrolments' | 'facilitators' | 'members' | 'moderators';
  label: string;
  members: Member[];
  onSelect: ((selected: string[]) => void);
  selected: string[];
}

class MemberList extends React.Component<Props, {}> {
  click(event: React.MouseEvent, id: string) {
    if (event.defaultPrevented || event.button !== 0) {
      return;
    }

    event.preventDefault();

    const wasCtrlKeyUsed = (clickEvent: React.MouseEvent) => {
      const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
      return isUsingWindows ? clickEvent.ctrlKey : clickEvent.metaKey;
    };

    if (wasCtrlKeyUsed(event)) {
      this.toggleSelectionInGroup(id);
      return;
    }

    if (event.shiftKey) {
      this.multiSelect(id);
      return;
    }

    this.toggleSelection(id);
  }

  multiSelect(id: string) {
    const { members, selected } = this.props;
    const lastSelected = selected[selected.length - 1];

    if (selected.length === 0) {
      this.toggleSelection(id);
      return;
    }

    const indexOfLast = members.findIndex((member) => member.id === lastSelected);
    const indexOfNew = members.findIndex((member) => member.id === id);

    if (indexOfNew === indexOfLast) {
      return;
    }

    const isSelectingForwards = indexOfNew > indexOfLast;
    const start = isSelectingForwards ? indexOfLast : indexOfNew;
    const end = isSelectingForwards ? indexOfNew : indexOfLast;

    this.props.onSelect(members.slice(start, end + 1).map((member) => member.id));
  }

  render() {
    const { draggingId, droppableId, label, members, selected } = this.props;
    return (
      <div className="MemberList">
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div className="container">
              <label>
                {label}{droppableId !== 'members' && `(${members.length})`}
              </label>
              <div className="list" ref={provided.innerRef}>
                {members.map((member: Member, index: number) => (
                  <Draggable
                    draggableId={member.id}
                    index={index}
                    key={member.id}
                  >
                    {(providedDraggable) => {
                      const isSelected = selected.includes(member.id);
                      const isDraggingSelected = isSelected && draggingId !== '' && draggingId !== member.id;
                      return (
                        <div
                          className={`element${isSelected ? ' selected' : ''}${isDraggingSelected ? ' dragging' : ''}`}
                          onClick={(event) => this.click(event, member.id)}
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                        >
                          {member.displayName}
                          {draggingId === member.id && selected.length > 1 && ` and ${selected.length - 1} others`}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  }

  toggleSelection(id: string) {
    this.props.onSelect(this.props.selected.includes(id) ? [] : [id]);
  }

  toggleSelectionInGroup(id: string) {
    const { selected } = this.props;
    this.props.onSelect(selected.includes(id) ? selected.filter((memberId) => memberId !== id) : [...selected, id]);
  }
}

export default MemberList;
