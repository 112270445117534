import * as React from 'react';

import Button from './Button';
import Checkbox from './Checkbox';
import { MembershipQuestion } from './interfaces';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import images from '../utils/images';

interface Props {
  expanded: boolean;
  index: number;
  onClickArrowHead: (() => void);
  onEditingPrompt: (() => void);
  onMouseLeave: (() => void);
  onRemove: (() => void);
  onUpdate: ((question: MembershipQuestion) => void);
  question: MembershipQuestion;
}

interface State {
  error: string;
  question: MembershipQuestion;
}

class MembershipQuestionEditor extends React.Component<Props, State> {
  private readonly textRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.handleClickPlaceholder = this.handleClickPlaceholder.bind(this);
    this.handleClickRemove = this.handleClickRemove.bind(this);
    this.textRef = React.createRef();
    this.state = {
      error: '',
      question: props.question
    };
  }

  changeProperty(value: string | boolean, prop: 'placeholder' | 'prompt' | 'required') {
    const question = { ...this.state.question, [prop]: value };
    const checkError = () => {
      if (question.prompt.length < 2) {
        return 'Prompt must be at least 2 characters long.';
      }
      return '';
    };
    const error = checkError();
    this.setState({
      error,
      question
    }, () => this.props.onUpdate(this.state.question));
  }

  handleClickPlaceholder() {
    this.props.onEditingPrompt();
    if (this.textRef.current === document.activeElement) {
      return;
    }
    this.textRef.current?.select();
  }

  async handleClickRemove() {
    if (confirm('Are you sure you want to remove this question?')) {
      this.props.onRemove();
    }
  }

  render() {
    const { error, question } = this.state;
    const { expanded, onClickArrowHead, onEditingPrompt, onMouseLeave } = this.props;
    const { placeholder, prompt, required } = question;
    return (
      <div className="MembershipQuestionEditor">
        <div className="header">
          <div className="caret">
            <Button
              imgAlt="Expand/Collapse"
              imgSrc={images.arrowHeadRight}
              onClick={async () => onClickArrowHead()}
              rotated={expanded}
              size="small"
            />
          </div>
          <TitleEditor
            onEditing={onEditingPrompt}
            onUpdateTitle={(value: string) => this.changeProperty(value, 'prompt')}
            showErrorIcon={prompt.trim().length < 2}
            title={prompt}
          />
          <div className="button">
            <Button
              imgAlt="Remove question"
              imgSrc={images.crossBox}
              onClick={this.handleClickRemove}
              size="large"
            />
          </div>
        </div>
        {expanded && (
          <div className="body">
            <label>
              <span>Required:</span>
              <Checkbox
                checked={required || false}
                onClick={() => this.changeProperty(!required, 'required')}
              />
            </label>
            <label>
              <span>Placeholder:</span>
              <input
                defaultValue={placeholder}
                onChange={(event) => this.changeProperty(event.target.value, 'placeholder')}
                onClick={this.handleClickPlaceholder}
                onMouseEnter={onEditingPrompt}
                onMouseLeave={onMouseLeave}
                ref={this.textRef}
                type="text"
              />
            </label>
          </div>
        )}
        {error.length > 0 && (
          <div className="error">
            <ValidationErrors messages={[error]} />
          </div>
        )}
      </div>
    );
  }
}

export default MembershipQuestionEditor;
