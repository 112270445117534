import * as React from 'react';
import isBlank from 'utils/isBlank';

import { Link } from './interfaces';

interface Props {
  currentLink?: Link;
  onCancel: (() => void);
  onSubmit: ((link: Link) => void);
}

interface State {
  link: Link;
}

class LinkPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      link: props.currentLink || { id: '', title: '', url: '' }
    };
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    this.props.onSubmit(this.state.link);
  }

  render() {
    const { link } = this.state;
    const { currentLink, onCancel } = this.props;
    return (
      <div className="LinkPopup">
        <div className="content">
          <form onSubmit={this.handleSubmit}>
            <div className="body">
              <label>URL</label>
              <input
                onChange={(event) => this.setState({ link: { ...link, url: event.target.value } })}
                placeholder="Enter a URL..."
                type="text"
                value={link.url}
              />
              <label>Title (optional)</label>
              <input
                onChange={(event) => this.setState({ link: { ...link, title: event.target.value } })}
                placeholder="Enter a title..."
                type="text"
                value={link.title}
              />
            </div>
            <div className="footer">
              <a onClick={onCancel}>Cancel</a>
              <button
                disabled={isBlank(link.url)}
                onClick={this.handleSubmit}
                type="submit"
              >
                {currentLink?.id ? 'Update Link' : 'Add Link'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default LinkPopup;
