import * as React from 'react';
import axios from 'axios';

import headers from '../utils/headersGenerator';

interface Option {
  content: string;
  id: number;
}

interface Props {
  groups: Surveyable[];
  lessons: Surveyable[];
  postUrl: string;
  showSurveyUrl: string;
  siteId: string;
  surveyId: string;
  surveyTitle: string;
  surveys: Surveyable[];
  token: string;
}

interface State {
  conditionParams: { unit: string, value: '' | number };
  optionId: number;
  surveyableId: string;
}

interface Surveyable {
  id: string;
  name: string;
}

const options: Option[] = [
  { content: 'When joining the site', id: 1 },
  { content: 'After being a site user for certain period of time', id: 2 },
  { content: 'When joining a group', id: 3 },
  { content: 'After being a group member for certain period of time', id: 4 },
  { content: 'When starting a lesson', id: 5 },
  { content: 'When completing a lesson', id: 6 },
  { content: 'When completing a survey', id: 7 }
];

class SurveyConditionCreator extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.state = {
      conditionParams: { unit: '', value: '' },
      optionId: 0,
      surveyableId: ''
    };
  }

  handleChangeNumberInput(inputValue: string) {
    const parsedValue = parseInt(inputValue, 10);
    const value = isNaN(parsedValue) ? '' : parsedValue;
    this.setState({
      conditionParams: { ...this.state.conditionParams, value }
    });
  }

  handleSubmit(condition: string, surveyableId: string, surveyableType: string) {
    const { postUrl, showSurveyUrl, surveyId, token } = this.props;
    const { conditionParams } = this.state;

    axios
      .post(
        postUrl,
        {
          surveyCondition: {
            condition,
            conditionParams,
            surveyableId,
            surveyableType,
            surveyId
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        window.location.href = `${showSurveyUrl}?notice=Condition created successfully`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          window.location.href = `${showSurveyUrl}?alert=${error.response.data.messages}`;
        }
      });
  }

  render() {
    const { optionId } = this.state;
    return (
      <div className="SurveyConditionCreator">
        {optionId === 0 ? this.renderOptions() : this.renderForm()}
      </div>
    );
  }

  renderAfterGroupPeriod() {
    const { groups, surveyTitle } = this.props;
    const { conditionParams, surveyableId } = this.state;
    const groupName = groups.find((group) => group.id === surveyableId)?.name;

    return (
      <>
        <div className="body">
          {this.renderTitle(4)}
          <div className="selectsContainer">
            <div className="selectDescription">Select group</div>
            {this.renderSurveyableSelect(groups)}
            {this.renderTimeSelect()}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users after
            being {groupName === undefined ? 'SELECT' : groupName} group member
            for {conditionParams.value === '' ? 'SELECT' : conditionParams.value}
            {conditionParams.unit === '' ? ' SELECT' : ` ${conditionParams.unit}`}.
          </div>
          <button
            disabled={surveyableId === '' || conditionParams.value === '' || conditionParams.unit === ''}
            onClick={() => this.handleSubmit('member_for', surveyableId, 'Group')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterJoinGroup() {
    const { groups, surveyTitle } = this.props;
    const { surveyableId } = this.state;
    const groupName = groups.find((group) => group.id === surveyableId)?.name;

    return (
      <>
        <div className="body">
          {this.renderTitle(3)}
          <div className="selectsContainer">
            <div className="selectDescription">Select group</div>
            {this.renderSurveyableSelect(groups)}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users when
            joining {groupName === undefined ? 'SELECT' : groupName} group.
          </div>
          <button
            disabled={surveyableId === ''}
            onClick={() => this.handleSubmit('join', surveyableId, 'Group')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterLessonComplete() {
    const { lessons, surveyTitle } = this.props;
    const { surveyableId } = this.state;
    const lessonName = lessons.find((lesson) => lesson.id === surveyableId)?.name;

    return (
      <>
        <div className="body">
          {this.renderTitle(6)}
          <div className="selectsContainer">
            <div className="selectDescription">Select lesson template</div>
            {this.renderSurveyableSelect(lessons)}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users when completing
            a lesson based on the lesson template {lessonName === undefined ? 'SELECT' : lessonName}.
          </div>
          <button
            disabled={surveyableId === ''}
            onClick={() => this.handleSubmit('complete', surveyableId, 'Lesson')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterLessonStart() {
    const { lessons, surveyTitle } = this.props;
    const { surveyableId } = this.state;
    const lessonName = lessons.find((lesson) => lesson.id === surveyableId)?.name;

    return (
      <>
        <div className="body">
          {this.renderTitle(5)}
          <div className="selectsContainer">
            <div className="selectDescription">Select lesson template</div>
            {this.renderSurveyableSelect(lessons)}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users when starting
            a lesson based on the lesson template {lessonName === undefined ? 'SELECT' : lessonName}.
          </div>
          <button
            disabled={surveyableId === ''}
            onClick={() => this.handleSubmit('start', surveyableId, 'Lesson')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterLogin() {
    const { siteId, surveyTitle } = this.props;
    return (
      <>
        <div className="body">
          {this.renderTitle(1)}
        </div>
        <div className="footer">
          <div className="resume">{surveyTitle} survey will be released for the users when joining the site.</div>
          <button onClick={() => this.handleSubmit('join', siteId, 'Site')} type="submit">
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterSitePeriod() {
    const { siteId, surveyTitle } = this.props;
    const { conditionParams } = this.state;
    return (
      <>
        <div className="body">
          {this.renderTitle(2)}
          <div className="selectsContainer">
            {this.renderTimeSelect()}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users after being a site member
            for {conditionParams.value === '' ? 'SELECT' : conditionParams.value}
            {conditionParams.unit === '' ? ' SELECT' : ` ${conditionParams.unit}`}.
          </div>
          <button
            disabled={conditionParams.value === '' || conditionParams.unit === ''}
            onClick={() => this.handleSubmit('member_for', siteId, 'Site')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderAfterSurveyComplete() {
    const { surveys, surveyTitle } = this.props;
    const { surveyableId } = this.state;
    const surveyName = surveys.find((survey) => survey.id === surveyableId)?.name;

    return (
      <>
        <div className="body">
          {this.renderTitle(6)}
          <div className="selectsContainer">
            <div className="selectDescription">Select survey</div>
            {this.renderSurveyableSelect(surveys)}
          </div>
        </div>
        <div className="footer">
          <div className="resume">
            {surveyTitle} survey will be released for the users when completing
            the survey {surveyName === undefined ? 'SELECT' : surveyName}.
          </div>
          <button
            disabled={surveyableId === ''}
            onClick={() => this.handleSubmit('complete', surveyableId, 'Survey')}
            type="submit"
          >
            Confirm
          </button>
        </div>
      </>
    );
  }

  renderForm() {
    switch (this.state.optionId) {
      case 1:
        return this.renderAfterLogin();
      case 2:
        return this.renderAfterSitePeriod();
      case 3:
        return this.renderAfterJoinGroup();
      case 4:
        return this.renderAfterGroupPeriod();
      case 5:
        return this.renderAfterLessonStart();
      case 6:
        return this.renderAfterLessonComplete();
      case 7:
        return this.renderAfterSurveyComplete();
      default:
        return (<div>Please refresh the page</div>);
    }
  }

  renderOptions() {
    return (
      <>
        <h3>When do you want the {this.props.surveyTitle} survey to be released?</h3>
        <div className="options">
          {options.map((option) => (
            <button key={option.id} onClick={() => this.setState({ optionId: option.id })} type="button">
              {option.content}
            </button>
          ))}
        </div>
      </>
    );
  }

  renderSurveyableSelect(surveyables: Surveyable[]) {
    return (
      <select onChange={(event) => this.setState({ surveyableId: event.currentTarget.value })}>
        <option defaultValue="" value="">Select</option>
        {surveyables.map((surveyable) => (
          <option key={surveyable.id} value={surveyable.id}>{surveyable.name}</option>
        ))}
      </select>
    );
  }

  renderTimeSelect() {
    const { conditionParams } = this.state;
    return (
      <>
        <div className="selectDescription">Specify when to release the survey</div>
        <input
          min="1"
          onChange={(event) => this.handleChangeNumberInput(event.target.value)}
          placeholder="15"
          type="number"
          value={conditionParams.value}
        />
        <select onChange={(event) => this.setState({ conditionParams: { ...conditionParams, unit: event.currentTarget.value } })}>
          <option defaultValue="" value="">Select</option>
          <option value="days">Days</option>
          <option value="months">Months</option>
          <option value="years">Years</option>
        </select>
      </>
    );
  }

  renderTitle(optionId: number) {
    return (
      <div className="title">
        <button
          className="goBack"
          onClick={() => this.setState(
            {
              conditionParams: { unit: '', value: '' },
              optionId: 0,
              surveyableId: ''
            }
          )}
          type="button"
        >
          &#x3c;
        </button>
        <h3>{options.find((option) => option.id === optionId)?.content}</h3>
      </div>
    );
  }
}

export default SurveyConditionCreator;
