import * as React from 'react';

import images from '../utils/images';

interface Props {
  authenticityToken: string;
  facebookUrl: string;
  googleUrl: string;
}

function OmniauthButtons(props: Props) {
  const { authenticityToken, facebookUrl, googleUrl } = props;
  return (
    <div className="OmniauthButtons">
      <form action={facebookUrl} className="omniauthForm" method="post">
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <button className="submitButton facebook" type="submit">
          <img alt="Continue with Facebook" src={images.facebook} />
          <div className="title">
            Continue with Facebook
          </div>
        </button>
      </form>
      <form action={googleUrl} className="omniauthForm" method="post">
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <button className="submitButton google" type="submit">
          <img alt="Continue with Google" src={images.google} />
          <div className="title">
            Continue with Google
          </div>
        </button>
      </form>
    </div>
  );
}

export default OmniauthButtons;
