import arrowHeadRight from '../images/arrow-head-right.svg';
import checkbox from '../images/checkbox.svg';
import close from '../images/close.svg';
import crossBox from '../images/cross-box.svg';
import drag from '../images/drag.svg';
import edit from '../images/pencil.svg';
import envelope from '../images/envelope.svg';
import error from '../images/error.svg';
import facebook from '../images/facebook.svg';
import flag from '../images/flag.svg';
import flagActive from '../images/flag-blue.svg';
import google from '../images/google.svg';
import minus from '../images/minus.svg';
import plus from '../images/plus.svg';
import thumbsUp from '../images/thumbs-up.svg';
import thumbsUpActive from '../images/thumbs-up-blue.svg';
import tickBox from '../images/tick-box.svg';
import trash from '../images/trash.svg';

const images = {
  arrowHeadRight,
  checkbox,
  close,
  crossBox,
  drag,
  edit,
  envelope,
  error,
  facebook,
  flag,
  flagActive,
  google,
  minus,
  plus,
  thumbsUp,
  thumbsUpActive,
  tickBox,
  trash
};

export default images;
