import { v1 } from 'uuid';

function nonce(previous?: string): string {
  for (;;) {
    const candidate = v1();
    if (candidate !== previous) {
      return candidate;
    }
  }
}

export default nonce;
