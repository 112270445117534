import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import {
  FloatQuestion,
  FreeformQuestion,
  IntegerQuestion,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
  Option,
  Question,
  QuestionType,
  SortableOption
} from './interfaces';
import Button from './Button';
import Checkbox from './Checkbox';
import MultipleChoiceEditor from './MultipleChoiceEditor';
import SortOptionEditor from './SortOptionEditor';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import getDraggableStyle from '../utils/getDraggableStyle';
import images from '../utils/images';
import reorder from '../utils/reorder';

interface Props {
  dependencyQuestion?: Question;
  dependentQuestions: Question[];
  expanded: boolean;
  multipleChoiceQuestions: Question[];
  onChangeProperty: ((value: boolean | number | Option[] | string, property: string) => void);
  onChangeType: ((type: string) => void);
  onClickArrowHead: (() => void);
  onEditingTitle: (() => void);
  onMouseEnter: (() => void);
  onMouseLeave: (() => void);
  onRemoveDependencyOption: ((id: string) => void);
  onRemoveQuestion: (() => Promise<void>);
  question: Question;
  questionErrorMessages: string[];
  questionnaireType: 'quiz' | 'survey';
}

interface State {
  currentMultipleChoiceOptions?: MultipleChoiceOption[];
  currentSortOptions?: SortableOption[];
  provisionalDependency?: Question;
}

type NumberProperty = 'correct' | 'maxValue' | 'minValue';

class QuestionEditor extends React.Component<Props, State> {
  private selectOption: React.RefObject<HTMLSelectElement>;

  constructor(props: Props) {
    super(props);
    this.selectOption = React.createRef();
    this.handleClickFreeform = this.handleClickFreeform.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    const { question } = this.props;
    this.state = {
      currentMultipleChoiceOptions: question.type === QuestionType.MultipleChoice ? question.multipleChoiceOptions : undefined,
      currentSortOptions: question.type === QuestionType.Sort ? question.sortOptions : undefined,
      provisionalDependency: undefined
    };
  }

  async addMultipleChoiceOption({ freeform = false }) {
    const { currentMultipleChoiceOptions } = this.state;

    if (!currentMultipleChoiceOptions) {
      return;
    }

    if (currentMultipleChoiceOptions.length >= 8) {
      alert('You can not add more than 8 options');
      return;
    }

    const label = freeform ? 'Freeform Prompt' : `Option #${currentMultipleChoiceOptions.length + 1}`;

    const newOption = { freeform, id: uuidv4(), label };

    if (this.props.questionnaireType === 'quiz') {
      Object.assign(newOption, { correct: false });
    }

    const options = [...currentMultipleChoiceOptions, newOption];
    this.setState({
      currentMultipleChoiceOptions: options
    }, () => options && this.props.onChangeProperty(options, 'multipleChoiceOptions'));
  }

  async addSortOption() {
    const { currentSortOptions } = this.state;

    if (!currentSortOptions) {
      return;
    }

    if (currentSortOptions.length >= 8) {
      alert('You can not add more than 8 options');
      return;
    }

    const newOption = { id: uuidv4(), label: `Option #${currentSortOptions.length + 1}` };
    const options = [...currentSortOptions, newOption];

    this.setState({
      currentSortOptions: options
    }, () => options && this.props.onChangeProperty(options, 'sortOptions'));
  }

  changeDependencyOption(value: string) {
    this.props.onChangeProperty(value, 'dependantOf');
    this.setState({
      provisionalDependency: undefined
    });
  }

  changeDependencyQuestion(value: string) {
    const { current } = this.selectOption;
    this.props.onChangeProperty('', 'dependantOf');
    if (value === '') {
      this.setState({
        provisionalDependency: undefined
      });
    } else {
      this.setState({
        provisionalDependency: this.props.multipleChoiceQuestions.find((question) => question.id === value)
      });

      if (current) {
        current.focus();
      }
    }
  }

  changeLabel(id: string, value: string) {
    const { currentMultipleChoiceOptions, currentSortOptions } = this.state;
    const { onChangeProperty, question } = this.props;
    if (question.type === QuestionType.MultipleChoice) {
      const options = currentMultipleChoiceOptions?.map((option) => option.id === id ? { ...option, label: value } : option);
      this.setState({
        currentMultipleChoiceOptions: options
      }, () => options && onChangeProperty(options, 'multipleChoiceOptions'));
    } else {
      const options = currentSortOptions?.map((option) => option.id === id ? { ...option, label: value } : option);
      this.setState({
        currentSortOptions: options
      }, () => options && onChangeProperty(options, 'sortOptions'));
    }
  }

  changeNumber(value: string, property: NumberProperty, type: QuestionType.Float | QuestionType.Integer) {
    const floatRegex = /^\d+\.?\d{0,2}$/;
    const integerRegex = /^\d+$/;
    if (value.length >= 7) {
      return false;
    }

    if (value === '') {
      return this.props.onChangeProperty('', property);
    }

    if (type === QuestionType.Integer && integerRegex.test(value)) {
      return this.props.onChangeProperty(value, property);
    }

    if (type === QuestionType.Float && floatRegex.test(value)) {
      return this.props.onChangeProperty(value, property);
    }
    return false;
  }

  changeSelectedValue(id: string, value: string) {
    const { currentMultipleChoiceOptions } = this.state;

    const optionExists = currentMultipleChoiceOptions?.find((option) => option.id === id);
    if (!optionExists) {
      console.error(`Option missing: ${id}`);
      return;
    }

    const options = currentMultipleChoiceOptions?.map((option) => option.id === id ? { ...option, correct: value === 'correct' } : option);

    this.setState({
      currentMultipleChoiceOptions: options
    }, () => options && this.props.onChangeProperty(options, 'multipleChoiceOptions'));
  }

  componentDidUpdate(prevProps: Props) {
    const { question } = this.props;
    const { type } = question;
    if (type === QuestionType.MultipleChoice && question !== prevProps.question) {
      this.setState({
        currentMultipleChoiceOptions: question.multipleChoiceOptions
      });
    }
    if (type === QuestionType.Sort && question !== prevProps.question) {
      this.setState({
        currentSortOptions: question.sortOptions
      });
    }
  }

  handleClickFreeform() {
    const { currentMultipleChoiceOptions } = this.state;
    const freeformOption = currentMultipleChoiceOptions?.find((option) => option.freeform);
    if (freeformOption) {
      const options = currentMultipleChoiceOptions?.filter((option) => option.id !== freeformOption.id);
      if (!options) {
        return;
      }
      this.setState({
        currentMultipleChoiceOptions: options
      }, () => this.props.onChangeProperty(options, 'multipleChoiceOptions'));
    } else {
      this.addMultipleChoiceOption({ freeform: true });
    }
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    const { currentMultipleChoiceOptions, currentSortOptions } = this.state;

    if (currentSortOptions) {
      const options = reorder(currentSortOptions, result.source.index, result.destination.index);
      this.setState({
        currentSortOptions: options
      }, () => options && this.props.onChangeProperty(options, 'sortOptions'));
    } else if (currentMultipleChoiceOptions) {
      const optionsWithoutFreeform = currentMultipleChoiceOptions.filter((option) => !option.freeform);
      const freeformOption = currentMultipleChoiceOptions?.find((option) => option.freeform) || [];
      const options = reorder(optionsWithoutFreeform, result.source.index, result.destination.index).concat(freeformOption);

      this.setState({
        currentMultipleChoiceOptions: options
      }, () => options && this.props.onChangeProperty(options, 'multipleChoiceOptions'));
    }
  }

  parseNumber(value: string, property: NumberProperty) {
    if (property === 'correct' && value === '') {
      return false;
    }

    const parsedNumber = Number(value);
    return !isNaN(parsedNumber) && this.props.onChangeProperty(parsedNumber, property);
  }

  removeOption(id: string) {
    const { dependentQuestions, onChangeProperty, onRemoveDependencyOption } = this.props;
    const { currentMultipleChoiceOptions, currentSortOptions } = this.state;
    const dependentQuestion = dependentQuestions.find((question) => question.dependantOf === id);
    const confirmationPrompt = 'At least one other question depends on this option.'
      + ' Deleting this option will cause those questions to always be shown.\n\nAre you sure you want to delete this option?';
    if (dependentQuestion) {
      if (!confirm(confirmationPrompt)) {
        return;
      }
      onRemoveDependencyOption(id);
    }
    if (currentSortOptions) {
      const sortOptions = currentSortOptions.filter((option) => option.id !== id);
      this.setState({
        currentSortOptions: sortOptions
      }, () => sortOptions && onChangeProperty(sortOptions, 'sortOptions'));
    } else {
      const options = currentMultipleChoiceOptions?.filter((option) => option.id !== id);
      this.setState({
        currentMultipleChoiceOptions: options
      }, () => options && onChangeProperty(options, 'multipleChoiceOptions'));
    }
  }

  render() {
    const {
      expanded,
      onClickArrowHead,
      onChangeProperty,
      onEditingTitle,
      onMouseEnter,
      onMouseLeave,
      onRemoveQuestion,
      question,
      questionErrorMessages,
      questionnaireType
    } = this.props;
    const { dependantOf, id, prompt, required, type } = question;
    const dependencyQuestion = this.state.provisionalDependency || this.props.dependencyQuestion;
    return (
      <div className="QuestionEditor">
        <div className="header">
          <div className="caret">
            <Button
              imgAlt="Expand/Collapse"
              imgSrc={images.arrowHeadRight}
              onClick={async () => onClickArrowHead()}
              rotated={expanded}
              size="small"
            />
          </div>
          <TitleEditor
            onEditing={() => onEditingTitle()}
            onUpdateTitle={(value: string) => onChangeProperty(value, 'prompt')}
            showErrorIcon={questionErrorMessages.length > 0}
            title={prompt}
          />
          <div className="button">
            <Button
              imgAlt="Remove question"
              imgSrc={images.crossBox}
              onClick={onRemoveQuestion}
              size="large"
            />
          </div>
        </div>
        {expanded && (
          <div className="content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="configuration">
              {questionnaireType === 'survey' && (
                <>
                  <label>
                    <span>Depends on Question:</span>
                    <select onChange={(event) => this.changeDependencyQuestion(event.target.value)} value={dependencyQuestion?.id || ''}>
                      <option value="">None</option>
                      {this.renderDependencyQuestions(id)}
                    </select>
                  </label>
                  <label>
                    <span>Depends on Option:</span>
                    <select
                      onChange={(event) => this.changeDependencyOption(event.target.value)}
                      ref={this.selectOption}
                      required={dependencyQuestion?.type === QuestionType.MultipleChoice
                        && dependencyQuestion?.multipleChoiceOptions && dependencyQuestion.multipleChoiceOptions.length > 0}
                      value={dependantOf}
                    >
                      {dependencyQuestion?.type === QuestionType.MultipleChoice && dependencyQuestion?.multipleChoiceOptions
                        ? (
                          <>
                            <option value="">Select Option</option>
                            {this.renderDependencyOptions(dependencyQuestion.multipleChoiceOptions)}
                          </>
                        )
                        : <option value="">Select Question</option>
                      }
                    </select>
                  </label>
                </>
              )}
              <label>
                <span>Question Type:</span>
                <select
                  onChange={(event) => this.props.onChangeType(event.target.value)}
                  value={type}
                >
                  <option value="float">Float</option>
                  <option value="freeform">Freeform</option>
                  <option value="integer">Integer</option>
                  <option value="multiple-choice">Multiple-choice</option>
                  <option value="sort">Sort</option>
                </select>
              </label>
              {questionnaireType === 'survey' && (
                <label>
                  <span>Required:</span>
                  <Checkbox
                    checked={required || false}
                    onClick={() => onChangeProperty(!required, 'required')}
                  />
                </label>
              )}
              {(type === QuestionType.Float || type === QuestionType.Integer) && this.renderNumber(question)}
              {type === QuestionType.Freeform && this.renderFreeform(question)}
              {type === QuestionType.MultipleChoice && this.renderMultipleChoice(question)}
              {type === QuestionType.Sort && this.renderSort()}
            </div>
            {questionErrorMessages.length > 0 && (
              <div className="footer">
                <ValidationErrors messages={questionErrorMessages} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderDependencyOptions(dependencyOptions: MultipleChoiceOption[]) {
    return (dependencyOptions.reduce((options: JSX.Element[], option) => {
      if (!option.freeform) {
        options.push(<option key={option.id} value={option.id}>{option.label}</option>);
      }
      return options;
    }, []));
  }

  renderDependencyQuestions(id: string) {
    return (this.props.multipleChoiceQuestions.reduce((options: JSX.Element[], question) => {
      if (question.id !== id) {
        options.push(<option key={question.id} value={question.id}>{question.prompt}</option>);
      }
      return options;
    }, []));
  }

  renderFreeform(question: FreeformQuestion) {
    const { correct, placeholder } = question;
    return (
      <>
        <label>
          <span>Placeholder:</span>
          <input
            onChange={(event) => this.props.onChangeProperty(event.target.value, 'placeholder')}
            type="text"
            value={placeholder}
          />
        </label>
        {this.props.questionnaireType === 'quiz' && (
          <label>
            <span>Correct:</span>
            <input
              onChange={(event) => this.props.onChangeProperty(event.target.value, 'correct')}
              type="text"
              value={correct || ''}
            />
          </label>
        )}
      </>
    );
  }

  renderFreeformOption(option: MultipleChoiceOption) {
    return (
      <div className="freeformOption">
        <label>
          Freeform prompt:
          <input
            onChange={(event) => this.changeLabel(option.id, event.target.value)}
            placeholder="Explain how to answer the question..."
            type="text"
            value={option.label}
          />
        </label>
      </div>
    );
  }

  renderMultipleChoice(question: MultipleChoiceQuestion) {
    const { currentMultipleChoiceOptions } = this.state;

    if (!currentMultipleChoiceOptions) {
      return '';
    }

    return (
      <>
        <label>
          <span>Freeform</span>
          <Checkbox
            checked={currentMultipleChoiceOptions?.some((option) => option.freeform) || false}
            onClick={this.handleClickFreeform}
          />
        </label>
        <label>
          <span>Shuffle</span>
          <Checkbox
            checked={question.shuffle || false}
            onClick={() => this.props.onChangeProperty(!question.shuffle, 'shuffle')}
          />
        </label>
        <label>
          <span>Choose up to:</span>
          <select
            onChange={(event) => this.props.onChangeProperty(parseInt(event.target.value, 10), 'maxSelectable')}
            value={question.maxSelectable}
          >
            {currentMultipleChoiceOptions?.map((option, index) => <option key={option.id} value={index + 1}>{index + 1}</option>)}
          </select>
        </label>
        {this.renderOptions()}
        <div className="footer">
          <Button
            imgAlt="Add option"
            imgSrc={images.plus}
            onClick={() => this.addMultipleChoiceOption({ freeform: false })}
            size="medium"
          />
        </div>
      </>
    );
  }

  renderMultipleChoiceOption(option: MultipleChoiceOption) {
    return (
      <MultipleChoiceEditor
        onChangeLabel={(value) => this.changeLabel(option.id, value)}
        onRemoveOption={() => this.removeOption(option.id)}
        onSelectValue={(value) => this.changeSelectedValue(option.id, value)}
        option={option}
        optionsCount={this.state.currentMultipleChoiceOptions?.length || 0}
        questionnaireType={this.props.questionnaireType}
      />
    );
  }

  renderNumber(question: FloatQuestion | IntegerQuestion) {
    const { correct, maxValue, minValue, type } = question;
    return (
      <>
        <label>
          <span>Max value:</span>
          <input
            min="0"
            onBlur={(event) => this.parseNumber(event.target.value, 'maxValue')}
            onChange={(event) => this.changeNumber(event.target.value, 'maxValue', type)}
            type="text"
            value={maxValue}
          />
        </label>
        <label>
          <span>Min value:</span>
          <input
            min="0"
            onBlur={(event) => this.parseNumber(event.target.value, 'minValue')}
            onChange={(event) => this.changeNumber(event.target.value, 'minValue', type)}
            type="text"
            value={minValue}
          />
        </label>
        {this.props.questionnaireType === 'quiz' && (
          <label>
            <span>Correct:</span>
            <input
              onBlur={(event) => this.parseNumber(event.target.value, 'correct')}
              onChange={(event) => this.changeNumber(event.target.value, 'correct', type)}
              placeholder="10"
              type="text"
              value={correct === undefined ? '' : correct}
            />
          </label>
        )}
      </>
    );
  }

  renderOptions() {
    const { type } = this.props.question;
    const { currentMultipleChoiceOptions, currentSortOptions } = this.state;
    const freeformOption = currentMultipleChoiceOptions?.find((option) => option.freeform);

    const options = type === QuestionType.Sort
      ? currentSortOptions
      : currentMultipleChoiceOptions?.filter((option) => !option.freeform);
    if (!options) {
      return '';
    }

    return (
      <>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="QuestionEditor">
            {(providedDroppable) => (
              <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                {options.map((option, index) => (
                  <Draggable
                    draggableId={option.id}
                    index={index}
                    key={option.id}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.draggableProps}
                        ref={providedDraggable.innerRef}
                        style={getDraggableStyle(providedDraggable.draggableProps.style)}
                      >
                        <div {...providedDraggable.dragHandleProps}>
                          {type === QuestionType.Sort
                            ? this.renderSortOption(option)
                            : this.renderMultipleChoiceOption(option)}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {freeformOption && this.renderFreeformOption(freeformOption)}
      </>
    );
  }

  renderSort() {
    return (
      <>
        <p>Please sort the options in the correct order. The answers will be displayed randomly.</p>
        {this.renderOptions()}
        <div className="footer">
          <Button
            imgAlt="Add option"
            imgSrc={images.plus}
            onClick={() => this.addSortOption()}
            size="medium"
          />
        </div>
      </>
    );
  }

  renderSortOption(option: SortableOption) {
    return (
      <SortOptionEditor
        label={option.label}
        onChangeLabel={(value) => this.changeLabel(option.id, value)}
        onRemoveOption={() => this.removeOption(option.id)}
        onSelectValue={(value) => this.changeSelectedValue(option.id, value)}
        optionsCount={this.state.currentSortOptions?.length || 0}
      />
    );
  }
}

export default QuestionEditor;
