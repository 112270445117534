import * as React from 'react';

import DefaultAvatar from './DefaultAvatar';
import { DefaultAvatarInterface } from './interfaces';

interface Props {
  customAvatarAllowed: boolean;
  defaultAvatars: DefaultAvatarInterface[];
  hasAvatar: boolean;
  onCancel: (() => void);
  onRemove: (() => void);
  onSelect: ((defaultAvatar: DefaultAvatarInterface) => void);
}

const label = (props: Props) => {
  if (props.customAvatarAllowed) {
    return ' or upload your own';
  }
  if (props.hasAvatar) {
    return ' or remove current';
  }
  return '';
};

function DefaultAvatarList(props: Props) {
  return (
    <div className="DefaultAvatarList">
      <label>{`Select a default avatar${label(props)}`}</label>
      <div className="body">
        <div className={`list ${props.defaultAvatars.length < 4 ? 'centered' : ''}`}>
          {props.defaultAvatars.map((avatar, index) => (
            <DefaultAvatar
              imgAlt={`Default avatar #${index}`}
              imgSrc={avatar.url}
              key={avatar.path}
              onClick={() => props.onSelect(avatar)}
            />
          ))}
        </div>
      </div>
      {!props.customAvatarAllowed && (
        <div className="footer">
          <a onClick={props.onCancel}>
            Cancel
          </a>
          {props.hasAvatar && (
            <button
              onClick={props.onRemove}
              type="button"
            >
              Remove Avatar
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default DefaultAvatarList;
