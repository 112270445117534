import * as React from 'react';

interface Props {
  imgAlt: string;
  imgSrc: string;
  onClick: (() => void);
}

function DefaultAvatar(props: Props) {
  return (
    <div className="DefaultAvatar">
      <button
        onClick={props.onClick}
        type="button"
      >
        <img
          alt={props.imgAlt}
          height="105"
          src={props.imgSrc}
          width="105"
        />
      </button>
    </div>
  );
}

export default DefaultAvatar;
