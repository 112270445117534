import * as React from 'react';

import LessonBlockDescription from './LessonBlockDescription';
import isBlank from '../utils/isBlank';

interface Props {
  description?: string;
  onSubmitVideo: ((newContent: { videoSrc: string }) => void);
  onUpdateDescription: ((description: string) => void);
  title: string;
  videoSrc: string;
}

interface State {
  value: string;
}

class LessonVideoEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      value: this.props.videoSrc
    };
  }

  handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const matchedCode = this.state.value.match(/src\s*=\s*"([^"]+)"/);

    if (matchedCode) {
      this.props.onSubmitVideo({ videoSrc: matchedCode[1] });
      this.setState({ value: matchedCode[1] });
    } else {
      alert('Please insert a valid embed code.');
    }
  }

  render() {
    const { description, onUpdateDescription, title, videoSrc } = this.props;
    const { value } = this.state;
    return (
      <div className="LessonVideoEditor">
        <LessonBlockDescription onUpdate={onUpdateDescription} value={description} />
        <form onSubmit={this.handleSubmit}>
          <label>Vimeo or YouTube embed video code:</label>
          <small>To get the embed code go to share video and select embed.</small>
          <textarea
            name="videoSrc"
            onChange={(event) => this.setState({ value: event.target.value })}
            onClick={(event) => event.currentTarget !== document.activeElement && event.currentTarget.focus()}
            value={value}
          />
          <input disabled={isBlank(value)} type="submit" />
        </form>
        {videoSrc && (
          <iframe
            allow="autoplay; fullscreen"
            className="preview"
            frameBorder="0"
            height="360"
            src={videoSrc}
            title={title}
            width="640"
          />
        )}
      </div>
    );
  }
}

export default LessonVideoEditor;
