import * as React from 'react';

import RenderHTML from './RenderHTML';

interface Props {
  content: string;
  onClose: () => void;
}

function LessonPreview(props: Props) {
  return (
    <div className="LessonPreview">
      <h1>Lesson Preview</h1>
      <RenderHTML content={props.content} />
      <div className="footer">
        <button onClick={props.onClose} type="button">
          Close Preview
        </button>
      </div>
    </div>
  );
}

export default LessonPreview;
