import * as React from 'react';

interface Props {
  onUpdate: ((description: string) => void);
  value?: string;
}

function LessonBlockDescription(props: Props) {
  return (
    <div className="LessonBlockDescription">
      <label>Description</label>
      <textarea
        onChange={(event) => props.onUpdate(event.target.value)}
        onClick={(event) => event.currentTarget !== document.activeElement && event.currentTarget.focus()}
        placeholder="Optional description..."
        value={props.value || ''}
      />
    </div>
  );
}

export default LessonBlockDescription;
