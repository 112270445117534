import * as React from 'react';
import axios from 'axios';

import PopupLayout from './PopupLayout';
import ReasonPopup from './ReasonPopup';
import headers from '../utils/headersGenerator';

interface Props {
  editMembershipRequestUrl: string;
  membershipRequestUrl: string;
  redirectUrl: string;
  token: string;
}

interface State {
  showPopup: boolean;
}

class RejectButton extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);

    this.state = {
      showPopup: false
    };
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  render() {
    return (
      <div className="RejectButton">
        <a
          className="button"
          href={this.props.editMembershipRequestUrl}
          onClick={(event) => {
            event.preventDefault();
            this.setState({ showPopup: true });
          }}
        >
          Reject
        </a>
        {this.state.showPopup && this.renderPopup()}
      </div>
    );
  }

  renderPopup() {
    return (
      <PopupLayout
        onClose={() => this.setState({ showPopup: false })}
        title="Reject Membership Request"
      >
        <ReasonPopup
          label="Reason"
          onCancel={() => this.setState({ showPopup: false })}
          onSubmit={(reason) => this.submit(reason)}
        />
      </PopupLayout>
    );
  }

  submit(reason: string) {
    const { membershipRequestUrl, redirectUrl, token } = this.props;

    axios
      .put(
        membershipRequestUrl,
        {
          reason
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          showPopup: false
        });
        window.location.href = `${redirectUrl}?notice=Membership request has been rejected successfully.`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
        }
        window.location.href = `${redirectUrl}?alert=Membership request could not be rejected.`;
      });
  }
}

export default RejectButton;
