import * as React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

interface Props {
  hasAvatar: boolean;
  inFlight: boolean;
  maxSize?: number;
  onCancel: () => void;
  onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
  onRemove: () => void;
}

function CustomAvatarUploader(props: Props) {
  return (
    <div className="CustomAvatarUploader">
      <a
        onClick={props.onCancel}
        type="button"
      >
        Cancel
      </a>
      {props.hasAvatar && (
        <button
          className="button"
          onClick={props.onRemove}
          type="button"
        >
          Remove avatar
        </button>
      )}
      <Dropzone
        accept="image/gif, image/heic, image/jpeg, image/png"
        maxSize={props.maxSize || 5242880}
        minSize={0}
        multiple={false}
        onDrop={props.onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="button" {...getRootProps()}>
            <button
              disabled={props.inFlight}
              type="button"
            >
              <input {...getInputProps()} />
              Upload own
            </button>
          </div>
        )}
      </Dropzone>
    </div>
  );
}

export default CustomAvatarUploader;
