import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';

import LessonBlockDescription from './LessonBlockDescription';
import { Link } from './interfaces';
import LinkElement from './LinkElement';

interface Props {
  currentBlockId: string;
  currentLinks: Link[];
  description?: string;
  onClickAddLink: ((blockId: string) => void);
  onClickEdit: ((blockId: string, linkId: string) => void);
  onReorderLinks: ((sourceIndex: number, destinationIndex: number) => void);
  onUpdateDescription: ((description: string) => void);
  updateCurrentBlockLinks: ((links: Link[]) => void);
}

class LessonLinkEditor extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleRemoveLink = this.handleRemoveLink.bind(this);
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    this.props.onReorderLinks(result.source.index, result.destination.index);
  }

  handleRemoveLink(id: string) {
    const updatedLinks = this.props.currentLinks.filter((currentLink) => currentLink.id !== id);
    this.props.updateCurrentBlockLinks(updatedLinks);
  }

  render() {
    const { currentLinks, currentBlockId, description, onClickAddLink, onClickEdit, onUpdateDescription } = this.props;
    return (
      <div className="LessonLinkEditor">
        <LessonBlockDescription onUpdate={onUpdateDescription} value={description} />
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable
            droppableId={`blockId-${currentBlockId}`}
            type="droppableItem"
          >
            {(providedDroppable) => (
              <div
                className="linkList"
                ref={providedDroppable.innerRef}
              >
                {currentLinks.map((link, index) => (
                  <Draggable
                    draggableId={link.id}
                    index={index}
                    key={link.id}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <LinkElement
                          currentBlockId={currentBlockId}
                          link={link}
                          onClickEdit={onClickEdit}
                          onRemoveLink={this.handleRemoveLink}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
                }
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="footer">
          <button
            onClick={() => onClickAddLink(currentBlockId)}
            type="button"
          >
            Add link
          </button>
        </div>
      </div>
    );
  }
}

export default LessonLinkEditor;
