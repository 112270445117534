import * as React from 'react';
import axios from 'axios';

import Button from './Button';
import PopupLayout from './PopupLayout';
import ReasonPopup from './ReasonPopup';
import headers from '../utils/headersGenerator';
import images from '../utils/images';

interface Props {
  appearance?: 'flat' | 'regular';
  authenticityToken: string;
  faded?: boolean;
  flagId?: string;
  flaggableId: string;
  flaggableType: string;
  flagsUrl: string;
  onClick?: ((flagId: string) => void);
  onToggleShowMenu?: ((showMenu: boolean) => void);
  reportOptions: string[];
  size?: 'small' | 'medium' | 'large'
  title?: string;
  token: string;
}

interface State {
  flagId?: string;
  isClient: boolean;
  showPopup: boolean;
  title?: string;
}

class FlagButton extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClosePopup = this.handleClosePopup.bind(this);
    this.handleCreateFlag = this.handleCreateFlag.bind(this);
    this.handleDeleteFlag = this.handleDeleteFlag.bind(this);

    const { flagId, title } = this.props;

    this.state = {
      flagId,
      isClient: false,
      showPopup: false,
      title
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleClosePopup() {
    const { onToggleShowMenu } = this.props;
    if (onToggleShowMenu) {
      onToggleShowMenu(false);
    }
    this.setState({
      showPopup: false
    });
  }

  async handleCreateFlag() {
    const { onToggleShowMenu } = this.props;
    if (onToggleShowMenu) {
      onToggleShowMenu(true);
    }
    this.setState({ showPopup: true });
  }

  async handleDeleteFlag() {
    const { flagsUrl, onClick, onToggleShowMenu, token } = this.props;
    const { flagId } = this.state;

    if (onToggleShowMenu) {
      onToggleShowMenu(true);
    }
    if (!confirm('Are you sure you want to discard this report?')) {
      if (onToggleShowMenu) {
        onToggleShowMenu(false);
      }
      return undefined;
    }

    return axios
      .delete(
        `${flagsUrl}/${flagId}`,
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          flagId: undefined,
          title: 'Report'
        }, () => {
          if (onClick) {
            onClick('');
          }
          if (onToggleShowMenu) {
            onToggleShowMenu(false);
          }
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  render() {
    return (
      <div className="FlagButton">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { flagId, showPopup, title } = this.state;
    const { appearance, faded, size } = this.props;
    return (
      <>
        <Button
          appearance={appearance}
          faded={faded}
          imgAlt={flagId ? 'flag-active' : 'flag'}
          imgSrc={flagId ? images.flagActive : images.flag}
          onClick={flagId ? this.handleDeleteFlag : this.handleCreateFlag}
          size={size}
          title={title}
        />
        {showPopup && this.renderPopup()}
      </>
    );
  }

  renderPopup() {
    return (
      <PopupLayout
        onClose={this.handleClosePopup}
        title="Report"
      >
        <ReasonPopup
          label="Reason"
          onCancel={this.handleClosePopup}
          onSubmit={(reason) => this.submitReason(reason)}
          options={this.props.reportOptions}
          prompt="Can you tell us why this content is problematic?"
        />
      </PopupLayout>
    );
  }

  renderServerSide() {
    const { authenticityToken, flaggableId, flaggableType, flagId } = this.props;
    return (
      <form action={flagId ? `/flags/${flagId}` : '/flags/new'} method={flagId ? 'post' : 'get'}>
        {flagId && <input name="_method" type="hidden" value="delete" />}
        {flagId && <input name="authenticity_token" type="hidden" value={authenticityToken} />}
        <input name="flaggable_id" type="hidden" value={flaggableId} />
        <input name="flaggable_type" type="hidden" value={flaggableType} />
        <label>
          <input type="submit" />
          <img alt={flagId ? 'Reported' : 'Not reported'} src={flagId ? images.flagActive : images.flag} />
        </label>
      </form>
    );
  }

  submitReason(reason: string) {
    const { flaggableId, flagsUrl, flaggableType, onClick, token } = this.props;

    axios
      .post(
        flagsUrl,
        {
          flag: {
            flaggableId,
            flaggableType,
            reason
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        const flagId = response.data.flag_id;
        this.setState({
          flagId,
          showPopup: false,
          title: 'Reported'
        }, () => onClick && onClick(flagId));
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          this.setState({
            showPopup: false
          });
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }
}

export default FlagButton;
