import * as React from 'react';
import axios from 'axios';

import { BlockQuiz } from './interfaces';

interface Props {
  currentBlockIds: string[];
  onAddQuizzes: ((quizzes: BlockQuiz[]) => void);
  onCancel: (() => void);
  quizzes: BlockQuiz[];
}

interface State {
  quizzes: BlockQuiz[];
  selectedQuizIds: string[];
}

class QuizListPopup extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      quizzes: this.props.quizzes,
      selectedQuizIds: []
    };
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedQuizIds = Array.from(event.target.selectedOptions).map((option) => option.value);
    if (selectedQuizIds.length === 0) {
      return;
    }

    this.setState({ selectedQuizIds });
  }

  handleSubmit() {
    const selectedQuizzes = this.state.quizzes.filter((quiz) => this.state.selectedQuizIds.includes(quiz.id));
    this.props.onAddQuizzes(selectedQuizzes);
  }

  render() {
    const { quizzes, selectedQuizIds } = this.state;
    return (
      <div className="QuizListPopup">
        <div className="body">
          {quizzes.length === 0
            ? <p>There are no quizzes to be shown or all the quizzes have been added.</p>
            : (
              <>
                <p>Select the quizzes you would like to add:</p>
                <form>
                  <select
                    multiple
                    onChange={this.handleChange}
                    size={quizzes.length > 10 ? 10 : quizzes.length}
                  >
                    {quizzes.map((quiz) => (
                      <option key={quiz.id} value={quiz.id}>{quiz.title}</option>
                    ))}
                  </select>
                </form>
              </>
            )
          }
        </div>
        <div className="footer">
          <a onClick={this.props.onCancel}>Cancel</a>
          {quizzes.length > 0 && (
            <button
              disabled={selectedQuizIds.length === 0}
              onClick={this.handleSubmit}
              type="submit"
            >
              {selectedQuizIds.length > 1 ? 'Add quizzes' : 'Add quiz'}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default QuizListPopup;
