import * as React from 'react';
import Popup from 'reactjs-popup';

import Avatar from './Avatar';
import { NotificationElement } from './interfaces';
import NotificationSubMenu from './NotificationSubMenu';
import RenderHTML from './RenderHTML';

interface Props {
  notification: NotificationElement;
  onClickHideShow: (() => void);
  onClickMarkAs: (() => void);
  onCloseMenu: (() => void);
  onOpenMenu: (() => void);
  showMenu: boolean;
  token: string;
}

interface State {
  isClient: boolean;
  notification: NotificationElement;
}

class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isClient: false,
      notification: props.notification
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  handleClick(event: React.MouseEvent, read: boolean, url: string) {
    const { classList } = event.target as HTMLElement;
    if (classList.contains('link') || classList.contains('medium') || classList.contains('popup-overlay')) {
      event.stopPropagation();
      return;
    }
    window.location.href = url;
    if (!read) {
      this.props.onClickMarkAs();
    }
  }

  render() {
    const { read, url } = this.state.notification;
    return (
      <div className={`Notification ${read ? 'read' : ''}`}>
        <a href={this.state.isClient ? undefined : url} onClick={(event) => this.handleClick(event, read, url)}>
          {this.renderNotificationBody()}
        </a>
      </div>
    );
  }

  renderNotificationBody() {
    const { isClient, notification } = this.state;
    const { avatarUrl, createdAt, memberUrl, title } = notification;
    return (
      <object>
        <div className="body">
          <Avatar
            memberUrl={memberUrl}
            rounded
            size="medium"
            url={avatarUrl}
          />
          <div className="content">
            <div className="title">
              <RenderHTML content={title} />
            </div>
            <div className="createdAt">
              {createdAt}
            </div>
          </div>
          {isClient && (
            <div className="menuButton">
              {this.renderSubMenu()}
            </div>
          )}
        </div>
      </object>
    );
  }

  renderSubMenu() {
    const { notification } = this.state;
    const {
      onClickHideShow,
      onClickMarkAs,
      onCloseMenu,
      onOpenMenu,
      showMenu
    } = this.props;
    return (
      <Popup
        arrow={false}
        nested
        onClose={() => onCloseMenu()}
        onOpen={() => onOpenMenu()}
        open={showMenu}
        position="left top"
        trigger={<button type="button">...</button>}
      >
        <NotificationSubMenu
          notification={notification}
          onClickHideShow={onClickHideShow}
          onClickMarkAs={onClickMarkAs}
        />
      </Popup>
    );
  }
}

export default Notification;
