import { AxiosRequestHeaders } from 'axios';

const headersGenerator = (token: string): AxiosRequestHeaders => (
  {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') || ''
  }
);

export default headersGenerator;
