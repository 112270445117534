import * as EmailValidator from 'email-validator';
import * as React from 'react';
import axios from 'axios';
import { sortBy } from 'lodash';

import { EmailAddress } from './interfaces';
import InvitationsPopupBody from './InvitationsPopupBody';
import InvitationsPopupSummary from './InvitationsPopupSummary';
import PopupLayout from './PopupLayout';
import headers from '../utils/headersGenerator';
import isBlank from '../utils/isBlank';

const createOption = (label: string, valid: boolean) => ({
  label,
  valid,
  value: label
});

interface Props {
  authenticityToken: string;
  createUrl: string;
  groupId?: string;
  token: string;
  url: string;
}

interface State {
  emailAddresses: EmailAddress[];
  errors: [];
  inputValue: string;
  isClient: boolean;
  message: string;
  referralsAlreadySent: string[];
  referralsNotSent: string[];
  referralsSent: string[];
  sending: boolean;
  showPopup: boolean;
  summaryPresent: boolean;
  unsavedEmails: string;
}

class Invitations extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleBlurChange = this.handleBlurChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSummaryCancel = this.handleSummaryCancel.bind(this);

    this.state = {
      emailAddresses: [],
      errors: [],
      inputValue: '',
      isClient: false,
      message: '',
      referralsAlreadySent: [],
      referralsNotSent: [],
      referralsSent: [],
      sending: false,
      showPopup: false,
      summaryPresent: false,
      unsavedEmails: ''
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  createOptionFromValue(value: string) {
    return createOption(value.trim(), this.isEmailValid(value.trim()));
  }

  handleBlurChange() {
    const { inputValue, emailAddresses } = this.state;

    if (isBlank(inputValue)) {
      return;
    }

    this.setState({
      emailAddresses: sortBy([...emailAddresses, this.createOptionFromValue(inputValue)], 'value')
    });
  }

  handleCancel() {
    this.setState({
      message: '',
      showPopup: false,
      unsavedEmails: ''
    });
  }

  handleSubmit() {
    this.setState({ sending: true });
    const { emailAddresses, message } = this.state;
    const { groupId, token, url } = this.props;

    const updatedEmailAddresses = emailAddresses.map((emailAddress) => emailAddress.value);
    if (updatedEmailAddresses.length === 0) {
      return;
    }

    axios
      .post(
        url,
        {
          emailAddresses: updatedEmailAddresses,
          groupId,
          message
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.setState({
          emailAddresses: [],
          errors: response.data.errors.base,
          referralsAlreadySent: response.data.referrals_already_sent,
          referralsNotSent: response.data.referrals_not_sent,
          referralsSent: response.data.referrals_sent,
          summaryPresent: true,
          sending: false,
          showPopup: false
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
          this.setState({ sending: false });
        }
      });
  }

  handleSummaryCancel() {
    this.setState({
      message: '',
      summaryPresent: false,
      unsavedEmails: ''
    });
  }

  isEmailValid(email: string) {
    return EmailValidator.validate(email);
  }

  onKeyDown = (event: React.KeyboardEvent) => {
    const { inputValue, emailAddresses } = this.state;
    if (!inputValue) {
      return;
    }

    if (event.key === 'Enter' || event.key === 'Tab') {
      this.setState({
        emailAddresses: sortBy([...emailAddresses, this.createOptionFromValue(inputValue)], 'value'),
        inputValue: ''
      });
    }
  };

  render() {
    return this.state.isClient ? this.renderClientSide() : this.renderServerSide();
  }

  renderClientSide() {
    const { showPopup, summaryPresent } = this.state;
    return (
      <div className="Invitations">
        <form>
          <input
            onChange={(event) => this.setState({ unsavedEmails: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                this.updateEmailAddresses();
              }
            }}
            placeholder="Enter an email address..."
            type="text"
            value={this.state.unsavedEmails}
          />
        </form>
        <button
          onClick={(event) => {
            event.preventDefault();
            this.updateEmailAddresses();
          }}
          type="button"
        >
          Invite
        </button>
        {showPopup && this.renderPopup()}
        {summaryPresent && this.renderSummaryPopup()}
      </div>
    );
  }

  renderPopup() {
    const { emailAddresses, inputValue, message, sending } = this.state;
    return (
      <PopupLayout
        onClose={this.handleCancel}
        title="Send Referrals"
      >
        <InvitationsPopupBody
          emailAddresses={emailAddresses}
          inputValue={inputValue}
          message={message}
          onBlur={this.handleBlurChange}
          onCancel={this.handleCancel}
          onChange={(values) => this.setState({ emailAddresses: values })}
          onInputChange={(value) => this.setState({ inputValue: value })}
          onKeyDown={(event) => this.onKeyDown(event)}
          onMessageChange={(value) => this.setState({ message: value })}
          onSubmit={this.handleSubmit}
          sending={sending}
        />
      </PopupLayout>
    );
  }

  renderServerSide() {
    const { authenticityToken, createUrl } = this.props;
    return (
      <form action={createUrl} method="post">
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <input name="email_addresses" placeholder="Enter an email address..." type="text" />
        <input type="submit" value="Invite" />
      </form>
    );
  }

  renderSummaryPopup() {
    const { errors, referralsAlreadySent, referralsNotSent, referralsSent } = this.state;
    return (
      <PopupLayout
        onClose={this.handleSummaryCancel}
        title="Invitations Summary"
      >
        <InvitationsPopupSummary
          errors={errors}
          onClickCancel={this.handleSummaryCancel}
          onClickRetry={() => this.updateInvalidEmails(referralsNotSent)}
          referralsAlreadySent={referralsAlreadySent}
          referralsNotSent={referralsNotSent}
          referralsSent={referralsSent}
        />
      </PopupLayout>
    );
  }

  updateEmailAddresses() {
    const updateEmailAddresses = this.state.unsavedEmails
      .replace(/\s/g, ',')
      .split(/[,|;]/)
      .filter((email) => email !== '')
      .map((email: string) => this.createOptionFromValue(email));

    this.setState({
      emailAddresses: sortBy([...updateEmailAddresses], 'value'),
      showPopup: true
    });
  }

  updateInvalidEmails(referrals: string[]) {
    const emailAddresses = referrals.map((referral) => this.createOptionFromValue(referral));
    this.setState({
      emailAddresses,
      showPopup: true,
      summaryPresent: false
    });
  }
}

export default Invitations;
