import * as React from 'react';
import { Popup } from 'reactjs-popup';

import Avatar from './Avatar';
import DeleteButton from './DeleteButton';
import FlagButton from './FlagButton';
import { InteractionElement } from './interfaces';
import InteractionSubMenu from './InteractionSubMenu';
import LikeButton from './LikeButton';

interface Props {
  authenticityToken: string;
  clientSideDeleteUrl: string;
  defaultAvatarUrl: string;
  flaggedId?: string;
  flagsUrl: string;
  friendlyName: string;
  interaction: InteractionElement;
  likesUrl: string;
  membershipId?: string;
  onClickDeleteButton: ((id: string) => void);
  redirectUrl: string;
  reportOptions: string[];
  serverSideDeleteUrl: string;
  token: string;
  type: string;
}

interface State {
  interaction: InteractionElement;
  isClient: boolean;
  showMenu: boolean;
}

const getStyle = (showMenu: boolean) => ({
  display: showMenu ? 'none' : ''
});

class Interaction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      interaction: props.interaction,
      isClient: false,
      showMenu: false
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { interaction } = this.props;
    if (prevProps.interaction !== interaction) {
      this.setState({
        interaction
      });
    }
  }

  render() {
    const { defaultAvatarUrl, flaggedId, friendlyName } = this.props;
    const { interaction, isClient } = this.state;
    const { anyFlag, avatarUrl, canDestroy, canFlag, content, createdAt, createdTime, deleted, displayName, id, memberUrl } = interaction;
    return (
      <div className={`Interaction ${id === flaggedId ? 'bordered' : ''}`} id={interaction.id}>
        <Avatar
          memberUrl={deleted ? '' : memberUrl}
          rounded
          size="small"
          url={deleted ? defaultAvatarUrl : avatarUrl}
        />
        <div className={`content${deleted ? ' deleted' : ''}`}>
          <div className="header">
            <div className="authorship">
              {deleted
                ? <span className="displayName">[hidden]</span>
                : (
                  <a
                    className="displayName"
                    href={memberUrl}
                    id={id}
                  >
                    {displayName}
                  </a>
                )
              }
              <span className="divider" />
              <span className="createdAt" title={createdTime}>{createdAt}</span>
              {anyFlag && (
                <>
                  <span className="divider" />
                  <div className="reported">Reported</div>
                </>
              )}
            </div>
            {isClient && !deleted && (canFlag || canDestroy) && (
              <div className="menuButton">
                {this.renderSubMenu()}
              </div>
            )}
          </div>
          {deleted
            ? <div className="contentDeleted">[This {friendlyName.toLowerCase()} has been deleted]</div>
            : <p>{content}</p>
          }
          {!deleted && this.renderActions()}
        </div>
      </div>
    );
  }

  renderActions() {
    const {
      authenticityToken,
      flagsUrl,
      friendlyName,
      likesUrl,
      membershipId,
      onClickDeleteButton,
      reportOptions,
      serverSideDeleteUrl,
      token,
      type
    } = this.props;
    const { interaction, isClient } = this.state;
    const { canDestroy, canFlag, canLike, flagId, id, likeCount, likeId } = interaction;

    return (
      <div className="actions">
        <div className="left">
          <div className="action">
            <LikeButton
              authenticityToken={authenticityToken}
              canLike={canLike}
              likeId={likeId}
              likeableId={id}
              likeableType={type}
              likesUrl={likesUrl}
              membershipId={membershipId}
              token={token}
            />
          </div>
          <div className="likeCount">
            {likeCount === 1 && <p>Liked by one person.</p>}
            {likeCount > 1 && <p>Liked by {likeCount} others.</p>}
          </div>
        </div>
        {!isClient && (
          <div className="right">
            {canFlag && (
              <FlagButton
                authenticityToken={authenticityToken}
                flagId={flagId}
                flaggableId={id}
                flaggableType={type}
                flagsUrl={flagsUrl}
                reportOptions={reportOptions}
                token={token}
              />
            )}
            {canDestroy && (
              <DeleteButton
                authenticityToken={authenticityToken}
                friendlyName={friendlyName}
                onClickDeleteButton={() => onClickDeleteButton(interaction.id)}
                serverSideUrl={serverSideDeleteUrl}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  renderSubMenu() {
    const {
      authenticityToken,
      clientSideDeleteUrl,
      flagsUrl,
      friendlyName,
      onClickDeleteButton,
      reportOptions,
      serverSideDeleteUrl,
      token,
      type
    } = this.props;
    const { showMenu, interaction } = this.state;

    return (
      <Popup
        arrow={false}
        contentStyle={getStyle(showMenu)}
        nested
        open={showMenu}
        position="left top"
        trigger={<button type="button">...</button>}
      >
        <InteractionSubMenu
          authenticityToken={authenticityToken}
          clientSideDeleteUrl={clientSideDeleteUrl}
          flagsUrl={flagsUrl}
          friendlyName={friendlyName}
          interaction={interaction}
          onClick={(newFlagId) => this.updateInteraction(newFlagId)}
          onClickDeleteButton={onClickDeleteButton}
          onToggleShowMenu={(showSubMenu) => this.setState({ showMenu: showSubMenu })}
          reportOptions={reportOptions}
          serverSideDeleteUrl={serverSideDeleteUrl}
          token={token}
          type={type}
        />
      </Popup>
    );
  }

  updateInteraction(flagId: string) {
    const { interaction } = this.state;
    this.setState({
      interaction: {
        ...interaction,
        flagId
      },
      showMenu: false
    });
  }
}

export default Interaction;
