import * as React from 'react';

interface Props {
  bordered?: boolean;
  memberUrl?: string;
  rounded?: boolean
  size: 'small' | 'medium' | 'large';
  url: string;
}

const image = (props: Props) => (
  <img
    alt="avatar"
    className={`${props.size} ${props.bordered ? 'bordered' : ''} ${props.rounded ? 'rounded' : ''}`}
    src={props.url}
  />
);

function Avatar(props: Props) {
  return (
    <div className="Avatar">
      {props.memberUrl
        ? (
          <a
            className="avatar"
            href={props.memberUrl}
          >
            {image(props)}
          </a>
        )
        : (
          <span className="avatar">
            {image(props)}
          </span>
        )
      }
    </div>
  );
}

export default Avatar;
