import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { CSSProperties } from 'react';

function getDraggableStyle(draggableStyle?: DraggingStyle | NotDraggingStyle, isLast?: boolean): CSSProperties {
  return ({
    margin: isLast ? '0' : '0 0 8px 0',
    outline: 'none',
    userSelect: 'none',
    width: '100%',
    ...draggableStyle
  });
}

export default getDraggableStyle;
