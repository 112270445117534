import * as React from 'react';

import Button from './Button';
import { Rule } from './interfaces';
import TitleEditor from './TitleEditor';
import ValidationErrors from './ValidationErrors';
import images from '../utils/images';

interface Props {
  expanded: boolean;
  index: number;
  onClickArrowHead: (() => void);
  onEditingTitle: (() => void);
  onMouseLeave: (() => void);
  onRemove: (() => void);
  onUpdate: ((rule: Rule) => void);
  rule: Rule;
}

interface State {
  errors: string;
  rule: Rule;
}

class RuleEditor extends React.Component<Props, State> {
  private readonly textareaRef: React.RefObject<HTMLTextAreaElement>;

  constructor(props: Props) {
    super(props);
    this.handleClickRemove = this.handleClickRemove.bind(this);
    this.handleClickTextarea = this.handleClickTextarea.bind(this);
    this.textareaRef = React.createRef();
    this.state = {
      errors: '',
      rule: props.rule
    };
  }

  changeProperty(value: string, prop: 'content' | 'title') {
    const rule = { ...this.state.rule, [prop]: value };
    const getErrors = () => {
      if (rule.content.length < 2 && rule.title.length < 2) {
        return 'Content and title must be at least 2 characters long.';
      }
      if (rule.content.length < 2) {
        return 'The content must be at least 2 characters long.';
      }
      if (rule.title.length < 2) {
        return 'The title must be at least 2 characters long.';
      }
      return '';
    };
    const errors = getErrors();
    this.setState({
      errors,
      rule
    }, () => this.props.onUpdate(this.state.rule));
  }

  async handleClickRemove() {
    if (confirm('Are you sure you want to remove this rule?')) {
      this.props.onRemove();
    }
  }

  handleClickTextarea() {
    this.props.onEditingTitle();
    if (this.textareaRef.current === document.activeElement) {
      return;
    }
    this.textareaRef.current?.select();
  }

  render() {
    const { errors, rule } = this.state;
    const { expanded, onClickArrowHead, onEditingTitle, onMouseLeave } = this.props;
    const { content, title } = rule;
    return (
      <div className="RuleEditor">
        <div className="header">
          <div className="caret">
            <Button
              imgAlt="Expand/Collapse"
              imgSrc={images.arrowHeadRight}
              onClick={async () => onClickArrowHead()}
              rotated={expanded}
              size="small"
            />
          </div>
          <TitleEditor
            onEditing={onEditingTitle}
            onUpdateTitle={(value: string) => this.changeProperty(value, 'title')}
            showErrorIcon={title.trim().length < 2}
            title={title}
          />
          <div className="button">
            <Button
              imgAlt="Remove question"
              imgSrc={images.crossBox}
              onClick={this.handleClickRemove}
              size="large"
            />
          </div>
        </div>
        {expanded && (
          <textarea
            defaultValue={content}
            onChange={(event) => this.changeProperty(event.target.value, 'content')}
            onClick={this.handleClickTextarea}
            onMouseEnter={onEditingTitle}
            onMouseLeave={onMouseLeave}
            ref={this.textareaRef}
            required
          />
        )}
        {errors.length > 0 && (
          <div className="error">
            <ValidationErrors messages={[errors]} />
          </div>
        )}
      </div>
    );
  }
}

export default RuleEditor;
