import * as React from 'react';
import axios from 'axios';

import {
  FloatQuestion,
  IntegerQuestion,
  MultipleChoiceQuestion,
  Option,
  Question,
  QuestionType,
  SortableQuestion
} from './interfaces';
import Checkbox from './Checkbox';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import headers from '../utils/headersGenerator';

interface Props {
  block: {
    content: Question[],
    id: string,
    title: string,
    type: string
  };
  feedback: string;
  graded: boolean;
  lessonResponsesUrl: string;
  responses: Responses;
  token?: string;
}

interface Responses {
  [key: string]: {
    content: string;
    options: string[];
  }
}

interface State {
  feedback: string;
  isDirty: boolean;
  questions: Question[];
  responseGraded: boolean;
  responses: Responses;
  saving: boolean;
}

class LessonQuizResponse extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleSubmitReview = this.handleSubmitReview.bind(this);

    this.state = {
      feedback: props.feedback,
      isDirty: !props.graded,
      questions: this.parseQuestions(),
      responseGraded: props.graded,
      responses: props.responses,
      saving: false
    };
  }

  calculateGrade() {
    return this.state.questions.filter((question) => question.marked === 'correct').length;
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleSubmitReview() {
    const { lessonResponsesUrl, token } = this.props;
    const { feedback, questions } = this.state;
    this.setState({ saving: true });
    if (!token) {
      return;
    }

    axios
      .put(
        lessonResponsesUrl,
        {
          lessonResponse: {
            feedback,
            graded: true,
            quiz: { content: JSON.stringify(questions) },
            score: this.calculateGrade()
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          isDirty: false,
          responseGraded: true,
          saving: false
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          this.setState({
            saving: false
          });
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  isOptionIncluded(questionId: string, optionId: string) {
    const question = this.state.responses[questionId];
    if (!question) {
      return false;
    }

    return question.options.some((option) => option === optionId);
  }

  markQuestion(tick: string, index: number) {
    const updatedQuestions = [...this.state.questions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      marked: tick
    };

    this.setState({ isDirty: true, questions: updatedQuestions });
  }

  parseQuestions(): Question[] {
    return typeof this.props.block.content === 'string' ? JSON.parse(this.props.block.content) : this.props.block.content;
  }

  render() {
    const { feedback, isDirty, questions, responseGraded, saving } = this.state;
    const { block } = this.props;
    return (
      <div className="LessonQuizResponse">
        <UnsavedChangesPrompt isDirty={isDirty} />
        <div className="header">
          <h3>{block.title}</h3>
        </div>
        <div className="body expanded">
          {questions.map((question, index) => (
            <div className="question" key={question.id}>
              <div className="header">
                <p>{`Q: ${index + 1}. ${question.prompt}`}</p>
                <Checkbox
                  checked={question.marked === 'correct'}
                  onClick={() => (question.marked === 'correct'
                    ? this.markQuestion('', index)
                    : this.markQuestion('correct', index))
                  }
                />
              </div>
              {this.renderQuestion(question)}
            </div>
          ))}
          <div className="score">
            <p>Total score: {this.calculateGrade()}/
              {questions.length}
            </p>
          </div>
          <div className="feedback">
            <textarea
              name="feedback"
              onChange={(event) => this.setState({ feedback: event.target.value })}
              placeholder="Provide some feedback here..."
              value={feedback}
            />
          </div>
        </div>
        <div className="footer">
          {saving
            ? (
              <button disabled type="button">
                Saving...
              </button>
            )
            : (
              <button
                disabled={!isDirty}
                onClick={this.handleSubmitReview}
                type="button"
              >
                {responseGraded ? 'Update Grade' : 'Set Grade'}
              </button>
            )}
        </div>
      </div>
    );
  }

  renderFreeform(questionId: string, option?: Option) {
    return <p>{this.state.responses[questionId]?.content || option?.label}</p>;
  }

  renderMultipleChoice(question: MultipleChoiceQuestion) {
    const { id, multipleChoiceOptions } = question;
    return (
      multipleChoiceOptions?.map((option) => (
        <div className="multipleChoice disabled" key={option.id}>
          <Checkbox checked={this.isOptionIncluded(id, option.id)} disabled />
          {
            option.freeform
              ? this.renderFreeform(question.id, option)
              : <div>{option.label}</div>
            }
        </div>
      ))
    );
  }

  renderNumberQuestion(question: FloatQuestion | IntegerQuestion) {
    return (
      <input
        disabled
        type="number"
        value={this.state.responses[question.id]?.content || ''}
      />
    );
  }

  renderQuestion(question: Question) {
    switch (question.type) {
      case QuestionType.Float:
        // fall-through
      case QuestionType.Integer:
        return this.renderNumberQuestion(question);
      case QuestionType.Freeform:
        return this.renderFreeform(question.id);
      case QuestionType.MultipleChoice:
        return this.renderMultipleChoice(question);
      case QuestionType.Sort:
        return this.renderSortable(question);
      default:
        return '';
    }
  }

  renderSortable(question: SortableQuestion) {
    const response = this.state.responses[question.id];
    return (
      <div className="sort">
        {response?.options.map((option, index) => (
          <div className="option" key={option}>
            {index + 1}. {question.sortOptions?.find((sortOption) => sortOption.id === option)?.label}
          </div>
        ))}
      </div>
    );
  }
}

export default LessonQuizResponse;
