import * as React from 'react';
import images from 'utils/images';

interface Props {
  checked: boolean;
  disabled?: boolean;
  onClick?: ((event: React.MouseEvent) => void);
}

function Checkbox(props: Props) {
  return (
    <div
      className={`Checkbox ${props.disabled ? 'disabled' : ''}`}
      onClick={props.onClick}
    >
      <img
        alt="checkbox"
        src={props.checked ? images.tickBox : images.checkbox}
      />
    </div>
  );
}

export default Checkbox;
