import * as React from 'react';
import axios from 'axios';

import DeleteButton from './DeleteButton';
import { StatusUpdate } from './interfaces';
import headers from '../utils/headersGenerator';

interface Props {
  clientSideUrl: string;
  content: string;
  membershipId: string;
  onClose: (() => void);
  onError: ((messages: string[]) => void);
  onUpdateStatusList: ((list: StatusUpdate[]) => void);
  statusUpdates: StatusUpdate[];
  token: string;
}

interface State {
  content: string;
  inFlight: boolean;
  statusUpdates: StatusUpdate[];
}

class StatusUpdatePopupBody extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    const { content, statusUpdates } = props;
    this.state = {
      content,
      inFlight: false,
      statusUpdates
    };
  }

  deleteStatus(id: string) {
    const { clientSideUrl, token } = this.props;
    this.setState({
      inFlight: true
    });

    axios
      .delete(
        `${clientSideUrl}/${id}`,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: { id }
        }
      )
      .then((response) => {
        const { statusUpdates } = response.data;
        this.setState({
          statusUpdates
        }, () => {
          this.props.onUpdateStatusList(statusUpdates);
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert(error.message);
        }
        console.error(error);
      })
      .finally(() => {
        this.setState({
          inFlight: false
        });
      });
  }

  handleSubmit(content: string) {
    if (content.length > 100) {
      this.props.onError(['Status is too long (maximum is 100 characters)']);
      return;
    }

    this.setState({
      inFlight: true
    });

    const { clientSideUrl, membershipId, token } = this.props;

    axios
      .post(
        clientSideUrl,
        { content, membershipId },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then((response) => {
        this.props.onUpdateStatusList(response.data.statusUpdates);
        this.props.onClose();
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          this.setState({ inFlight: false });
          this.props.onError(error.response.data.message);
          console.error(error);
        }
      });
  }

  render() {
    const { content, inFlight } = this.state;
    return (
      <div className="StatusUpdatePopupBody">
        <div className="content">
          <label>Set your new Status</label>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.handleSubmit(content);
            }}
          >
            <input
              onChange={(event) => this.setState({ content: event.target.value })}
              placeholder="Your status..."
              type="text"
              value={content}
            />
          </form>
          {this.renderList()}
        </div>
        <div className="footer">
          <a onClick={this.props.onClose}>Cancel</a>
          <button
            disabled={content === this.props.content || inFlight}
            onClick={(event) => {
              event.preventDefault();
              this.handleSubmit(content);
            }}
            type="submit"
          >
            {inFlight ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    );
  }

  renderList() {
    const { content, inFlight, statusUpdates } = this.state;
    const filteredStatus = statusUpdates.filter((status) => status.content !== content);

    return (
      <div className={`listContainer ${filteredStatus.length === 0 ? 'hidden' : ''}`}>
        <label>Reuse a recent one</label>
        <div className="list">
          {filteredStatus.map((status, index) => (
            <div className="item" key={index}>
              <a onClick={() => this.setState({ content: status.content })}>
                {status.content}
              </a>
              {status.content !== content && (
                <DeleteButton
                  appearance="regular"
                  disabled={inFlight}
                  onClickDeleteButton={async () => this.deleteStatus(status.id)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default StatusUpdatePopupBody;
