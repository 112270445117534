import * as React from 'react';

import Button from './Button';
import images from '../utils/images';

interface Props {
  appearance?: 'flat' | 'regular';
  authenticityToken?: string;
  disabled?: boolean;
  friendlyName?: string;
  onClickDeleteButton: (() => void);
  onToggleShowMenu?: ((showMenu: boolean) => void);
  serverSideUrl?: string;
  title?: string;
}

interface State {
  isClient: boolean;
}

class DeleteButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleClickButton = this.handleClickButton.bind(this);

    this.state = {
      isClient: false
    };
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  async handleClickButton() {
    const { friendlyName, onClickDeleteButton, onToggleShowMenu } = this.props;
    if (onToggleShowMenu) {
      onToggleShowMenu(true);
    }

    if (friendlyName && confirm(`Are you sure you want to delete this ${friendlyName.toLowerCase()}?`)) {
      onClickDeleteButton();
    } else if (onToggleShowMenu) {
      onToggleShowMenu(false);
    } else if (!friendlyName) {
      onClickDeleteButton();
    }
  }

  render() {
    return (
      <div className="DeleteButton">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { appearance, disabled, title } = this.props;
    return (
      <Button
        appearance={appearance}
        disabled={disabled}
        imgAlt="Delete"
        imgSrc={images.trash}
        onClick={this.handleClickButton}
        title={title}
      />
    );
  }

  renderServerSide() {
    const { authenticityToken, serverSideUrl } = this.props;
    return (
      <form action={serverSideUrl} method="post">
        <input name="_method" type="hidden" value="delete" />
        <input name="authenticity_token" type="hidden" value={authenticityToken} />
        <label>
          <input type="submit" />
          <img alt="Delete" src={images.trash} />
        </label>
      </form>
    );
  }
}

export default DeleteButton;
