import * as React from 'react';

import Spinner from './Spinner';

interface Props {
  appearance?: 'flat' | 'regular';
  disabled?: boolean;
  faded?: boolean;
  imgAlt?: string;
  imgSrc?: string;
  onClick: (() => Promise<void>);
  rotated?: boolean;
  size?: 'small' | 'medium' | 'large';
  title?: string;
}

interface State {
  inFlight: boolean;
}

const getSize = (size: string) => {
  switch (size) {
    case 'small': return 6;
    case 'medium': return 20;
    default: return 24;
  }
};

class Button extends React.Component<Props, State> {
  private abortController = new AbortController();
  private signal = this.abortController.signal;

  constructor(props: Props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      inFlight: false
    };
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleClick() {
    this.setState({
      inFlight: true
    });

    this.props.onClick()
      .then(() => {
        if (!this.signal.aborted) {
          this.setState({ inFlight: false });
        }
      })
      .catch((error) => {
        alert('Something went wrong, please refresh the page and try again.');
        console.log(error);
      });
  }

  render() {
    const { inFlight } = this.state;
    const { disabled, size, title } = this.props;
    return (
      <button
        className={`Button ${size || ''}`}
        disabled={disabled || inFlight}
        onClick={this.handleClick}
        type="button"
      >
        {title ? this.renderButtonWithTitle(title) : this.renderButtonWithoutTitle()}
      </button>
    );
  }

  renderButtonWithTitle(title: string) {
    const { faded, imgAlt, imgSrc, appearance, rotated, size } = this.props;
    return (
      <div className={`withTitle  ${appearance || 'default'}`}>
        {imgSrc && (
          <div className="icon">
            <img
              alt={imgAlt}
              className={`${rotated ? 'rotated' : size || ''} ${faded ? 'faded' : ''}`}
              src={imgSrc}
            />
          </div>
        )}
        <div className="label">
          {title}
        </div>
      </div>
    );
  }

  renderButtonWithoutTitle() {
    const { inFlight } = this.state;
    const { appearance, faded, imgAlt, imgSrc, rotated, size } = this.props;
    return (
      inFlight
        ? <Spinner size={getSize(size || 'medium')} />
        : (
          <div className={`withoutTitle ${appearance || 'default'}`}>
            <img
              alt={imgAlt}
              className={`${rotated ? 'rotated' : size || ''} ${faded ? 'faded' : ''}`}
              src={imgSrc}
            />
          </div>
        )
    );
  }
}

export default Button;
