import * as React from 'react';
import * as Select from 'react-select';

import { EmailAddress } from './interfaces';

const colourStyles = {
  container: (_provided: any) => ({ ..._provided }),
  control: (_provided: any) => ({ ..._provided,
    backgroundColor: '#f9f9f9',
    border: 'solid 1px #b1b9bb',
    boxShadow: '0',
    '&:hover': { border: 'solid 1px #b1b9bb' } }),
  multiValueLabel: (_provided: any, state: any) => ({ ..._provided, color: state.data.valid ? 'black' : 'red' }),
  placeholder: (_provided: any) => ({ ..._provided, fontSize: '16px' })
};

interface Props {
  emailAddresses: EmailAddress[];
  inputValue: string,
  onBlur: (() => void);
  onChange: ((emailAddresses: EmailAddress[]) => void);
  onInputChange: ((value: string) => void);
  onKeyDown: ((event: React.KeyboardEvent) => void);
  sending: boolean;
}

interface State {
  select?: typeof Select.default;
}

class MultiSelectorElement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line global-require
      select: require('react-select').default
    });
  }

  render() {
    if (!this.state.select) {
      return '';
    }

    const {
      emailAddresses,
      inputValue,
      onBlur,
      onChange,
      onInputChange,
      onKeyDown,
      sending
    } = this.props;

    return (
      <this.state.select
        components={{ DropdownIndicator: null }}
        inputValue={inputValue}
        isClearable
        isDisabled={sending}
        isMulti
        menuIsOpen={false}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={(event) => onKeyDown(event)}
        placeholder="Enter one or more email addresses..."
        styles={colourStyles}
        value={emailAddresses}
      />
    );
  }
}

export default MultiSelectorElement;
