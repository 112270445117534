import * as React from 'react';

import AuthenticatedLinks from './AuthenticatedLinks';
import { Avatar } from './interfaces';
import TermsOfServicePopup from './TermsOfServicePopup';
import UnauthenticatedLinks from './UnauthenticatedLinks';

interface AuthLinks {
  accountPath: string;
  adminPath: string;
  becomeAdminPath?: string;
  contentPath: string;
  groupsPath: string;
  logOutPath: string;
  messagesPath: string;
  notificationsPath: string;
  surveysCount: number;
  surveysPath: string;
  termsOfServiceProps?: TermsOfServiceProps;
  unreadMessageCount: number;
  unseenNotificationCount: number;
  userIsAdmin: boolean;
  userIsEditor: boolean;
}

interface Props {
  authLinks?: AuthLinks;
  initialPath: string;
  loggedIn: boolean;
  logoUrl: string;
  rootPath: string;
  unauthLinks?: UnauthLinks;
}

interface State {
  currentPath: string;
  isClient: boolean;
}

interface TermsOfServiceProps {
  accountUrl: string;
  authenticityToken: string;
  clientUpdateUrl: string;
  serverUpdateUrl: string;
  termsOfServiceAbstract: string;
  termsOfServiceUrl: string;
  token: string;
}

interface UnauthLinks {
  authenticityToken: string;
  avatars: Avatar[];
  clientLoginUrl: string;
  clientSignupUrl: string;
  facebookUrl: string;
  forgotPasswordUrl: string;
  googleUrl: string;
  rootUrl: string;
  serverLoginUrl: string;
  serverRegistrationUrl: string;
}

class NavigationBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleTurbolinksLoad = this.handleTurbolinksLoad.bind(this);
    this.state = {
      currentPath: props.initialPath,
      isClient: false
    };
  }

  componentDidMount() {
    document.addEventListener('turbolinks:load', this.handleTurbolinksLoad);
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    document.removeEventListener('turbolinks:load', this.handleTurbolinksLoad);
  }

  handleTurbolinksLoad() {
    this.setState({
      currentPath: window.location.pathname
    });
  }

  render() {
    const { authLinks, loggedIn, logoUrl, rootPath } = this.props;
    return (
      <div className="NavigationBar">
        <a href={rootPath}>
          <img alt="Logo" className="logo" src={logoUrl} />
        </a>
        {!this.state.isClient && (
          <div className="javaScriptWarning">
            <div className="content">Enable JavaScript for a better experience</div>
          </div>
        )}
        {loggedIn ? this.renderAuthenticatedLinks() : this.renderUnauthenticatedLinks()}
        {authLinks?.termsOfServiceProps && <TermsOfServicePopup {...authLinks.termsOfServiceProps} />}
      </div>
    );
  }

  renderAuthenticatedLinks() {
    if (!this.props.authLinks) {
      return '';
    }

    return (
      <AuthenticatedLinks {...this.props.authLinks} currentPath={this.state.currentPath} />
    );
  }

  renderUnauthenticatedLinks() {
    const { logoUrl, unauthLinks } = this.props;
    if (!unauthLinks) {
      return '';
    }

    return (
      <UnauthenticatedLinks {...unauthLinks} currentPath={this.state.currentPath} logoUrl={logoUrl} />
    );
  }
}

export default NavigationBar;
