import * as React from 'react';

import { BlockType } from './interfaces';

interface Props {
  onAddBlock: ((type: BlockType) => void);
}

function BlockList(props: Props) {
  return (
    <div className="BlockList">
      <button
        onClick={() => props.onAddBlock(BlockType.Attachment)}
        type="button"
      >
        Attachment
      </button>
      <button
        onClick={() => props.onAddBlock(BlockType.Link)}
        type="button"
      >
        Link
      </button>
      <button
        onClick={() => props.onAddBlock(BlockType.Quiz)}
        type="button"
      >
        Quiz
      </button>
      <button
        onClick={() => props.onAddBlock(BlockType.Rich)}
        type="button"
      >
        Rich
      </button>
      <button
        onClick={() => props.onAddBlock(BlockType.Video)}
        type="button"
      >
        Video
      </button>
    </div>
  );
}

export default BlockList;
