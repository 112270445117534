import * as React from 'react';
import axios from 'axios';

import headers from '../utils/headersGenerator';

interface Model {
  actionName: string; // 'lesson_comment_created'
  friendlyName: string; // 'Comment'
  modelName: string; // 'Lesson Comment'
}

interface Point {
  actionName: string;
  pointsAwarded: string;
}

interface Props {
  authenticityToken: string;
  buttonTitle: string;
  clientSideUrl: string;
  models: Model[];
  point?: Point;
  redirectUrl: string;
  serverSideUrl: string;
  successMessageTitle: string;
  token: string;
}

interface State {
  inFlight: boolean;
  isClient: boolean;
  pointsAwarded: number;
  selectedActionName: string;
}

class ActionPoint extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleCreateNewPoint = this.handleCreateNewPoint.bind(this);
    this.handleUpdatePoint = this.handleUpdatePoint.bind(this);

    const { models, point } = props;

    this.state = {
      inFlight: false,
      isClient: false,
      pointsAwarded: point ? parseInt(point.pointsAwarded, 10) : 0,
      selectedActionName: models.length > 0 ? models[0].actionName : ''
    };
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  handleCreateNewPoint() {
    const { clientSideUrl, redirectUrl, successMessageTitle, token } = this.props;
    const { pointsAwarded, selectedActionName } = this.state;
    axios
      .post(
        clientSideUrl,
        {
          actionPoint: {
            actionName: selectedActionName,
            pointsAwarded
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          inFlight: false
        });
        window.location.href = `${redirectUrl}?notice=${successMessageTitle}`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          this.setState({
            inFlight: false
          });
          console.error(error);
          window.location.href = `${redirectUrl}?alert=${error.response.data.message}`;
        }
      });
  }

  handleUpdatePoint() {
    const { clientSideUrl, redirectUrl, successMessageTitle, token } = this.props;
    const { pointsAwarded } = this.state;

    axios
      .put(
        clientSideUrl,
        {
          actionPoint: {
            pointsAwarded
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          inFlight: false
        });
        window.location.href = `${redirectUrl}?notice=${successMessageTitle}`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          this.setState({
            inFlight: false
          });
          console.error(error);
          window.location.href = `${redirectUrl}?alert=${error.response.data.message}`;
        }
      });
  }

  render() {
    return this.state.isClient ? this.renderClientSide() : this.renderServerSide();
  }

  renderClientSide() {
    const { buttonTitle, models, point } = this.props;
    const { inFlight, pointsAwarded, selectedActionName } = this.state;

    return (
      <div className="ActionPoint">
        {models.length === 0
          ? <div>Actions have been created for all models.</div>
          : (
            <form>
              <div className="body">
                <div>
                  <label>Model</label>
                  {models.length > 0 && (
                    <select
                      defaultValue={selectedActionName}
                      disabled={point !== null}
                      onChange={(event) => this.updateSelectedModel(event.target.value)}
                    >
                      {models.map((model, index) => (
                        <option
                          key={index}
                          value={model.actionName}
                        >
                          {model.friendlyName}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div>
                  <label>When</label>
                  <input
                    disabled
                    type="text"
                    value="created"
                  />
                </div>
              </div>
              <div className="previewAction">
                <label>Preview Action Name</label>
                <input
                  disabled
                  type="text"
                  value={selectedActionName}
                />
              </div>
              <label>Points to be Awarded</label>
              <input
                min={0}
                onChange={(event) => this.updatePointsAwarded(event.target.value)}
                type="number"
                value={pointsAwarded}
              />
              <div className="footer">
                <button
                  disabled={inFlight}
                  onClick={point ? this.handleUpdatePoint : this.handleCreateNewPoint}
                  type="button"
                >
                  {inFlight ? 'Sending' : buttonTitle}
                </button>
              </div>
            </form>
          )
        }
      </div>
    );
  }

  renderServerSide() {
    const {
      authenticityToken,
      buttonTitle,
      models,
      point,
      serverSideUrl } = this.props;

    return (
      <div className="ActionPoint">
        {models.length === 0
          ? <div>Actions have been created for all models.</div>
          : (
            <form action={serverSideUrl} method="post">
              <div className="body">
                <input name="authenticity_token" type="hidden" value={authenticityToken} />
                {point && <input name="_method" type="hidden" value="put" />}
                <div>
                  <label>Model</label>
                  <select
                    defaultValue={point?.actionName}
                    disabled={point !== null}
                    name="action_point[action_name]"
                  >
                    {models.map((model, index) => (
                      <option
                        key={index}
                        value={model.actionName}
                      >
                        {model.friendlyName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>When</label>
                  <input
                    disabled
                    type="text"
                    value="created"
                  />
                </div>
              </div>
              <label>Points to be  awarded</label>
              <input
                defaultValue={point?.pointsAwarded || 0}
                min="0"
                name="action_point[points_awarded]"
                type="number"
              />
              <div className="footer">
                <button type="submit">{buttonTitle}</button>
              </div>
            </form>
          )
        }
      </div>
    );
  }

  updatePointsAwarded(newPointsAwarded: string) {
    this.setState({
      pointsAwarded: parseInt(newPointsAwarded, 10) || 0
    });
  }

  updateSelectedModel(selectedActionName: string) {
    this.setState({
      selectedActionName
    });
  }
}

export default ActionPoint;
