import * as React from 'react';
import {
  DragDropContext,
  DragStart,
  DropResult
} from 'react-beautiful-dnd';
import { isEqual, sortBy } from 'lodash';
import axios from 'axios';

import { Member } from './interfaces';
import MemberList from './MemberList';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string,
  cohortId: string;
  createEnrolmentUrl: string;
  enrolments: Member[];
  groupId: string;
  members: Member[];
  showCohortUrl: string;
  token: string;
  updateEnrolmentUrl: string;
}

interface State {
  draggingId: string;
  enrolments: Member[];
  enrolmentsSelected: string[];
  inFlight: boolean;
  isClient: boolean;
  members: Member[];
  membersSelected: string[];
}

class EnrolmentManager extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleSave = this.handleSave.bind(this);

    const { enrolments, members } = props;

    this.state = {
      draggingId: '',
      enrolments,
      enrolmentsSelected: [],
      inFlight: false,
      isClient: false,
      members,
      membersSelected: []
    };
  }

  addEnrolments() {
    const { enrolments, members, membersSelected } = this.state;
    if (membersSelected.length === 0) {
      return;
    }
    const newEnrolments = members.filter((member) => membersSelected.includes(member.id));
    this.setState({
      draggingId: '',
      enrolments: sortBy([...enrolments, ...newEnrolments], 'displayName'),
      enrolmentsSelected: [],
      members: sortBy(members.filter((member) => !membersSelected.includes(member.id)), 'displayName'),
      membersSelected: []
    });
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleDragEnd(dropResult: DropResult) {
    const { destination, source } = dropResult;

    if (!destination || source.droppableId === destination.droppableId) {
      this.setState({
        draggingId: ''
      });
      return;
    }

    if (destination.droppableId === 'enrolments') {
      this.addEnrolments();
    } else {
      this.removeEnrolments();
    }
  }

  handleDragStart(start: DragStart) {
    const id = start.draggableId;
    const list = start.source.droppableId;
    const { enrolmentsSelected, membersSelected } = this.state;

    this.setState({
      draggingId: id,
      enrolmentsSelected: list === 'enrolments' && !enrolmentsSelected.includes(id) ? [id] : enrolmentsSelected,
      membersSelected: list === 'members' && !membersSelected.includes(id) ? [id] : membersSelected
    });
  }

  handleSave() {
    const {
      cohortId,
      enrolments,
      groupId,
      showCohortUrl,
      token,
      updateEnrolmentUrl
    } = this.props;
    const newEnrolments = this.state.enrolments.filter((enrolment) => !enrolments.includes(enrolment));
    const removedEnrolments = enrolments.filter((enrolment) => this.state.members.includes(enrolment));

    if (newEnrolments.length === 0 && removedEnrolments.length === 0) {
      return;
    }

    this.setState({ inFlight: true });

    axios
      .put(
        updateEnrolmentUrl,
        {
          add: newEnrolments.map((member: Member) => ({
            cohortId,
            groupId,
            membershipId: member.id
          })),
          remove: removedEnrolments.map((member: Member) => ({ membershipId: member.id })),
          type: 'enrolment'
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        window.location.href = `${showCohortUrl}?notice=Enrolments successfully updated.`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.response.status === 403) {
          alert(error.response.statusText);
          window.location.href = '/';
        } else {
          window.location.href = `${showCohortUrl}?alert=Some enrolments could not be updated.`;
        }
        this.setState({ inFlight: false });
      });
  }

  removeEnrolments() {
    const { enrolments, enrolmentsSelected, members } = this.state;
    if (enrolmentsSelected.length === 0) {
      return;
    }
    const newMembers = enrolments.filter((member) => enrolmentsSelected.includes(member.id));
    this.setState({
      draggingId: '',
      enrolments: sortBy(enrolments.filter((member) => !enrolmentsSelected.includes(member.id)), 'displayName'),
      enrolmentsSelected: [],
      members: sortBy([...members, ...newMembers], 'displayName'),
      membersSelected: []
    });
  }

  render() {
    return (
      <div className="EnrolmentManager">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const {
      draggingId,
      enrolments,
      enrolmentsSelected,
      inFlight,
      members,
      membersSelected
    } = this.state;

    return (
      <>
        {members.length === 0 && enrolments.length === 0 && <h4>There are no members enrolled in this cohort.</h4>}
        <p>
          You can drag and drop members to enrol them into this cohort.
        </p>
        <p>
          Multiple items can be selected at once.
        </p>
        <div className="lists">
          <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
            <MemberList
              draggingId={draggingId}
              droppableId="members"
              key="members"
              label="Members"
              members={members}
              onSelect={(selection) => this.setState({ enrolmentsSelected: [], membersSelected: selection })}
              selected={membersSelected}
            />
            <div className="buttons">
              <button disabled={membersSelected.length === 0} onClick={() => this.addEnrolments()} type="button">
                &#10217;
              </button>
              <button disabled={enrolmentsSelected.length === 0} onClick={() => this.removeEnrolments()} type="button">
                &#10216;
              </button>
            </div>
            <MemberList
              draggingId={draggingId}
              droppableId="enrolments"
              key="enrolments"
              label="enrolments"
              members={enrolments}
              onSelect={(selection) => this.setState({ enrolmentsSelected: selection, membersSelected: [] })}
              selected={enrolmentsSelected}
            />
          </DragDropContext>
        </div>
        <button
          className="saveButton"
          disabled={inFlight || (isEqual(members, this.props.members) && isEqual(enrolments, this.props.enrolments))}
          onClick={this.handleSave}
          type="submit"
        >
          Save
        </button>
      </>
    );
  }

  renderServerSide() {
    const { authenticityToken, createEnrolmentUrl, members } = this.props;
    return (
      members.length === 0
        ? <h3>All the members have been enrolled for this cohort.</h3>
        : (
          <form action={createEnrolmentUrl} method="post">
            <div className="content">
              <input name="authenticity_token" type="hidden" value={authenticityToken} />
              <label>Select a member to enrol</label>
              <select name="enrolment[membership_id]">
                {members.map((member) => (
                  <option key={member.id} value={member.id}>{member.displayName}</option>
                ))}
              </select>
            </div>
            <div className="footer">
              <button type="submit">Create Enrolment</button>
            </div>
          </form>
        )
    );
  }
}

export default EnrolmentManager;
