import * as React from 'react';

import NewConversationList from './NewConversationList';
import PopupLayout from './PopupLayout';
import { Profile } from './interfaces';

interface Props {
  allProfiles: Profile[];
  conversationUrl: string;
  delayTime: number;
  isOnline: boolean;
  onClickConversation:((counterpart: Profile) => void);
  onUpdateDelayTime: (() => void);
  onUpdateOnlineStatus: ((isOnline: boolean) => void);
  onUpdateShowOverlay: ((showOverlay: boolean) => void);
  profileId: string;
}

interface State {
  showPopup: boolean;
}

class NewConversation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPopup: false
    };
  }

  render() {
    const { isOnline } = this.props;
    return (
      <div className="NewConversation">
        <button
          disabled={!isOnline}
          onClick={() => this.setState({ showPopup: true })}
          type="button"
        >
          &#43;
        </button>
        {this.state.showPopup && isOnline && this.renderPopup()}
      </div>
    );
  }

  renderPopup() {
    const {
      allProfiles,
      conversationUrl,
      onClickConversation,
      profileId } = this.props;
    return (
      <PopupLayout
        onClose={() => this.setState({ showPopup: false })}
        title="Start New Conversation"
      >
        <NewConversationList
          conversationUrl={conversationUrl}
          counterparts={allProfiles}
          onCancel={() => this.setState({ showPopup: false })}
          onClickConversation={(counterpart: Profile) => {
            onClickConversation(counterpart);
            this.setState({ showPopup: false });
          }}
          profileId={profileId}
        />
      </PopupLayout>
    );
  }
}

export default NewConversation;
