import * as React from 'react';

interface Props {
  conversationSearchTerm: string;
  onChangeConversationInput: ((inputValue: string) => void);
}

function MessengerSearch(props: Props) {
  return (
    <div className="MessengerSearch">
      <form onSubmit={(event) => event.preventDefault()}>
        <input
          onChange={(event) => props.onChangeConversationInput(event.target.value)}
          placeholder="Search..."
          type="text"
          value={props.conversationSearchTerm}
        />
      </form>
      <span aria-label="Magnifying Glass" role="img">🔍</span>
    </div>
  );
}

export default MessengerSearch;
