import * as React from 'react';
import axios from 'axios';

import { Profile } from './interfaces';
import Spinner from './Spinner';
import headers from '../utils/headersGenerator';
import isBlank from '../utils/isBlank';

interface Message {
  content: string;
  createdAt: string;
  id: string;
  recipientId: string;
}

interface Props {
  conversationUrl: string;
  counterpart?: Profile;
  delayTime: number;
  isOnline: boolean;
  loading: boolean;
  messages: Message[];
  onSendMessage: ((message: Message) => void);
  onUpdateDelayTime: (() => void);
  onUpdateOnlineStatus: ((isOnline: boolean) => void);
  onUpdateShowOverlay: ((showOverlay: boolean) => void);
  profileId: string;
  token: string;
}

interface State {
  intervalId: number;
  maxScrollHeight: number;
  messages: Message[];
  newMessage: string;
  requesting: boolean;
  sending: boolean;
}

class Conversation extends React.Component<Props, State> {
  private readonly bottomRef: React.RefObject<HTMLDivElement>;
  private readonly listRef: React.RefObject<HTMLDivElement>;

  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.bottomRef = React.createRef();
    this.listRef = React.createRef();
    const { delayTime, messages } = props;
    const reversedMessages = messages.reverse();

    this.state = {
      intervalId: window.setInterval(() => this.fetchMessages(), delayTime),
      maxScrollHeight: 0,
      messages: reversedMessages,
      newMessage: '',
      requesting: false,
      sending: false
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: Props) {
    const { delayTime } = this.props;
    if (prevProps.delayTime !== delayTime) {
      window.clearInterval(this.state.intervalId);
      this.setState({
        intervalId: window.setInterval(() => this.fetchMessages(), delayTime)
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.signal.cancel('Request cancelled.');
  }

  fetchMessages() {
    const {
      conversationUrl,
      counterpart,
      isOnline,
      onUpdateDelayTime,
      onUpdateOnlineStatus,
      profileId,
      token } = this.props;
    if (!counterpart) {
      return;
    }

    if (!isOnline) {
      onUpdateOnlineStatus(navigator.onLine);
      onUpdateDelayTime();
      return;
    }

    const lastReceived = this.lastReceived();
    axios
      .get(
        `${conversationUrl}/${counterpart?.id}`,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: {
            fetchNewMessages: true,
            lastReceivedAt: lastReceived?.createdAt,
            lastReceivedId: lastReceived?.id,
            profileId
          }
        }
      )
      .then((response) => {
        if (response.data.messages.length === 0) {
          return;
        }
        this.setState({
          messages: [...this.state.messages, ...response.data.messages]
        }, () => this.scrollToBottom());
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.message === 'Network Error') {
          onUpdateOnlineStatus(false);
        } else {
          console.error(error);
        }
      });
  }

  lastReceived() {
    return this.state.messages.slice(-1)[0];
  }

  render() {
    const { counterpart, isOnline, loading, profileId } = this.props;
    const { messages, newMessage, requesting, sending } = this.state;

    if (counterpart === null) {
      return (
        <div className="Conversation">
          <p>Select a conversation from the list to start.</p>
        </div>
      );
    }
    return (
      <div className="Conversation">
        <div className="header">
          <img
            alt="avatar"
            src={this.props.counterpart?.avatar}
          />
          <span className="displayName">{this.props.counterpart?.displayName}</span>
        </div>
        {(requesting || loading) && <div className="spinnerContainer"><Spinner size={30} /></div>}
        {!loading
          && (
            <div className="body">
              <div
                className="messages"
                onScroll={(event) => this.scroll(event.target as HTMLUListElement)}
                ref={this.listRef}
              >
                {messages.map((message) => (
                  <div
                    className={`message ${message.recipientId === profileId ? 'received' : 'sent'}`}
                    key={message.id}
                  >
                    <span id={message.id} title={new Date(message.createdAt).toLocaleString()}>{message.content}</span>
                  </div>
                ))}
                <div ref={this.bottomRef} />
              </div>
              <div>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.submitNewMessage();
                  }}
                >
                  <input
                    disabled={loading || sending}
                    onChange={(event) => this.setState({ newMessage: event.target.value })}
                    placeholder="Reply"
                    type="text"
                    value={newMessage}
                  />
                  <input
                    disabled={isBlank(newMessage) || !isOnline || sending}
                    type="submit"
                    value="Send"
                  />
                </form>
              </div>
            </div>
          )}
      </div>
    );
  }

  scroll(list: HTMLUListElement) {
    if (list.scrollTop < 40 && !this.state.requesting && this.state.maxScrollHeight < list.scrollHeight) {
      this.setState({
        maxScrollHeight: list.scrollHeight,
        requesting: true
      }, () => {
        const { conversationUrl, counterpart, token, profileId } = this.props;
        const { messages } = this.state;

        axios
          .get(
            `${conversationUrl}/${counterpart?.id}`,
            {
              cancelToken: this.signal.token,
              headers: headers(token),
              params: {
                firstCreatedAt: this.state.messages[0].createdAt,
                profileId
              }
            }
          )
          .then((response) => {
            this.setState({
              messages: [...response.data.messages.reverse(), ...messages],
              requesting: false
            });
            list.scrollTo(0, list.scrollHeight - this.state.maxScrollHeight);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.debug(error.message);
            } else {
              alert('Something went wrong, please refresh the page and try again.');
              console.error(error);
            }
          });
      });
    }
  }

  scrollToBottom() {
    if (this.listRef.current) {
      this.listRef.current.scrollTo(0, this.bottomRef.current?.offsetTop || 0);
    }
  }

  submitNewMessage() {
    const {
      conversationUrl,
      counterpart,
      isOnline,
      onSendMessage,
      onUpdateOnlineStatus,
      onUpdateShowOverlay,
      profileId,
      token } = this.props;

    if (!isOnline) {
      onUpdateOnlineStatus(navigator.onLine);
      onUpdateShowOverlay(true);
    }

    const content = this.state.newMessage;
    this.setState({
      sending: true
    }, () => {
      axios
        .post(
          conversationUrl,
          {
            privateMessage: {
              content,
              profile: profileId,
              recipient: counterpart?.id
            }
          },
          {
            cancelToken: this.signal.token,
            headers: headers(token)
          }
        )
        .then((response) => {
          this.setState({
            messages: [...this.state.messages, response.data.message],
            newMessage: '',
            sending: false
          });
          onSendMessage(response.data.message);
          this.scrollToBottom();
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.debug(error.message);
          } else if (error.message === 'Network Error') {
            onUpdateOnlineStatus(false);
            this.setState({
              sending: false
            });
          } else {
            console.error(error);
          }
        });
    });
  }
}

export default Conversation;
