import * as React from 'react';

import Button from './Button';
import { MultipleChoiceOption } from './interfaces';
import images from '../utils/images';

interface Props {
  onChangeLabel: ((value: string) => void);
  onRemoveOption: (() => void);
  onSelectValue: ((value: string) => void);
  option: MultipleChoiceOption;
  optionsCount: number;
  questionnaireType: 'quiz' | 'survey';
}

function MultipleChoiceEditor(props: Props) {
  const { correct, freeform, label } = props.option;
  return (
    <div className="MultipleChoiceEditor">
      <img alt="Drag Handler" src={images.drag} />
      <div className="content">
        <div className="prompt">
          <label>
            {freeform ? 'Freeform prompt: ' : 'Option:'}
            <input
              onChange={(event) => props.onChangeLabel(event.target.value)}
              placeholder={freeform
                ? 'Explain how to answer the question...'
                : 'Choose a label for this option...'
              }
              type="text"
              value={label}
            />
          </label>
          <div className="button">
            <Button
              disabled={props.optionsCount <= 2}
              imgAlt="Remove option"
              imgSrc={images.minus}
              onClick={async () => props.onRemoveOption()}
              size="medium"
            />
          </div>
        </div>
        <div className="settings">
          {!freeform && props.questionnaireType === 'quiz' && (
            <select
              onChange={(event) => props.onSelectValue(event.target.value)}
              value={correct ? 'correct' : 'incorrect'}
            >
              <option value="correct">Mark as correct</option>
              <option value="incorrect">Mark as incorrect</option>
            </select>
          )}
        </div>
      </div>
    </div>
  );
}

export default MultipleChoiceEditor;
