import * as React from 'react';
import axios from 'axios';

import Conversation from './Conversation';
import ConversationList from './ConversationList';
import NewConversation from './NewConversation';
import { Profile } from './interfaces';
import headers from '../utils/headersGenerator';

interface Message {
  content: string;
  createdAt: string;
  id: string;
  recipientId: string;
}

interface Props {
  allProfiles: Profile[];
  conversationUrl: string;
  counterpart?: Profile;
  counterparts: Profile[];
  messages: Message[];
  newConversation?: boolean;
  profileId: string;
  token: string;
  unreadMessagesProfileIds: string[];
}

interface State {
  counterpart?: Profile;
  counterparts: Profile[];
  delayTime: number;
  isClient: boolean;
  isOnline: boolean;
  loading: boolean;
  messages: Message[];
  showOverlay: boolean;
  unreadMessagesProfileIds: string[];
}

// Maxiumum number of delay time is 5 minutes
const maxDelayTime = 300000;

class Messenger extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleUpdateDelayTime = this.handleUpdateDelayTime.bind(this);
    const { counterpart, counterparts, messages, unreadMessagesProfileIds } = this.props;
    this.state = {
      counterpart,
      counterparts,
      delayTime: 1000,
      isClient: false,
      isOnline: false,
      loading: false,
      messages,
      showOverlay: true,
      unreadMessagesProfileIds
    };
  }

  clickConversation(newCounterpart: Profile) {
    const { counterpart, counterparts, messages } = this.state;
    const filteredCounterparts = counterparts.filter((profile) => profile.id !== counterpart?.id);
    if (newCounterpart.id === counterpart?.id) {
      return;
    }
    if (counterpart && messages.length === 0) {
      this.setState({
        counterparts: filteredCounterparts
      });
    }
    if (!counterparts.find((profile: Profile) => profile.id === newCounterpart.id)) {
      const restCounterparts = messages.length === 0 ? filteredCounterparts : counterparts;
      this.setState({
        counterparts: [newCounterpart, ...restCounterparts]
      });
    }

    this.setState({
      loading: true
    }, () => this.getMessages(newCounterpart));
  }

  componentDidMount() {
    this.setState({
      isClient: true,
      isOnline: window.navigator.onLine
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  async getMessages(newCounterpart: Profile) {
    const { conversationUrl, profileId, token } = this.props;
    await axios
      .get(
        `${conversationUrl}/${newCounterpart.id}`,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: { profileId }
        }
      )
      .then((response) => {
        this.setState({
          counterpart: newCounterpart,
          loading: false,
          messages: response.data.messages
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
        }
      });
  }

  handleSendMessage(message: Message) {
    this.setState({
      messages: [...this.state.messages, message]
    });
    this.reorderProfiles();
  }

  handleUpdateDelayTime() {
    const newDelayTime = this.state.delayTime * 1.5;
    const onlineStatus = navigator.onLine;
    const updatedDelaytime = newDelayTime >= maxDelayTime ? maxDelayTime : newDelayTime;

    // Return default delay time (1000ms) if the site goes back to online
    this.setState({
      delayTime: onlineStatus ? 1000 : updatedDelaytime
    });
  }

  render() {
    return (
      <div className="Messenger">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const {
      allProfiles,
      conversationUrl,
      profileId,
      token
    } = this.props;
    const {
      counterpart,
      counterparts,
      delayTime,
      isOnline,
      loading,
      messages,
      showOverlay,
      unreadMessagesProfileIds } = this.state;
    return (
      <div className="clientSide">
        <NewConversation
          allProfiles={allProfiles}
          conversationUrl={conversationUrl}
          delayTime={delayTime}
          isOnline={isOnline}
          onClickConversation={(newCounterpart) => this.clickConversation(newCounterpart)}
          onUpdateDelayTime={this.handleUpdateDelayTime}
          onUpdateOnlineStatus={(onlineStatus) => this.updateOnlineStatus(onlineStatus)}
          onUpdateShowOverlay={(overlayStatus) => this.updateShowOverlay(overlayStatus)}
          profileId={profileId}
        />
        <ConversationList
          conversationUrl={conversationUrl}
          counterpartId={counterpart?.id}
          counterparts={counterparts}
          delayTime={delayTime}
          isOnline={isOnline}
          key={counterparts[0]?.id}
          onClickConversation={(newCounterpart) => this.clickConversation(newCounterpart)}
          onGetUnreadMessagesProfileIds={(newProfileIds) => this.updateUnreadMessagesProfileIds(newProfileIds)}
          onUpdateDelayTime={this.handleUpdateDelayTime}
          onUpdateOnlineStatus={(onlineStatus) => this.updateOnlineStatus(onlineStatus)}
          onUpdateShowOverlay={(overlayStatus) => this.updateShowOverlay(overlayStatus)}
          profileId={profileId}
          token={token}
          unreadMessagesProfileIds={unreadMessagesProfileIds}
        />
        <Conversation
          conversationUrl={conversationUrl}
          counterpart={counterpart}
          delayTime={delayTime}
          isOnline={isOnline}
          key={`conversation-${counterpart?.id}`}
          loading={loading}
          messages={messages}
          onSendMessage={(message: Message) => this.handleSendMessage(message)}
          onUpdateDelayTime={this.handleUpdateDelayTime}
          onUpdateOnlineStatus={(onlineStatus) => this.updateOnlineStatus(onlineStatus)}
          onUpdateShowOverlay={(overlayStatus) => this.updateShowOverlay(overlayStatus)}
          profileId={profileId}
          token={token}
        />
        {!isOnline && showOverlay
          && (
            <div className="overlay" onClick={() => this.updateShowOverlay(false)}>
              <button
                aria-label="Close"
                className="closeButton"
                onClick={() => this.updateShowOverlay(false)}
                type="button"
              >
                &times;
              </button>
              <div className="text">
                <span>Your internet connection is unstable.</span>
                <span className="smaller">
                  Some features may not be available.
                </span>
              </div>
            </div>
          )}
      </div>
    );
  }

  renderServerSide() {
    return (
      <div className="serverSide">Enable JavaScript to send and receive messages.</div>
    );
  }

  reorderProfiles() {
    const { counterpart, counterparts } = this.state;
    const counterpartProfile = counterparts.find((profile) => profile.id === counterpart?.id);
    if (counterpartProfile) {
      this.setState({
        counterparts: [counterpartProfile, ...counterparts.filter((profile) => profile.id !== counterpart?.id)]
      });
    } else if (counterpart && counterparts.find((profile) => profile.id === counterpart?.id)) {
      this.setState({
        counterparts: [counterpart, ...counterparts]
      });
    }
  }

  updateOnlineStatus(onlineStatus: boolean) {
    this.setState({
      isOnline: onlineStatus
    });
  }

  updateShowOverlay(showOverlay: boolean) {
    this.setState({
      showOverlay
    });
  }

  updateUnreadMessagesProfileIds(newProfileIds: string[]) {
    this.setState({
      unreadMessagesProfileIds: [...newProfileIds]
    });
  }
}

export default Messenger;
