import * as React from 'react';
import Popup from 'reactjs-popup';

import ErrorMessage from './ErrorMessage';
import Spinner from './Spinner';

interface Props {
  children: React.ReactNode;
  errorMessages?: string | string[];
  loading?: boolean;
  onClose: (() => void);
  title: string;
  width?: string;
}

interface State {
  windowOffsetX: number;
  windowOffsetY: number;
}

class PopupLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      windowOffsetX: 0,
      windowOffsetY: 0
    };
  }

  componentDidMount() {
    const windowOffsetX = window.scrollX;
    const windowOffsetY = window.scrollY;
    document.body.setAttribute('style', `position: fixed; top: -${windowOffsetY}px; left: -${windowOffsetX}px; right: 0;`);
    this.setState({
      windowOffsetX,
      windowOffsetY
    });
  }

  componentWillUnmount() {
    document.body.setAttribute('style', '');
    window.scrollTo(this.state.windowOffsetX, this.state.windowOffsetY);
  }

  render() {
    const {
      children,
      errorMessages,
      loading,
      onClose,
      title,
      width
    } = this.props;
    return (
      <div
        onClick={onClose}
        onKeyDown={(event) => event.key === 'Escape' && onClose()}
      >
        <Popup
          className="PopupLayout"
          closeOnDocumentClick={false}
          closeOnEscape={false}
          contentStyle={{ width: width || '33em' }}
          on="focus"
          open
        >
          <div className="popup">
            <div className="header">
              <h3>{title}</h3>
              <button
                aria-label="Close"
                className="closeButton"
                onClick={onClose}
                type="button"
              >
                &times;
              </button>
            </div>
            <div className="body">
              {errorMessages && errorMessages.length > 0 && <ErrorMessage messages={errorMessages} />}
              {children}
              {loading && <div className="spinnerContainer"><Spinner size={60} /></div>}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default PopupLayout;
