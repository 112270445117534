import * as React from 'react';

import images from '../utils/images';
import isBlank from '../utils/isBlank';

interface Props {
  name?: string;
  onBlur?: (() => void);
  onEditing?: (() => void);
  onUpdateTitle: ((title: string) => void);
  showErrorIcon: boolean;
  title: string;
}

class TitleEditor extends React.Component<Props> {
  private readonly textareaRef: React.RefObject<HTMLTextAreaElement>;

  constructor(props: Props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.textareaRef = React.createRef();
  }

  handleBlur() {
    const { onBlur, onUpdateTitle, title } = this.props;
    if (isBlank(title)) {
      return;
    }
    // Trim extra spaces from the end of the title when the focus is moved
    onUpdateTitle(title.trim());
    if (onBlur) {
      onBlur();
    }
  }

  handleClick() {
    if (this.textareaRef.current === document.activeElement) {
      return;
    }
    this.textareaRef.current?.select();
    if (this.props.onEditing) {
      this.props.onEditing();
    }
  }

  handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
    }
  }

  render() {
    const { name, title } = this.props;
    return (
      <div className="TitleEditor">
        <textarea
          name={name}
          onBlur={this.handleBlur}
          onChange={(event) => this.props.onUpdateTitle(event.target.value)}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          ref={this.textareaRef}
          required
          tabIndex={0}
          value={title}
        />
        {this.props.showErrorIcon && <img alt="Error sign" src={images.error} />}
      </div>
    );
  }
}

export default TitleEditor;
