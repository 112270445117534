import * as React from 'react';

import Checkbox from './Checkbox';
import { LessonSettings } from './interfaces';

interface Props {
  lessonSettings: LessonSettings;
  onClick: (() => void);
  onMouseEnterSettings: (() => void);
  onMouseLeaveSettings: (() => void);
  onSaveLessonSettings: ((lessonSettings: LessonSettings) => void);
}

function SequenceLessonSettings(props: Props) {
  const { commentsEnabled, daysToRelease, points, slug } = props.lessonSettings;
  return (
    <div
      className="SequenceLessonSettings"
      onMouseEnter={props.onMouseEnterSettings}
      onMouseLeave={props.onMouseLeaveSettings}
    >
      <form>
        <div className="content">
          <label>Slug:</label>
          <input
            onChange={(event) => props.onSaveLessonSettings(
              {
                ...props.lessonSettings,
                slug: event.target.value
              }
            )}
            onClick={(event) => {
              if (event.currentTarget === document.activeElement) {
                return;
              }
              event.currentTarget.select();
              props.onClick();
            }}
            type="text"
            value={slug}
          />
        </div>
        <div className="content">
          <label>Release After (days):</label>
          <input
            min={0}
            onChange={(event) => props.onSaveLessonSettings(
              {
                ...props.lessonSettings,
                daysToRelease: event.target.value
              }
            )}
            type="number"
            value={daysToRelease}
          />
        </div>
        <div className="content">
          <label>Points:</label>
          <input
            min={0}
            onChange={(event) => {
              props.onSaveLessonSettings(
                {
                  ...props.lessonSettings,
                  points: event.target.value
                }
              );
            }}
            type="number"
            value={points}
          />
        </div>
        <div className="content">
          <label>Comments Enabled:</label>
          <Checkbox
            checked={commentsEnabled}
            onClick={() => props.onSaveLessonSettings(
              {
                ...props.lessonSettings,
                commentsEnabled: !commentsEnabled
              }
            )}
          />
        </div>
      </form>
    </div>
  );
}

export default SequenceLessonSettings;
