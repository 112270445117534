import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { MembershipQuestion } from './interfaces';
import MembershipQuestionEditor from './MembershipQuestionEditor';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import getDraggableStyle from '../utils/getDraggableStyle';
import headers from '../utils/headersGenerator';
import reorder from '../utils/reorder';

interface Props {
  groupId: string;
  groupShowUrl: string;
  questions: MembershipQuestion[];
  token: string;
  updateUrl: string;
}

interface State {
  editingQuestionId: string;
  expandedQuestions: string[];
  isClient: boolean;
  isDirty: boolean;
  questions: MembershipQuestion[];
}

class MembershipQuestionsEditor extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleUpdateQuestion = this.handleUpdateQuestion.bind(this);
    this.state = {
      editingQuestionId: '',
      expandedQuestions: [],
      isClient: false,
      isDirty: false,
      questions: this.props.questions
    };
  }

  componentDidMount() {
    this.setState({
      isClient: true
    });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleClickAdd() {
    const questions = [...this.state.questions, { id: uuidv4(), prompt: 'Add prompt' }];
    this.setState({
      isDirty: !isEqual(questions, this.props.questions),
      questions
    });
  }

  handleClickSave() {
    const { groupId, groupShowUrl, token, updateUrl } = this.props;
    axios
      .patch(
        updateUrl,
        {
          membershipQuestions: this.state.questions,
          groupId
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        this.setState({
          isDirty: false
        }, () => {
          window.location.href = `${groupShowUrl}?notice=Membership questions successfully submitted.`;
        });
      })
      .catch((error) => {
        this.setState({
          isDirty: false
        });
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          console.error(error);
          window.location.href = `${groupShowUrl}?alert=Membership questions could not be submitted.`;
        }
      });
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    const questions = reorder(this.state.questions, result.source.index, result.destination.index);
    this.setState({
      isDirty: !isEqual(questions, this.props.questions),
      questions
    });
  }

  handleUpdateQuestion(question: MembershipQuestion) {
    const questions = this.state.questions.map((oldQuestion) => oldQuestion.id === question.id ? question : oldQuestion);
    this.setState({
      isDirty: !isEqual(questions, this.props.questions),
      questions
    });
  }

  isDisabled() {
    const tooShort = this.state.questions.some((question) => (
      question.prompt.trim().length < 2
    ));

    return !this.state.isDirty || tooShort;
  }

  onClickArrowHead(id: string) {
    const { expandedQuestions } = this.state;
    this.setState({
      expandedQuestions: expandedQuestions.includes(id)
        ? expandedQuestions.filter((expanded) => expanded !== id)
        : [...expandedQuestions, id]
    });
  }

  removeQuestion(id: string) {
    const questions = this.state.questions.filter((question) => question.id !== id);
    this.setState({
      expandedQuestions: this.state.expandedQuestions.filter((expanded) => expanded !== id),
      isDirty: !isEqual(questions, this.props.questions),
      questions
    });
  }

  render() {
    const { expandedQuestions, isClient, isDirty, questions } = this.state;
    if (!isClient) {
      return <>Please, enable JavaScript to edit the membership questions.</>;
    }
    return (
      <div className="MembershipQuestionsEditor">
        <div className="buttons">
          <div className="left">
            <button onClick={this.handleClickAdd} type="button">
              Add Question
            </button>
          </div>
          <div className="right">
            <button
              disabled={expandedQuestions.length === 0}
              onClick={() => this.setState({ expandedQuestions: [] })}
              type="button"
            >
              Minimise All
            </button>
            <button
              disabled={expandedQuestions.length === questions.length}
              onClick={() => this.setState({ expandedQuestions: questions.map((question) => question.id) })}
              type="button"
            >
              Expand All
            </button>
          </div>
        </div>
        <div className="content">
          {questions.length === 0
            ? 'There are no membership questions for this group.'
            : this.renderQuestions()
          }
        </div>
        <div className="footer">
          <button
            disabled={this.isDisabled()}
            onClick={() => this.handleClickSave()}
            type="button"
          >
            Save
          </button>
          <UnsavedChangesPrompt isDirty={isDirty} />
        </div>
      </div>
    );
  }

  renderQuestions() {
    const { editingQuestionId, expandedQuestions, questions } = this.state;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId="MembershipQuestionsEditor">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {questions.map((question, index) => {
                const { id } = question;
                return (
                  <Draggable
                    disableInteractiveElementBlocking={id !== editingQuestionId}
                    draggableId={id}
                    index={index}
                    key={id}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        ref={providedDraggable.innerRef}
                        style={getDraggableStyle(providedDraggable.draggableProps.style, questions.length === index + 1)}
                      >
                        <MembershipQuestionEditor
                          expanded={expandedQuestions.includes(id)}
                          index={index}
                          onClickArrowHead={() => this.onClickArrowHead(id)}
                          onEditingPrompt={() => this.setState({ editingQuestionId: id })}
                          onMouseLeave={() => this.setState({ editingQuestionId: '' })}
                          onRemove={() => this.removeQuestion(id)}
                          onUpdate={this.handleUpdateQuestion}
                          question={question}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default MembershipQuestionsEditor;
