import * as React from 'react';
import {
  DragDropContext,
  DragStart,
  DropResult
} from 'react-beautiful-dnd';
import { isEqual, sortBy } from 'lodash';
import axios from 'axios';

import { Member } from './interfaces';
import MemberList from './MemberList';
import headers from '../utils/headersGenerator';

interface Props {
  authenticityToken: string,
  cohortId: string;
  createFacilitatorUrl: string;
  facilitators: Member[];
  members: Member[];
  showCohortUrl: string;
  token: string;
  updateCohortUrl: string;
}

interface State {
  draggingId: string;
  facilitators: Member[];
  facilitatorsSelected: string[];
  inFlight: boolean;
  isClient: boolean;
  members: Member[];
  membersSelected: string[];
}

class FacilitatorManager extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {
      draggingId: '',
      facilitators: this.props.facilitators,
      facilitatorsSelected: [],
      inFlight: false,
      isClient: false,
      members: this.props.members,
      membersSelected: []
    };
  }

  addFacilitators() {
    const { facilitators, members, membersSelected } = this.state;
    if (membersSelected.length === 0) {
      return;
    }
    const newfacilitators = members.filter((member) => membersSelected.includes(member.id));
    this.setState({
      draggingId: '',
      facilitators: sortBy([...facilitators, ...newfacilitators], 'displayName'),
      facilitatorsSelected: [],
      members: sortBy(members.filter((member) => !membersSelected.includes(member.id)), 'displayName'),
      membersSelected: []
    });
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  componentWillUnmount() {
    this.signal.cancel('Request cancelled.');
  }

  handleDragEnd(dropResult: DropResult) {
    const { destination, source } = dropResult;

    if (!destination || source.droppableId === destination.droppableId) {
      this.setState({
        draggingId: ''
      });
      return;
    }

    if (destination.droppableId === 'facilitators') {
      this.addFacilitators();
    } else {
      this.removeFacilitators();
    }
  }

  handleDragStart(start: DragStart) {
    const id = start.draggableId;
    const list = start.source.droppableId;
    const { facilitatorsSelected, membersSelected } = this.state;

    this.setState({
      draggingId: id,
      facilitatorsSelected: list === 'facilitators' && !facilitatorsSelected.includes(id) ? [id] : facilitatorsSelected,
      membersSelected: list === 'members' && !membersSelected.includes(id) ? [id] : membersSelected
    });
  }

  handleSave() {
    const { cohortId, facilitators, showCohortUrl, token, updateCohortUrl } = this.props;
    const newfacilitators = this.state.facilitators.filter((facilitator) => !facilitators.includes(facilitator));
    const removedFacilitators = facilitators.filter((facilitator) => this.state.members.includes(facilitator));

    if (newfacilitators.length === 0 && removedFacilitators.length === 0) {
      return;
    }

    this.setState({ inFlight: true });

    axios
      .put(
        updateCohortUrl,
        {
          add: newfacilitators.map((facilitator: Member) => ({
            cohortId,
            membershipId: facilitator.id
          })),
          remove: removedFacilitators.map((facilitator: Member) => ({ facilitatorId: facilitator.id })),
          type: 'facilitator'
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        window.location.href = `${showCohortUrl}?notice=Facilitators successfully updated.`;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else if (error.response.status === 403) {
          alert(error.response.statusText);
          window.location.href = '/';
        } else {
          window.location.href = `${showCohortUrl}?alert=Some facilitators could not be updated.`;
        }
        this.setState({ inFlight: false });
      });
  }

  removeFacilitators() {
    const { facilitators, facilitatorsSelected, members } = this.state;
    if (facilitatorsSelected.length === 0) {
      return;
    }
    const newMembers = facilitators.filter((member) => facilitatorsSelected.includes(member.id));
    this.setState({
      draggingId: '',
      facilitators: sortBy(facilitators.filter((member) => !facilitatorsSelected.includes(member.id)), 'displayName'),
      facilitatorsSelected: [],
      members: sortBy([...members, ...newMembers], 'displayName'),
      membersSelected: []
    });
  }

  render() {
    return (
      <div className="FacilitatorManager">
        {this.state.isClient ? this.renderClientSide() : this.renderServerSide()}
      </div>
    );
  }

  renderClientSide() {
    const { draggingId, facilitators, facilitatorsSelected, inFlight, members, membersSelected } = this.state;

    return (
      <>
        {members.length === 0 && facilitators.length === 0 && <h4>There are no members enrolled in this cohort.</h4>}
        <p>
          You can drag and drop members from the member list to make them facilitators.
        </p>
        <p>
          Multiple items can be selected at once.
        </p>
        <div className="lists">
          <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
            <MemberList
              draggingId={draggingId}
              droppableId="members"
              key="members"
              label="Members"
              members={members}
              onSelect={(selection) => this.setState({ facilitatorsSelected: [], membersSelected: selection })}
              selected={membersSelected}
            />
            <div className="buttons">
              <button disabled={membersSelected.length === 0} onClick={() => this.addFacilitators()} type="button">
                &#10217;
              </button>
              <button disabled={facilitatorsSelected.length === 0} onClick={() => this.removeFacilitators()} type="button">
                &#10216;
              </button>
            </div>
            <MemberList
              draggingId={draggingId}
              droppableId="facilitators"
              key="facilitators"
              label="Facilitators"
              members={facilitators}
              onSelect={(selection) => this.setState({ facilitatorsSelected: selection, membersSelected: [] })}
              selected={facilitatorsSelected}
            />
          </DragDropContext>
        </div>
        <button
          className="saveButton"
          disabled={inFlight || (isEqual(members, this.props.members) && isEqual(facilitators, this.props.facilitators))}
          onClick={this.handleSave}
          type="submit"
        >
          Save
        </button>
      </>
    );
  }

  renderServerSide() {
    const { authenticityToken, createFacilitatorUrl, members } = this.props;
    return (
      members.length === 0
        ? <h3>There are no members enrolled in this cohort.</h3>
        : (
          <form action={createFacilitatorUrl} method="post">
            <div className="content">
              <input name="authenticity_token" type="hidden" value={authenticityToken} />
              <label>Select a member to create a facilitator</label>
              <select name="facilitator[membership_id]">
                {members.map((member) => (
                  <option key={member.id} value={member.id}>{member.displayName}</option>
                ))}
              </select>
            </div>
            <div className="footer">
              <button type="submit">Create Facilitator</button>
            </div>
          </form>
        )
    );
  }
}

export default FacilitatorManager;
