import * as React from 'react';

interface Props {
  accountPath: string;
  adminPath: string;
  becomeAdminPath?: string;
  contentPath: string;
  currentPath: string;
  groupsPath: string;
  logOutPath: string;
  messagesPath: string;
  notificationsPath: string;
  surveysCount: number;
  surveysPath: string;
  unreadMessageCount: number;
  unseenNotificationCount: number;
  userIsAdmin: boolean;
  userIsEditor: boolean;
}

const activePath = (currentPath: string, linkPath: string) => (
  currentPath.startsWith(linkPath.split('/', 2).join('/')) ? 'active' : ''
);

function AuthenticatedLinks(props: Props) {
  return (
    <div className="AuthenticatedLinks">
      <span><a className={activePath(props.currentPath, props.groupsPath)} href={props.groupsPath}>Groups</a></span>
      <span>
        <a className={activePath(props.currentPath, props.messagesPath)} href={props.messagesPath}>
          Messages
          <span className={`badge ${props.unreadMessageCount > 0 ? '' : 'none'}`}>
            <span>{props.unreadMessageCount > 9 ? '+' : props.unreadMessageCount}</span>
          </span>
        </a>
      </span>
      <span>
        <a className={activePath(props.currentPath, props.notificationsPath)} href={props.notificationsPath}>
          Notifications
          <span className={`badge ${props.unseenNotificationCount > 0 ? '' : 'none'}`}>
            <span>{props.unseenNotificationCount > 9 ? '+' : props.unseenNotificationCount}</span>
          </span>
        </a>
      </span>
      <span>
        <a className={activePath(props.currentPath, props.surveysPath)} href={props.surveysPath}>
          Surveys
          <span className={`badge ${props.surveysCount > 0 ? '' : 'none'}`}>
            <span>{props.surveysCount > 9 ? '+' : props.surveysCount}</span>
          </span>
        </a>
      </span>
      <span><a className={activePath(props.currentPath, props.accountPath)} href={props.accountPath}>Account</a></span>
      {(props.userIsAdmin || props.userIsEditor) && (
        <span><a className={activePath(props.currentPath, props.contentPath)} href={props.contentPath}>Content</a></span>
      )}
      {props.userIsAdmin && (
        <span><a className={activePath(props.currentPath, props.adminPath)} href={props.adminPath}>Admin</a></span>
      )}
      <span><a className={activePath(props.currentPath, props.logOutPath)} href={props.logOutPath}>Log Out</a></span>
      {props.becomeAdminPath && <span><a data-turbolinks="false" href={props.becomeAdminPath}>Back to Admin</a></span>}
    </div>
  );
}

export default AuthenticatedLinks;
