import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { cloneDeep } from 'lodash';

import Notification from './Notification';
import { NotificationElement } from './interfaces';
import Spinner from './Spinner';
import headers from '../utils/headersGenerator';

interface Props {
  fetchNotificationsUrl: string;
  hidden: boolean;
  notificationCount: number;
  notificationUpdateUrl: string;
  notifications: NotificationElement[];
  onClickHide: (() => void);
  onClickMarkAsRead: (() => void);
  onClickMarkAsUnread: (() => void);
  onClickShow: (() => void);
  token: string;
}

interface State {
  notificationCount: number;
  notifications: NotificationElement[];
  showMenuId: string;
}

class NotificationList extends React.Component<Props, State> {
  private signal = axios.CancelToken.source();

  constructor(props: Props) {
    super(props);
    this.handleFetchData = this.handleFetchData.bind(this);

    this.state = {
      notificationCount: props.notificationCount,
      notifications: cloneDeep(props.notifications),
      showMenuId: ''
    };
  }

  handleFetchData() {
    const { fetchNotificationsUrl, hidden, token } = this.props;
    const { notifications } = this.state;

    axios
      .get(
        fetchNotificationsUrl,
        {
          cancelToken: this.signal.token,
          headers: headers(token),
          params: {
            hidden,
            offset: notifications.length
          }
        }
      )
      .then((response) => {
        this.setState({
          notifications: [...notifications, ...response.data]
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }

  render() {
    const { token } = this.props;
    const { notificationCount, notifications } = this.state;
    return (
      <div className="NotificationList" id="scrollableDiv">
        {notifications.length === 0
          ? (
            <div className="paddedBody">
              There are no notifications.
            </div>
          )
          : (
            <InfiniteScroll
              dataLength={notifications.length}
              hasMore={notifications.length < notificationCount}
              loader={<div className="NotificationList-spinnerContainer"><Spinner size={20} /></div>}
              next={this.handleFetchData}
              scrollableTarget="scrollableDiv"
            >
              {notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  onClickHideShow={() => this.submitNotification(notification.id, 'hidden', !notification.hidden)}
                  onClickMarkAs={() => this.submitNotification(notification.id, 'read', !notification.read)}
                  onCloseMenu={() => this.setState({ showMenuId: '' })}
                  onOpenMenu={() => this.setState({ showMenuId: notification.id })}
                  showMenu={this.state.showMenuId === notification.id}
                  token={token}
                />
              ))}
            </InfiniteScroll>
          )}
      </div>
    );
  }

  submitNotification(notificationId: string, action: string, value: boolean) {
    const { notificationUpdateUrl, token } = this.props;
    const { notificationCount, notifications } = this.state;
    axios
      .patch(
        `${notificationUpdateUrl}/${notificationId}`,
        {
          notification: {
            [action]: value
          }
        },
        {
          cancelToken: this.signal.token,
          headers: headers(token)
        }
      )
      .then(() => {
        if (action === 'hidden') {
          this.setState({
            notificationCount: notificationCount - 1,
            notifications: notifications.filter((notifcation) => notifcation.id !== notificationId),
            showMenuId: ''
          });
          return;
        }
        this.setState({
          notifications: notifications.map((notifcation) => {
            if (notifcation.id === notificationId) {
              notifcation[action] = value;
            }
            return notifcation;
          }),
          showMenuId: ''
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.debug(error.message);
        } else {
          alert('Something went wrong, please refresh the page and try again.');
          console.error(error);
        }
      });
  }
}

export default NotificationList;
