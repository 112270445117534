import * as React from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  BlockQuiz,
  Option,
  Question,
  QuestionError,
  QuestionType
} from './interfaces';
import LessonBlockDescription from './LessonBlockDescription';
import QuestionEditor from './QuestionEditor';
import getDraggableStyle from '../utils/getDraggableStyle';
import isBlank from '../utils/isBlank';
import reorder from '../utils/reorder';

interface Props {
  onDirty: (() => void);
  onUpdateQuestionnaire: ((questionnaire: BlockQuiz) => void);
  onlyQuestions?: boolean;
  questionnaire: BlockQuiz;
  questionnaireType: 'quiz' | 'survey';
}

interface State {
  attemptLimit?: number;
  content: Question[];
  daysToReleaseGrade?: number;
  description?: string;
  editingTitleId: string;
  expandedQuestions: string[];
  isDragDisabled: boolean;
  manualGrading?: boolean;
}

class QuestionnaireEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleCollapseQuestions = this.handleCollapseQuestions.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleEnterQuestion = this.handleEnterQuestion.bind(this);
    this.handleExpandQuestions = this.handleExpandQuestions.bind(this);
    this.handleLeaveQuestion = this.handleLeaveQuestion.bind(this);
    this.handleUpdateBlock = this.handleUpdateBlock.bind(this);
    const { attemptLimit, content, daysToReleaseGrade, description, manualGrading } = this.props.questionnaire;
    this.state = {
      attemptLimit,
      content: cloneDeep(content),
      daysToReleaseGrade,
      description,
      editingTitleId: '',
      expandedQuestions: [],
      isDragDisabled: false,
      manualGrading
    };
  }

  changeProperty(value: boolean | number | Option[] | string, property: string, id: string) {
    const { content } = this.state;
    if (property === 'correct' && value === '') {
      const question = cloneDeep(content.find((currentQuestion) => currentQuestion.id === id));
      if (question) {
        delete question[property];
        this.setState({
          content: content.map((currentQuestion) => currentQuestion.id === id ? question : currentQuestion)
        }, this.handleUpdateBlock);
        return;
      }
    }
    this.setState({
      content: content.map((question) => question.id === id ? { ...question, [property]: value } : question)
    }, this.handleUpdateBlock);
  }

  changeType(type: string, questionId: string) {
    const { content } = this.state;
    const { questionnaireType } = this.props;
    const confirmationPrompt = 'At least one other question depends on this one. Updating this question type will'
      + ' cause those questions to always be shown.\n\nAre you sure you want to update this question type?';
    const foundQuestion = content.find((contentQuestion) => contentQuestion.id === questionId);
    const questionOptionIds = foundQuestion?.type === QuestionType.MultipleChoice
      ? foundQuestion.multipleChoiceOptions?.map((option) => option.id)
      : [];
    const dependent = this.dependentQuestions().find((question) => (
      question.dependantOf && questionOptionIds?.includes(question.dependantOf)
    ));
    if (dependent && !confirm(confirmationPrompt)) {
      return;
    }

    switch (type) {
      case QuestionType.Float:
      // fall-through
      case QuestionType.Integer:
        this.setState({
          content: content.map((question) => {
            const { dependantOf, id, prompt, required } = question;
            if (id === questionId) {
              return {
                dependantOf,
                id,
                maxValue: 10,
                minValue: 0,
                prompt,
                required,
                type
              };
            }
            return question;
          })
        }, this.handleUpdateBlock);
        break;
      case QuestionType.Freeform:
        this.setState({
          content: content.map((question) => {
            const { dependantOf, id, prompt, required } = question;
            if (id === questionId) {
              return {
                dependantOf,
                id,
                placeholder: '',
                prompt,
                required,
                type
              };
            }
            return question;
          })
        }, this.handleUpdateBlock);
        break;
      case QuestionType.MultipleChoice:
        this.setState({
          content: content.map((question) => {
            const { dependantOf, id, prompt, required } = question;
            if (id === questionId) {
              const options = () => {
                const optionA = { freeform: false, id: uuidv4(), label: 'Option A' };
                const optionB = { freeform: false, id: uuidv4(), label: 'Option B' };

                if (questionnaireType === 'quiz') {
                  Object.assign(optionA, { correct: false });
                  Object.assign(optionB, { correct: false });
                }
                return [optionA, optionB];
              };
              return {
                dependantOf,
                id,
                maxSelectable: 1,
                multipleChoiceOptions: options(),
                prompt,
                required,
                type
              };
            }
            return question;
          })
        }, this.handleUpdateBlock);
        break;
      case QuestionType.Sort:
        this.setState({
          content: content.map((question) => {
            const { dependantOf, id, prompt, required } = question;
            if (id === questionId) {
              const sortOptions = [
                { id: uuidv4(), label: 'Option A' },
                { id: uuidv4(), label: 'Option B' }
              ];
              return {
                dependantOf,
                id,
                sortOptions,
                prompt,
                required,
                type
              };
            }
            return question;
          })
        }, this.handleUpdateBlock);
        break;
      default:
        break;
    }
  }

  clickArrowHead(id: string) {
    this.setState({
      expandedQuestions: this.state.expandedQuestions.includes(id)
        ? this.state.expandedQuestions.filter((prevId) => prevId !== id)
        : [...this.state.expandedQuestions, id]
    });
  }

  dependentQuestions() {
    return this.state.content.filter((stateQuestion) => stateQuestion.dependantOf);
  }

  findQuestionByOption(id?: string) {
    if (id === undefined) {
      return undefined;
    }
    return this.state.content.find((question) => (
      question.type === QuestionType.MultipleChoice && question.multipleChoiceOptions?.map((option) => option.id).includes(id)
    ));
  }

  getBlock() {
    const { attemptLimit, content, daysToReleaseGrade, description, manualGrading } = this.state;
    return { ...this.props.questionnaire, attemptLimit, content, daysToReleaseGrade, description, manualGrading };
  }

  getErrorMessages(errors: QuestionError[], index: number) {
    return errors.find((_element, elementIndex) => index === elementIndex)?.messages || [];
  }

  getQuestionErrors(): QuestionError[] {
    const hasDuplicatedLabel = (options: Option[]) => (
      new Set(options.map((option) => option.label.toLocaleLowerCase())).size !== options.length
    );
    return this.state.content.map((question, index) => {
      const messages: string[] = [];
      const { prompt, type } = question;

      if ((type === QuestionType.MultipleChoice && hasDuplicatedLabel(question.multipleChoiceOptions || []))
        || (type === QuestionType.Sort && hasDuplicatedLabel(question.sortOptions || []))) {
        messages.push('Option label has been taken.');
      }

      if ((type === QuestionType.MultipleChoice && question.multipleChoiceOptions?.some((option) => isBlank(option.label)))
        || (type === QuestionType.Sort && question.sortOptions?.some((option) => isBlank(option.label)))) {
        messages.push('Question option label can not be blank.');
      }

      if (prompt.trim().length < 3) {
        messages.push('Question prompt must be at least 3 characters.');
      }

      if (prompt.trim().length > 100) {
        messages.push('Question prompt must be less than 100 characters.');
      }

      if (type === QuestionType.MultipleChoice && question.multipleChoiceOptions && question.multipleChoiceOptions.length < 2) {
        messages.push('Multiple-Choice questions must have at least 2 Options.');
      }

      const floatOrIntType = type === QuestionType.Float || type === QuestionType.Integer;
      if (floatOrIntType && question.maxValue !== undefined && question.minValue !== undefined) {
        if (question.maxValue <= question.minValue) {
          messages.push('Max value must be greater than Min value.');
        }
        if (question.correct !== undefined && (
          question.maxValue < Number(question.correct) || question.minValue > Number(question.correct)
        )) {
          messages.push('Correct value must be in between Max and Min values.');
        }
      }

      return { index, messages };
    });
  }

  handleAddQuestion() {
    const id = uuidv4();
    const newQuestion = {
      id,
      maxValue: 10,
      minValue: 0,
      prompt: 'Add question prompt',
      type: QuestionType.Integer
    };

    this.setState({
      expandedQuestions: [...this.state.expandedQuestions, newQuestion.id],
      content: [...this.state.content, newQuestion]
    }, this.handleUpdateBlock);
  }

  handleCollapseQuestions() {
    this.setState({
      expandedQuestions: []
    });
  }

  handleDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    this.setState({
      content: reorder(this.state.content, result.source.index, result.destination.index)
    }, this.handleUpdateBlock);
  }

  handleEnterQuestion() {
    this.setState({ isDragDisabled: true });
  }

  handleExpandQuestions() {
    this.setState({ expandedQuestions: this.state.content.map((question) => question.id) });
  }

  handleLeaveQuestion() {
    this.setState({ isDragDisabled: false });
  }

  handleUpdateBlock() {
    if (this.props.onlyQuestions) {
      this.props.onDirty();
      return;
    }
    this.props.onUpdateQuestionnaire(this.getBlock());
  }

  removeDependencyOption(id: string) {
    this.setState({
      content: this.state.content.map((question) => (
        question.dependantOf === id ? { ...question, dependantOf: undefined } : question
      ))
    }, this.handleUpdateBlock);
  }

  async removeQuestion(id: string) {
    const confirmationPrompt = 'At least one other question depends on this one.'
      + ' Deleting this question will cause those others to always be shown.\n\nAre you sure you want to delete this question?';
    const foundQuestion = this.state.content.find((question) => question.id === id);
    const optionIds = foundQuestion?.type === QuestionType.MultipleChoice
      ? foundQuestion?.multipleChoiceOptions?.map((option) => option.id)
      : [];
    const dependencies = this.dependentQuestions().reduce((questionIds: Array<Question['id']>, question) => {
      if (question.dependantOf && optionIds?.includes(question.dependantOf)) {
        questionIds.push(question.id);
      }
      return questionIds;
    }, []);

    if (dependencies.length > 0) {
      if (confirm(confirmationPrompt)) {
        this.setState({
          content: this.state.content.reduce((questions: Question[], question) => {
            if (question.id !== id) {
              questions.push(question.dependantOf && dependencies.includes(question.dependantOf)
                ? { ...question, dependantOf: undefined }
                : question);
            }
            return questions;
          }, [])
        }, this.handleUpdateBlock);
      }
      return;
    }
    if (window.confirm('Are you sure you want to delete this question?')) {
      this.setState({
        content: this.state.content.filter((question) => question.id !== id)
      }, this.handleUpdateBlock);
    }
  }

  render() {
    const allQuestionErrors = this.getQuestionErrors();
    const questionHasErrors = allQuestionErrors.some((error) => error.messages.length > 0);
    const { attemptLimit, content, daysToReleaseGrade, description, expandedQuestions, manualGrading } = this.state;
    return (
      <div className="QuestionnaireEditor">
        {!this.props.onlyQuestions && (
          <>
            <label>Details</label>
            <div className="quizDetails">
              <div className="detail">
                <label>Attempt Limit</label>
                <input
                  min={1}
                  onChange={(event) => this.setState({ attemptLimit: parseInt(event.target.value, 10) }, this.handleUpdateBlock)}
                  placeholder="Unlimited"
                  type="number"
                  value={attemptLimit || ''}
                />
              </div>
              <div className="detail">
                <label>Days to release grade after lesson is released</label>
                <input
                  min={0}
                  onChange={(event) => (
                    this.setState({ daysToReleaseGrade: event.target.value ? parseInt(event.target.value, 10) : 0 }, this.handleUpdateBlock)
                  )}
                  required
                  type="number"
                  value={daysToReleaseGrade || 0}
                />
              </div>
              <div className="detail">
                <label>Manual grading required</label>
                <input
                  defaultChecked={manualGrading}
                  min={0}
                  onChange={() => this.setState({ manualGrading: !this.state.manualGrading }, this.handleUpdateBlock)}
                  type="checkbox"
                />
              </div>
            </div>
            <LessonBlockDescription
              onUpdate={(newDescription) => this.setState({ description: newDescription }, this.handleUpdateBlock)}
              value={description}
            />
          </>
        )}
        <div className="buttons">
          <div className="left">
            <button onClick={this.handleAddQuestion} type="button">Add Question</button>
          </div>
          <div className="right">
            <button
              disabled={expandedQuestions.length === 0}
              onClick={this.handleCollapseQuestions}
              type="button"
            >
              Minimise All
            </button>
            <button
              disabled={expandedQuestions.length === content.length}
              onClick={this.handleExpandQuestions}
              type="button"
            >
              Expand All
            </button>
          </div>
        </div>
        <div className="questions">
          {this.renderQuestions()}
        </div>
        {this.props.onlyQuestions && (
          <div className="footer">
            <button
              disabled={questionHasErrors || isEqual(this.props.questionnaire, this.getBlock())}
              onClick={() => this.props.onUpdateQuestionnaire(this.getBlock())}
              type="button"
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    );
  }

  renderQuestions() {
    const { content, editingTitleId, expandedQuestions, isDragDisabled } = this.state;
    const questionErrors = this.getQuestionErrors();
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Droppable droppableId="QuestionnaireEditor">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              {content.map((question, index) => {
                const { id } = question;
                return (
                  <Draggable
                    disableInteractiveElementBlocking={id !== editingTitleId}
                    draggableId={id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    key={id}
                  >
                    {(providedDraggable) => (
                      <div
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        ref={providedDraggable.innerRef}
                        style={getDraggableStyle(providedDraggable.draggableProps.style)}
                      >
                        <QuestionEditor
                          dependencyQuestion={this.findQuestionByOption(question.dependantOf)}
                          dependentQuestions={this.dependentQuestions()}
                          expanded={expandedQuestions.includes(id)}
                          multipleChoiceQuestions={content.filter((stateQuestion) => stateQuestion.type === QuestionType.MultipleChoice)}
                          onChangeProperty={(value, property) => this.changeProperty(value, property, id)}
                          onChangeType={(type) => this.changeType(type, id)}
                          onClickArrowHead={() => this.clickArrowHead(id)}
                          onEditingTitle={() => this.setState({ editingTitleId: id })}
                          onMouseEnter={this.handleEnterQuestion}
                          onMouseLeave={this.handleLeaveQuestion}
                          onRemoveDependencyOption={(optionId) => this.removeDependencyOption(optionId)}
                          onRemoveQuestion={() => this.removeQuestion(id)}
                          question={question}
                          questionErrorMessages={this.getErrorMessages(questionErrors, index)}
                          questionnaireType={this.props.questionnaireType}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default QuestionnaireEditor;
